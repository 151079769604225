import { Button, Tab, Tabs } from "@itwin/itwinui-react";
import React, { useState, useEffect } from "react";
import { store } from "../../../store/rootReducer";
import { SyncUiEventDispatcher, UiFramework } from "@itwin/appui-react";
import AddMountElevationPopup from "../tools/AddMountElevationPopup";
import {
  init_DM_MountPropListState,
  mountDMPropList,
  ObjectCategoryTypes,
} from "../../../store/detectedData/apiDataTypes";
import { getValueInRequiredUnit } from "../Tables/BaseTable";
import { ReduceDecimalNumberToTwoPlaces } from "../Lists/EquipmentList";
import { AppSettings } from "../../../config/AppSettings";
import { SyncUiEventIds, unitSystemType } from "../../../store/redux-types";
import { getUnitsInLabel } from "../../tools/getPropertiesUnitLabelled";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { DesignModelAntennaData } from "../../../store/States";

export const MembersTabJSX = () => {
  const [index, setIndex] = useState(0);
  const [disableEdit, setDisableEdit] = useState(false);
  const [memberId, setMemberId] = useState("");
  const [parentMount, setParentMount] = useState("");
  const [parentMountTabDisabled, setParentMountTabDisabled] = useState(false);
  const [wholeData, setWholeData] = useState<any>({});
  const [isOpen,setIsOpen] = useState(false)
  useEffect(() => {
    const imc = UiFramework.getIModelConnection();
    imc?.selectionSet.emptyAll();
    const currentState = store.getState();
    let objectId =
      currentState.detectedData.selectedObjectInformation.objectProperties
        .objectIds;

    if (index == 1) {
      let selectedMember: any = currentState
        .dtvState.designModelData?.mountMemberData?.filter(
          (member) => member.ECInstanceId == objectId[0]
        );
      let allSelectedMember: any = currentState
        .dtvState.designModelData?.mountMemberData?.filter(
          (member) => member.Parent.Id == selectedMember[0].Parent.Id
        );
      let record: any = [];
      let allRecords = allSelectedMember;

      if (wholeData && wholeData.hasOwnProperty(selectedMember[0].Parent.Id)) {
        imc?.selectionSet.add([...wholeData[selectedMember[0].Parent.Id]]);
      } else {
        if (allRecords) {
          let length = allRecords.length - 1;
          for (let ind = 0; ind < allRecords.length - 1; ind++) {
            if (ind != length && ind < length && ind < 49) {
              record.push(allRecords[ind].ECInstanceId);
              record.push(allRecords[length].ECInstanceId);
              --length;
            } else {
              record.push(allRecords[ind].ECInstanceId);
              break;
            }
          }
        }
        imc?.selectionSet.add([...record]);
      }
      setParentMount(selectedMember[0].Parent.Id);
      if (record && record.length != 0) {
        let copyWholeData = { ...wholeData };
        copyWholeData[selectedMember[0].Parent.Id] = record;
        setWholeData(copyWholeData);
      }
      SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(
        SyncUiEventIds.DM_Mount_Selected
      );
    } else {
      if (
        currentState.detectedData.selectedObjectInformation.objectProperties
          .objectIds[0]
      ) {
        imc?.selectionSet.add(memberId);
        setMemberId(objectId[0]);
      }

    }
    setParentMountTabDisabled(false);
  }, [index]);
  useEffect(() => {
    if (
      store.getState().detectedData.selectedObjectInformation.objectProperties
        .objectCategoryType != ObjectCategoryTypes.MountMember_DM
    ) {
      setIndex(0);
      setMemberId("");
      setParentMountTabDisabled(false);
    }
  }, [
    store.getState().detectedData.selectedObjectInformation.objectProperties
      .objectCategoryType,
  ]);


  useEffect(() => {
    const currentState = store.getState();
    if (
      currentState.detectedData.selectedObjectInformation.objectProperties
        .objectIds &&
      currentState.detectedData.selectedObjectInformation.objectProperties
        .objectIds.length
    ) {
      let selectedMember: any = currentState.dtvState.designModelData?.mountMemberData?.filter(member => member.ECInstanceId == currentState.detectedData.selectedObjectInformation.objectProperties.objectIds[0]);
      // let obj = currentState.detectedData.selectedObjectInformation.objectProperties.objectIds.find((ids) => ids == memberId);
  
      if (
        selectedMember &&
        selectedMember.length &&
        selectedMember[0]?.hasOwnProperty("Parent") &&
        selectedMember[0]?.Parent?.Id != parentMount
      ) {
        setIndex(0);
        setMemberId(
          currentState.detectedData.selectedObjectInformation
            .objectProperties.objectIds[0]
        );

        const parentMount = currentState.dtvState.designModelData?.mountData?.filter(md=>md.ECInstanceId == selectedMember[0].Parent?.Id);
        if(parentMount && parentMount.length){
          const antennaData = currentState.dtvState.designModelData?.antennaData;
          if(antennaData){
              let antennas = antennaData.filter((antenna: DesignModelAntennaData): DesignModelAntennaData|void=>{
                  const mountLocation = parentMount[0].Location![0] == "F" ?  parentMount[0].Location![4] : parentMount[0].Location![3];
                  const antennaLocation = antenna.Location![0] == "F" ?  antenna.Location![4] : antenna.Location![3];
                  const distance = Math.abs(antenna.Elevation-parseFloat(parentMount[0].Elevation!.toString()));                  
                  if(distance <= 1 && parentMount[0].Type.includes("Platform") )return antenna;
                  else if(distance <= 1 && antennaLocation == mountLocation)return antenna;
              }) ;
              if(antennas && antennas.length > 0) setDisableEdit(true); else setDisableEdit(false);
          }
        }

        setParentMountTabDisabled(false);
      } else {
        if (
          selectedMember &&
          selectedMember.length &&
          !selectedMember[0]?.hasOwnProperty("Parent")
        )
          setParentMountTabDisabled(true);

      }
    }
  }, [
    store.getState().detectedData.selectedObjectInformation.objectProperties
      .objectIds,
  ]);
  


  const handleMount = () => {
    setIsOpen(!isOpen)
    // UiFramework.content.dialogs.open(
    //   <AddMountElevationPopup
    //     data={UiFramework.getIModelConnection()}
    //     type={"edit"}
    //   />,
    //   "AddNewRad"
    // );
  };

  const parentMountJsx = (disable) => {
    return (
      <>
        <div className="pt-2 propertyGridButton">
          <Button
            className={"property-button"}
            disabled={disable}
            title={disable ? "Cannot edit a mount that holds Antenna." : "Edit Mount"}
            onClick={() => {
              handleMount();
            }}
          >
            Edit Mount
          </Button>
        </div>
        <div></div>
      </>
    );
  };
  return (
    <>
      {store.getState().detectedData.selectedObjectInformation.objectProperties
        .objectCategoryType == ObjectCategoryTypes.MountMember_DM && (
        <>
          <Tabs
            tabsClassName="equipment-posrot-tab"
            contentClassName="pl-0 pb-0 pt-2"
            wrapperClassName="equipment-posrot-tab-wrapper"
            orientation="horizontal"
            onTabSelected={setIndex}
            activeIndex={index}
            type="pill"
            labels={[
              <Tab key={0} label="Member Property" />,
              <Tab
                key={1}
                label="Parent Property"
                disabled={parentMountTabDisabled}
              />,
            ]}
          >
            {index == 1 && parentMountJsx(disableEdit)}
          </Tabs>
        </>
      )}
      {isOpen && <AddMountElevationPopup onClose={()=>setIsOpen(false)}
        data={UiFramework.getIModelConnection()}
        type={"edit"}
      />}
    </>
  );
};

export const getMountFromMembereID = () => {
  SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(
    SyncUiEventIds.DM_Mount_Selected
  );
  let objectId =
    store.getState().detectedData.selectedObjectInformation.objectProperties
      .objectIds;
  if (objectId && objectId[0]) {
    objectId = objectId[0];
    let selectedMember: any = store
      .getState()
      .dtvState.designModelData?.mountMemberData?.filter(
        (member) => member.ECInstanceId == objectId
      );
    let selectedMount: any = store
      .getState()
      .dtvState.designModelData?.mountData?.filter(
        (mount) => mount.ECInstanceId == selectedMember[0].Parent.Id
      );

    let found = selectedMount;
    Object.keys(found[0]).forEach(function (key) {
      if (!isNaN(found[0][key])) {
        //checking whether number is integer or float
        const weightConvFactor = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL ? 1 : 0.4535147392290249 ;
        const inchToMeterConvFactor = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL ? 0.0833333 : 0.0254 ;
        if(key.match(/innerdia|outerdia|thickness|depth|length/ig))found[0] = {...found[0], [key]: AppSettings.defaultDisplaySettings.unitSystem  == unitSystemType.IMPERIAL ? found[0][key] : parseFloat(getValueInRequiredUnit(found[0][key], true, true)).toFixed(2)}
        else if(key.match(/elevation|Standoff|width|offset/ig))found[0] = {...found[0],[key]:parseFloat(getValueInRequiredUnit(found[0][key],true)).toFixed(2)}
        else if(key.match(/weight/ig))found[0] = {...found[0], [key]: (found[0][key]*weightConvFactor).toFixed(2)}
        else if(key.match(/height/ig))found[0] = {...found[0], [key]: (found[0][key]*inchToMeterConvFactor).toFixed(2)}
        else if(key.match(/diameter/ig))found[0] = {...found[0], [key]: AppSettings.defaultDisplaySettings.unitSystem  == unitSystemType.IMPERIAL ? found[0][key] : parseFloat(getValueInRequiredUnit(found[0][key], true, true)).toFixed(2)}
        else if(key.match(/elevation|offset|height|width|depth|length|thickness/ig) && !key.match(/noof|numberof|azimuth/ig))found[0] = {...found[0],[key]:parseFloat(getValueInRequiredUnit(found[0][key],true)).toFixed(2)}


        // if (key.toLowerCase().includes("weight"))
        //   found[0] = {
        //     ...found[0],
        //     [key]:
        //       AppSettings.defaultDisplaySettings.unitSystem ==
        //       unitSystemType.IMPERIAL
        //         ? found[0][key]
        //         : (found[0][key] / 2.2046).toFixed(2),
        //   };
        // else if (noPropertyChange(key.toLowerCase()))
        //   found[0] = {
        //     ...found[0],
        //     [key]: ReduceDecimalNumberToTwoPlaces(found[0][key]).toString(),
        //   };
        // else if (key.toLowerCase().includes("modelname"))
        //   found[0] = { ...found[0], [key]: found[0][key] };
        // else
        //   found[0] = {
        //     ...found[0],
        //     [key]: getValueInRequiredUnit(found[0][key], true),
        //   };
      }
    });
    let mountPropList: mountDMPropList = getUnitsInLabel(
      init_DM_MountPropListState,
      "Mount"
    );
    const nvPair: any[] = Object.entries(mountPropList).map((e) => ({
      name: e[1].label,
      value: found[0][e[0]],
    }));
    return nvPair;
  }
  return [];
};

const noPropertyChange = (name) => {
  let check = false;
  if (name.includes("azimuth")) check = true;
  else if (name.includes("noofpanel")) check = true;
  else if (name.includes("bearingangle")) check = true;

  return check;
};
