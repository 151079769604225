import { ConfigManager } from "../../config/ConfigManager";
import { sanitizeUrl } from "../../config/UrlManager";
import { Logger } from "./logging";
import { AccessToken } from "@itwin/core-bentley";

let privateAccessToken: AccessToken;

export default class mountClient {

  public static async putMountJson(token: string, mountData: any): Promise<any> {
    if (!mountData || !mountData.mountPipeId) {
      return;
    }
    const projectId = ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/v1.1/${projectId}/MountingsData/Mount/`;
    return fetch(sanitizeUrl(url), {
      method: "put",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mountData),
    }).then(async (res: Response) => {
      if (!res.ok) {
        return null;
      }
      return "Success.";
    }).then((data: any) => {
      return data;
    });
  }

  public static async deleteMount(token: string, deleteData: any, delFace: boolean=false, delMount: boolean=false) {
    const projectId = ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/v1.1/${projectId}/MountingsData/Mount/${deleteData.mountId}/${deleteData.mountFaceId}/${deleteData.mountPipeId}/${deleteData.type}/?delFace=${delFace}&delMount=${delMount}`;

    return fetch(sanitizeUrl(url), {
      method: "delete",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }).then(async (response: Response) => {
      if (!response.ok) {
        return "err";
      }
      return response.text();
    }).then((data: any) => {
      if (data === "err") {
        return null;
      }
      return "Successfully deleted.";
    });
  }

  public static async getMountingsSummaryDataJson(token: string = privateAccessToken): Promise<any> {
    const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/MountingsData/Summary`;
  
    return fetch(sanitizeUrl(url), {
        method: "get",
        headers: {
            Authorization: token,
        },
    }).then(async (response: Response) => {
        if (!response.ok) {
            return null;
        }
        return response.json();
    }).then((data: any) => {
        return data;
    }).catch(err=>{
      Logger.error('Error Or No Data while fetching Mountings Summary Data: ', err)
      return null;
    });
  }
    
  public static async putMountOperator(token: string = privateAccessToken, mountList: any): Promise<any> {
    const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Mounts/Operator`;
  
    return fetch(sanitizeUrl(url), {
      method: "put",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mountList),
    }).then(async (res: Response) => {
      if (!res.ok) {
        return null;
      }
      return "success";
    }).then((data: any) => {
      return data;
    });
  }
}
