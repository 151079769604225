// import { ChangedElementsWidget, VersionCompare } from "@bentley/version-compare";
import React, { useEffect ,useRef} from "react";
import { StagePanelLocation, StagePanelSection, UiFramework, UiItemsProvider, Widget, WidgetState, useActiveIModelConnection, useActiveViewport } from "@itwin/appui-react";
import { Animator, IModelApp, IModelConnection } from "@itwin/core-frontend";
import { Alert, Button, LabeledSelect, Slider, Text, ToggleSwitch } from "@itwin/itwinui-react";
import { SvgCompare } from "@itwin/itwinui-icons-react";
import VersionCompareIndex from "./VersionCompareIndex";
import { VersionCompareInitialize } from "./VersionCompareInitialize";
import { ChangedElementsWidget } from "@itwin/changed-elements-react";
import { VersionCompare } from "@bentley/version-compare";

export const VersionWidget = () => {

    return  <ChangedElementsWidget useV2Widget={true} manager={VersionCompare.manager as any} iModelConnection={UiFramework.getIModelConnection() as any}/>;
    
    
    }

export class VersionCompareWidgetProvider implements UiItemsProvider {
    public readonly id: string = "VersionCompareWidgetProvider";
  
    public provideWidgets(_stageId: string, _stageUsage: string, location: StagePanelLocation, _section?: StagePanelSection): ReadonlyArray<Widget> {
        const widgets: Widget[] = [];
      if (location === StagePanelLocation.Right) {
        widgets.push(
          {
            id: "ChangedElementsWidget",
            icon: "icon-compare",
            label: "Version Compare",
            defaultState: WidgetState.Hidden,
            content: ( <VersionWidget/> ),
          }
        );
      }
      return widgets;
    }
  }