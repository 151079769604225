import {useEffect, useState} from "react";
import "./imageSlider360.scss";
import { Badge, LabeledInput,IconButton, LabeledSelect, Modal, ModalContent, ProgressRadial, ToggleSwitch } from "@itwin/itwinui-react";
import React from "react";
import tower from "./tower.jpg";
import shelter from "./shelter.jpg";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import * as THREE from 'three';
// import * as BABYLON from 'babylonjs';
import { ConfigManager } from "../../../config/ConfigManager";
import { ButtonGroup, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { SvgAnnotation, SvgCheckmark, SvgWindowMaximize, SvgIssueReport, SvgStatusWarning, SvgDelete } from "@itwin/itwinui-icons-react";
import compass from "../../../icons/compass/compassSquared.png";
import { Angle } from "@itwin/core-geometry";
import { store } from "../../../store/rootReducer";
import PlanningAnnotationClient from "../../api/PlanningAnnotationClient";
import { setDefectInventoriesData } from "../../../store/detectedData/apiDataActionTypes";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { options } from "superagent";
import ImageCarousel from "../image-carousel/imageCarousel";
import { addDragDroppedFilesToProjectShareList, deleteImageFileInDocs, downloadingImage, existingPhoto } from "./imageslider-helper";


let camera, scene, renderer, labelRenderer;
interface MarkerInterface {x: number, y: number, z: number, existing: boolean};
const raycaster = new THREE.Raycaster();
const pointer = new THREE.Vector2();
const geometryHelper = new THREE.ConeGeometry( 20, 100, 3 );
geometryHelper.translate( 0, 50, 0 );
geometryHelper.rotateX( Math.PI / 2 );
const tagMarkerNew = document.createElement( 'div' );
tagMarkerNew.className = 'annotation-new';
tagMarkerNew.textContent = 'i';
// tagMarker.style.backgroundColor = 'transparent';

const compassContainer = document.createElement( 'div' );
const compassAngle = document.createElement( 'div' );
const compassElm = document.createElement( 'img' );
compassElm.src = compass;
compassElm.width = 80;
compassElm.height = 80;
compassContainer.append(compassElm);
compassContainer.append(compassAngle);
compassAngle.className = 'compass-angle';
compassContainer.className = 'plan-compass';
compassContainer.style.position = "absolute";
        // compassElm.style.cssText = `position: "relative", right: -700px, top: -450px`;
// tagMarker.style.backgroundColor = 'transparent';

const newLabel = new CSS2DObject( tagMarkerNew );
// const compassElement = new CSS2DObject( compassElm );
let enableTagDraw: boolean = false;
// let openTagForm: boolean = false;
const helper = new THREE.Mesh( geometryHelper, new THREE.MeshNormalMaterial() );
let isUserInteracting = false,
    onPointerDownMouseX = 0, onPointerDownMouseY = 0,
    lon = 0, onPointerDownLon = 0,
    lat = 0, onPointerDownLat = 0,
    phi = 0, theta = 0;
    let markers: MarkerInterface[] = [];

// const init_baby = async (_url)=>{
//     var canvas = document.getElementById("renderCanvas") as HTMLCanvasElement;
//     const modalContainer = document.getElementById( 'panoramicModal' )!;
//     const width = modalContainer.clientWidth;
//     const height = modalContainer.clientHeight;
//     canvas.width = width;
//     canvas.height = height;

//     const createDefaultEngine = function() { return new BABYLON.Engine(canvas, true, { preserveDrawingBuffer: true, stencil: true,  disableWebGL2Support: false}); };
//     var asyncEngineCreation = async function() {
//         try {
//         return createDefaultEngine();
//         } catch(e) {
//         console.log("the available createEngine function failed. Creating the default engine instead");
//         return createDefaultEngine();
//         }
//     }
    
//     const startRenderLoop = function (engine, _canvas, sceneToRender) {
//         engine.runRenderLoop(function () {
//             if (sceneToRender && sceneToRender.activeCamera) {
//                 sceneToRender.render();
//             }
//         });
//     }

//     const engine = await asyncEngineCreation();
//     if (!engine) throw 'engine should not be null.';
//     // window.scene = createScene();
//     //window.scene = (()=>{ 
//     const theScene = ((canvas)=>
//     { 
//     //static CreateScene(engine, canvas) {
//         // This creates a basic Babylon Scene object (non-mesh)
//         BABYLON.FramingBehavior.IgnoreBoundsSizeMode;
//         var scene = new BABYLON.Scene(engine);
//         // This creates and positions a free camera (non-mesh)
//         // var camera = new BABYLON.FreeCamera("camera1", new BABYLON.Vector3(0, 5, -10), scene);
//         const camera = new BABYLON.ArcRotateCamera("Camera", 0, 0, 0, new BABYLON.Vector3(0, 0, 0), scene);
//         // camera.setTarget(BABYLON.Vector3.Zero());
//         // This positions the camera
//         camera.setPosition(new BABYLON.Vector3(0, 0, 0));
//         camera.lowerRadiusLimit = 0;
//         camera.upperRadiusLimit = 30;
//         camera.radius = 30;
//         camera.fov = 45;
//         camera.lockedTarget = null;

//         camera.attachControl(canvas, true);
//         // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
//         var light = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(0, 0, 0), scene);
//         // Default intensity is 1. Let's dim the light a small amount
//         light.intensity = 2;
//         // Our built-in 'sphere' shape. Params: name, options, scene
//         var sphere = BABYLON.MeshBuilder.CreateSphere("sphere", { diameter: 100, segments: 32 }, scene);
//         // Move the sphere upward 1/2 its height
//         // sphere.position.y = 1;
//         const sphereMat = new BABYLON.StandardMaterial("sphereMat");
//         // const texture = new BABYLON.Texture("https://westeurope-dev.insite.bentley.com/clients/clone_24_Q4/MONTE_NEME_EB_11_19_07_56_09/img/planning/360/tower.jpg", scene, true, false, BABYLON.Texture.NEAREST_SAMPLINGMODE, ()=>document.getElementById('roundNround')?.setAttribute('style', 'display: none'));
//         const texture = new BABYLON.Texture(tower, scene, true, false, BABYLON.Texture.NEAREST_SAMPLINGMODE, ()=>document.getElementById('roundNround')?.setAttribute('style', 'display: none'));
//         // const texture = new BABYLON.Texture("https://westeurope-dev.insite.bentley.com/clients/clone_24_Q4/MONTE_NEME_EB_11_19_07_56_09/img/planning/360/shelter.jpg", scene, true, false, BABYLON.Texture.NEAREST_SAMPLINGMODE, ()=>document.getElementById('roundNround')?.setAttribute('style', 'display: none'));
//         // const texture = new BABYLON.Texture("https://westeurope-dev.insite.bentley.com/clients/clone_24_Q4/MONTE_NEME_EB_11_19_07_56_09/img/planning/360/shelter.jpg", scene, true, false, BABYLON.Texture.NEAREST_SAMPLINGMODE, ()=>document.getElementById('roundNround')?.setAttribute('style', 'display: none'));
//         // const texture = new BABYLON.Texture(url, scene, true, false, BABYLON.Texture.NEAREST_SAMPLINGMODE, ()=>document.getElementById('roundNround')?.setAttribute('style', 'display: none'));
//         sphereMat.diffuseTexture = texture;
//         sphere.material = sphereMat;
//         sphere.material.backFaceCulling = false;
//         sphere.material.sideOrientation = 1;
//         sphere.isPickable = false;
//         // Our built-in 'ground' shape. Params: name, options, scene
//         var canvasZone = document.getElementById('panoramicModal');
//         var all_labels = [];
//         var sprites = [];
//         // sprites.forEach((sprite) => {
//         //     sprite.elem.style.position = 'absolute';
//         //     sprite.elem.style.width = sprite.width + 'px';
//         //     sprite.elem.style.height = sprite.height + 'px';
//         //     sprite.elem.style.backgroundColor = sprite.color;
//         //     sprite.elem.style.top = '0px';
//         //     sprite.elem.style.left = '0px';
//         //     canvasZone.appendChild(sprite.elem);
//         // });
//         var sphere1 = BABYLON.MeshBuilder.CreateSphere("sphere1", { diameter: 1, segments: 8 }, scene);
//         // const sphereMat = new BABYLON.BasicMaterial("sphereMat");
//         sphere1.material = sphere.material;
//         sphere1.isPickable = false;

//         const mySpritePackedManager = new BABYLON.SpritePackedManager("spm", "textures/pack1.png", 4, scene);
//         // const sprite1 = new BABYLON.Sprite("sprite1", mySpritePackedManager);
//         // sprite1.cellRef = "triangle.png";
//         // sprite1.isPickable = true;
//         // const sprite2 = new BABYLON.Sprite("sprite2", mySpritePackedManager);
//         // sprite2.cellRef = "redman.png";
//         // sprite2.position.x = 1;
//         // sprite2.isPickable = true;
//         // const sprite3 = new BABYLON.Sprite("sprite3", mySpritePackedManager);
//         // sprite3.cellRef = "eye.png";
//         // sprite3.position.x = -1.5;
//         // sprite3.isPickable = true;
//         // const sprite4 = new BABYLON.Sprite("sprite4", mySpritePackedManager);
//         // sprite4.cellRef = "eye.png";
//         // sprite4.position.x = -1.5;
//         // sprite4.position.y = 2;
//         // sprite4.invertU = true;
//         // sprite4.invertV = true;
//         // sprite4.isPickable = true;
//         mySpritePackedManager.isPickable = true;

//         var onPointerDown = function (evt) {
//             if (evt.button !== 0) {
//                 return;
//             }
//             // check if we are under a mesh
//             var pickInfo = scene.pick(scene.pointerX, scene.pointerY, function (mesh) { return mesh === sphere; });
//             if (pickInfo && pickInfo.hit) {
//                 sphere1.position.set(pickInfo.pickedPoint!.x, pickInfo.pickedPoint!.y, pickInfo.pickedPoint!.z)
//                 // sprite4.position.set(pickInfo.pickedPoint!.x, pickInfo.pickedPoint!.y, pickInfo.pickedPoint!.z);
//                 // sprite4.position.z += 0.7;
//                 // add a sprite
//                 // let sprite = { pos: pickInfo.pickedPoint, elem: document.createElement('div'), color: '#00ff00', width: 20, height: 20 };
//                 // sprite.pos.addInPlace(pickInfo.getNormal());
//                 // sprite.elem.style.position = 'absolute';
//                 // sprite.elem.style.width = sprite.width + 'px';
//                 // sprite.elem.style.height = sprite.height + 'px';
//                 // sprite.elem.style.backgroundColor = sprite.color;
//                 // sprite.elem.style.top = '0px';
//                 // sprite.elem.style.left = '0px';
//                 // if(sprites.length == 0)sprites.push(sprite);
//                 // canvasZone.appendChild(sprite.elem);
//             }
//         };
//         var canvas2 = engine.getRenderingCanvas() as HTMLCanvasElement;
//         canvas2.addEventListener("pointerdown", onPointerDown, false);

//         scene.onDispose = function () {
//             canvas.removeEventListener("pointerdown", onPointerDown);
//             all_labels.forEach((ob: any) => {
//                 ob.div.remove();
//             });
//             sprites.forEach((ob: any) => {
//                 ob.elem.remove();
//             });
//             // all_labels = null;
//         };
//         scene.onPointerMove = function (_evt) {
//             var pickResult = scene.pickSprite(this.pointerX, this.pointerY);
//             if (pickResult!.hit) {
//                 pickResult!.pickedSprite!.angle += 0.5;
//             }
//         };
//         return scene;
//     //}
    
//     }
//     )(canvas);
//     window.addEventListener("resize", function () {
//         engine.resize();
//     });
//     startRenderLoop(engine, canvas, theScene);

// }

enum DefectEnum {
    Low = "Low",
    Medium = "Medium",
    High= "High",
}

interface TowerFormInterface {id?: string|number, imageList?: string[]|string, siteCode: string, siteName: string, owner: string, distance: number, towerHeight: number, LOS: boolean};
interface InventoryFormInterface {id?: string|number, imageList?: string[]|string, assetName: string, assetGroup: string, operator: string, model: string, manufacturer: string, technology: string, dimensions: string, azimuth: string, mechanicalTilt: number, electricalTilt: number, feederType: string};
interface DefectFormInterface {id?: string|number, imageList?: string[]|string, owner: string, defectiveStructure: string, defectiveComponent: string, defect: string, criticality: DefectEnum, interventionPriority: string, height: string, length: string, inspector: string, installationDate: Date|string, inspectionDate: Date|string};
enum TagType {
    Tower = "Tower",
    Defect = "Defect",
    Inventory = "Inventory"
}
enum ButtonActionType {
    Edit = "Edit",
    Editing = "Editing",
    Save = "Save",
    Submit = "Submit"
}
enum FormMode {
    Read = "Read",
    Write = "Write"
}
enum PanoramicMode {
    Spherical = "Spherical",
    InShelter = "InShelter"
}
const TowerFormInitState: TowerFormInterface = {siteCode: "", siteName: "", owner: "", distance: 0, towerHeight: 0, LOS: false, imageList: `[\no_image\]`};
const DefectFormInitState: DefectFormInterface = {owner: "", defectiveStructure: "", defectiveComponent: "", defect: "", criticality: DefectEnum.Low, interventionPriority: "", height: "", length: "", inspector: "", installationDate: "", inspectionDate: ""};
const InventoryFormInitState: InventoryFormInterface = {assetName: "", assetGroup: "", operator: "", model: "", manufacturer: "", technology: "", dimensions: "", azimuth: "", mechanicalTilt: 0, electricalTilt: 0, feederType: ""};

const ImageSlider360 = (props:{title:any,height:any,width:any,insightImageUrl:any, showImageModal:any, toggleImageModal:any ,toggle:any,setToggle:any}) => {
    const [loading, setLoading]=useState(false);
    // const [enableTagDraw, setEnableTagDraw]=useState(false);
    const [tagFormType, setTagFormType]=useState<TagType>(TagType.Defect);
    const [formMode, setFormMode]=useState<FormMode>(FormMode.Write);
    const [panoramicMode, setPanoramicMode]=useState<PanoramicMode>(PanoramicMode.Spherical);
    const [multiActionButton, setMultiActionButton]=useState<ButtonActionType>(ButtonActionType.Submit);
    const [siteDetails, setSiteDetails]=useState<{siteCode: string, clientName: string}>({siteCode: "", clientName: ""});

    const [tagForm, setTagForm]=useState<TowerFormInterface|InventoryFormInterface|DefectFormInterface>(TowerFormInitState);
    const [openTagForm,setOpenTagForm]=useState<boolean>(false)
    const [showModal, setShowModal]=useState(props.showImageModal)
    const [imageFile,setImageFile] = useState<any>("")
    const [downloadedImage, setDownloadedImage] = useState([{url:"",id:0,data:{id:""}}]);
    const [imageOpen,setIsOpenImage] = useState(false)
    const [toDeleteImageFile,setToDeleteImageFIle] = useState([""])
    const [imageSelected, setImageSelected] = useState([""])
    useEffect(()=>{
        if(showModal && !loading)setTimeout(() => {
            const type = props.title.split(' - ');
            const theUrl = new URL(props.insightImageUrl);
            const searchParams = new URLSearchParams(theUrl.search.substring(1));
            if(searchParams.has("siteCode") && searchParams.has("clientName")){
              theUrl.search="";
              theUrl.pathname="/insite";
              const sc =  searchParams.get("siteCode")! as string;
              const cn =  searchParams.get("clientName")! as string;
              setSiteDetails({siteCode: sc, clientName: cn});
              theUrl.searchParams.append("site", sc);
              theUrl.searchParams.append("client", cn);
              theUrl.searchParams.append("pId", ConfigManager.projectId!);
            }
          let url = "";
            switch (type[0]) {
                case "Spherical":
                    url = `${theUrl.origin}/clients/${searchParams.get("client")}/${searchParams.get("site")}/img/planning/360/tower.jpg`;
                    setPanoramicMode(PanoramicMode.Spherical);
                    setTagFormType(TagType.Tower);
                break;            
                case "In shelter":
                    url = `${theUrl.origin}/clients/${searchParams.get("client")}/${searchParams.get("site")}/img/planning/360/shelter.jpg`
                    setTagFormType(TagType.Inventory);
                    setPanoramicMode(PanoramicMode.InShelter);
                break;            
            }
            init_three(url);
            // init_baby(url)
            onWindowResize();
        }, 1000);
    },  [loading]);

    async function init_three(_url) {

        const modalContainer = document.getElementById( 'panoramicModal' )!;
        const width = modalContainer.clientWidth;
        const height = modalContainer.clientHeight;
        const container = document.getElementById( 'threeCanvas' )!;
    
        camera = new THREE.PerspectiveCamera( 30, width / height, 1, 1100 );
        // camera.rotation.z += Math.PI/2;
        // camera.updateProjectionMatrix();
        // camera.layers.enableAll();
        scene = new THREE.Scene();
        // globalThis.scene = scene;
        // globalThis.camera = camera;
        const geometry = new THREE.SphereGeometry( 500, 40, 40 );
        // invert the geometry on the x-axis so that all of the faces point inward
        geometry.scale( - 1, 1, 1 );
    
        const texture = new THREE.TextureLoader().load(_url, ()=>document.getElementById('roundNround')?.setAttribute('style', 'display: none'));
        // const texture = new THREE.TextureLoader().load(tower, ()=>document.getElementById('roundNround')?.setAttribute('style', 'display: none'));
        texture.colorSpace = THREE.SRGBColorSpace;
        const material = new THREE.MeshBasicMaterial( { map: texture } );
    
        const mesh = new THREE.Mesh( geometry, material );
    
        scene.add( mesh );
        // scene.rotation.y=3*Math.PI/2

        renderer = new THREE.WebGLRenderer();
        renderer.setClearColor( 0xffffff, 0)
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( width, height );
        // renderer.setAnimationLoop( animate );
        container.appendChild( renderer.domElement );
        renderer.domElement.setAttribute('style', 'margin-left: 0px');
    
        labelRenderer = new CSS2DRenderer();
        labelRenderer.setSize( window.innerWidth, window.innerHeight );
        labelRenderer.domElement.style.position = 'absolute';
        labelRenderer.domElement.style.top = '0px';
        container.appendChild( labelRenderer.domElement );
        // labelRenderer.domElement.append(compassElm);
        labelRenderer.domElement.append(compassContainer);
    
        const controls = new OrbitControls( camera, labelRenderer.domElement );
        controls.minDistance = 5;
        controls.maxDistance = 100;
        // controls.
        // controls.update();
        // controls.target
        // controls.zoomSpeed = 2;
        // controls.enablePan = false;
        // globalThis.controls = controls;
        // controls.rotation.z

        loadData()
    
        newLabel.position.set( 0, 0, 0 );
        newLabel.center.set( 0, 1 );
        newLabel.name = "NewLabel";
        mesh.add( newLabel );
        newLabel.visible = false;
        newLabel.layers.set( 0 );
    
        // compassElement.position.set( 0, 0, 0 );
        // compassElement.center.set( 0, 1 );
        // mesh.add( compassElement );
        // compassElement.layers.set( 0 );
    
    
        container.style.touchAction = 'none';
        container.addEventListener( 'pointerdown', onPointerDown );
    
        container.addEventListener( 'wheel', onDocumentMouseWheel );
    
        container.addEventListener( 'resize', onWindowResize );
        container.addEventListener( 'pointermove', onPointerMove );
        container.addEventListener( 'pointerup', onPointerUp );
        animate()
    }

    const loadData = (defectInventories = store.getState().detectedData.planningDefectInventoriesData) => {
        const type = props.title.split(' - ')[0];
        // const currentState = store.getState();
        // const defectInventories = di == undefined ?  : di;
        const mesh = scene.children[0];
        mesh.children.map(child=>child.remove());
        defectInventories.inventories!.map(ed=>{
            // existingData.inventories!.map(ed=>{
                const matchInventoryType = type == "Spherical" ? "LOS" : "SHELTER";
                if(ed.activeOrNot == "Active" && ed.position && ed.typeOfInventory.match(matchInventoryType)){
                    const tagMarker = document.createElement( 'div' );
                    tagMarker.className = 'annotation';
                    tagMarker.title = `Inventory: ${ed.sCreateTooltipArgs}`;
                    tagMarker.id = `${ed.inventoryId}`;
                    tagMarker.setAttribute("data", JSON.stringify({type: ed.typeOfInventory, id: ed.inventoryId, defaultClass: "annotation"}));
                    tagMarker.textContent = 'i';
                    const existingLabel = new CSS2DObject( tagMarker );
                    existingLabel.position.set( ed.position.x, ed.position.y, ed.position.z );
                    existingLabel.center.set( 0, 1 );
                    mesh.add( existingLabel );
                    existingLabel.layers.set( 0 );
                }
            })
            // if(panoramicMode == PanoramicMode.InShelter){
            if(type == "In shelter"){
                defectInventories.defects!.map(ed=>{
                // existingData.defects!.map(ed=>{
                    if(ed.activeOrNot == "Active" && ed.position){
                        const tagMarker = document.createElement( 'div' );
                        tagMarker.className = ed.criticality == DefectEnum.Low ? 'low-defect' : ed.criticality == DefectEnum.Medium ? 'medium-defect' : 'high-defect';
                        tagMarker.title = `Defect: ${ed.criticality}`;
                        tagMarker.id = `${ed.defectId}`;
                        tagMarker.setAttribute("data", JSON.stringify({type: ed.criticality, id: ed.defectId}));
                        tagMarker.textContent = 'i';
                        const existingLabel = new CSS2DObject( tagMarker );
                        existingLabel.position.set( ed.position.x, ed.position.y, ed.position.z );
                        existingLabel.center.set( 0, 1 );
                        mesh.add( existingLabel );
                        existingLabel.layers.set( 0 );
                    }
                })
            }
        
    
    }
    
    function onWindowResize() {
        const modalContainer = document.getElementById( 'panoramicModal' )!;
        const width = modalContainer.clientWidth;
        const height = modalContainer.clientHeight;
        
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
    
        renderer.setSize( width, height );
        labelRenderer.setSize( width, height );
    
    }
    
   async function onPointerDown( event ) {
        const target = event.target;
        if(target.className.match(/defect|annotation/ig) && !target.className.match(/annotation-new/ig) && !enableTagDraw){
            const data = JSON.parse(target.attributes.data.value);
            // target.classList.remove('annotation');
            // target.classList.add('selected-annotation');
            switch (data.type) {
                case "LOS": {
                        setTagFormType(TagType.Tower);
                        const selInvent = store.getState().detectedData.planningDefectInventoriesData.inventories!.find(invent=>invent.inventoryId==data.id)!;
                        // setTagFormType()
                        if(selInvent){
                            const formData: TowerFormInterface = {
                                id: selInvent.inventoryId,
                                siteCode: selInvent.sCreateTooltipArgs,
                                siteName: selInvent.sphericalSiteName!,
                                owner: selInvent.sphericalOwner!,
                                distance: parseFloat(selInvent.sphericalDistance!),
                                towerHeight: parseFloat(selInvent.sphericalTowerHeight!),
                                LOS: selInvent.sphericalLOS == "1",
                                imageList:selInvent.sImagens
                            }
                            if(formData.imageList!="[\"no_image\"]"){
                                setDownloadedImage([{url:"",id:0,data:{id:""}}])
                                setImageSelected([""])
                                let parsedData= JSON.parse(formData.imageList as any)
                                await getImage( parsedData)
                               
                            }else{
                                setDownloadedImage([{url:"",id:0,data:{id:""}}])
                            }
                            setMultiActionButton(ButtonActionType.Edit);
                            setFormMode(FormMode.Read);
                            setTagForm(formData);
                            setOpenTagForm(true);
                        }
                    }
                    break;
                case "PIN_SHELTER": {
                        setTagFormType(TagType.Inventory);
                        const selInvent = store.getState().detectedData.planningDefectInventoriesData.inventories!.find(invent=>invent.inventoryId==data.id)!;
                        if(selInvent){
                            const formData: InventoryFormInterface = {
                                id: selInvent.inventoryId,
                                assetName: selInvent.sCreateTooltipArgs!,
                                assetGroup: selInvent.shelterAssetGroup!,
                                operator: selInvent.operator!,
                                model: selInvent.model!,
                                manufacturer: selInvent.manufacturer!,
                                technology: selInvent.tech!,
                                dimensions: selInvent.dim!,
                                azimuth: selInvent.azimuth!,
                                mechanicalTilt: parseFloat(selInvent.mechanical!),
                                electricalTilt: parseFloat(selInvent.eletrical!),
                                feederType: selInvent.shelterFeederType!,
                                imageList:selInvent.sImagens

                            }
                            if(formData.imageList!="[\"no_image\"]"){
                                setDownloadedImage([{url:"",id:0,data:{id:""}}])
                                setImageSelected([""])
                                let parsedData= JSON.parse(formData.imageList as any)
                               await getImage( parsedData)
                               
                            }else{
                                setDownloadedImage([{url:"",id:0,data:{id:""}}])
                            }
                            setMultiActionButton(ButtonActionType.Edit);
                            setFormMode(FormMode.Read);
                            setTagForm(formData);
                            setOpenTagForm(true);
                        }
                    }
                    break;
                case "Low": 
                case "Medium": 
                case "High": {
                    setTagFormType(TagType.Defect);
                    const selDefect = store.getState().detectedData.planningDefectInventoriesData.defects!.find(defect=>defect.defectId==data.id)!;
                    if(selDefect){
                        console.log("selDefect",selDefect)
                        const formData: DefectFormInterface = {
                            owner: selDefect.owner,
                            id: selDefect.defectId,
                            defectiveStructure: selDefect.defectiveStructure,
                            defectiveComponent: selDefect.defectiveComponent,
                            defect: selDefect.defect,
                            criticality: selDefect.criticality == "Low" ? DefectEnum.Low : selDefect.criticality == "Medium" ? DefectEnum.Medium : DefectEnum.High,
                            interventionPriority: selDefect.intervation,
                            height: selDefect.height,
                            length: selDefect.length,
                            inspector: selDefect.inspector,
                            installationDate: toDateInputValue(selDefect.instalationDate,true),
                            inspectionDate: toDateInputValue( selDefect.inspectionDate,true),
                            imageList:selDefect.imgList

                        }
                        if(formData.imageList!="[\"no_image\"]"){
                            setDownloadedImage([{url:"",id:0,data:{id:""}}])
                            setImageSelected([""])
                            if(formData.imageList && formData.imageList.length > 0){
                                let parsedData= JSON.parse(formData.imageList as any)
                                await getImage( parsedData)
                            }
                           
                        }else{
                            setDownloadedImage([{url:"",id:0,data:{id:""}}])
                        }
                        setMultiActionButton(ButtonActionType.Edit);
                        setFormMode(FormMode.Read);
                        setTagForm(formData);
                        setOpenTagForm(true);
                    }
                }
                    break;
               
                default:
                    break;
            }
            if(data.type == "LOS"){
            
            }
        }
        // if ( event.isPrimary === false ) return;
    
        // isUserInteracting = true;
    
        // onPointerDownMouseX = event.clientX;
        // onPointerDownMouseY = event.clientY;
    
        // onPointerDownLon = lon;
        // onPointerDownLat = lat;
    
        // document.addEventListener( 'pointermove', onPointerMove );
        // document.addEventListener( 'pointerup', onPointerUp );
    
    }
    const toDateInputValue = (dt,show=true)=>{
        const date = new Date(dt);
        let month = date.getMonth() <10?"0"+(date.getMonth()+1):date.getMonth()+1;
        let day = date.getDate() <10?"0"+date.getDate():date.getDate();
        let year = date.getFullYear() ;
       
    return show?`${year}-${month}-${day}`:`${(date.getMonth()+1)}/${date.getDate()}/${year} 12:00:00 AM`
    }
    
    const getImage = async (arr)=>{
        let temp :any= []
        for(let i=0;i<arr.length;i++){

            let url = await downloadingImage(arr[i])
            if(url)temp.push({url:url,id:i,data:{id:arr[i],url}
            });
    
        }
        if(temp.length>0)setDownloadedImage(temp)
    }
    const multiActionButtonHandler = ( event ) => {
        const target = event.target;
        switch (target.innerText) {
            case "EDIT": 
            case "EDITING": {
                setMultiActionButton(multiActionButton=>multiActionButton  == ButtonActionType.Edit ? ButtonActionType.Editing : ButtonActionType.Edit);
                setFormMode(formMode=>formMode  == FormMode.Read ? FormMode.Write : FormMode.Read);
                break;
            }
        
            default:
                break;
        }
    }
    
    function onPointerMove( event ) {
    
        if ( event.isPrimary === false ) return;
        const modal = document.getElementById('panoramicModal')!;
        lon = ( onPointerDownMouseX - event.clientX ) * 0.1 + onPointerDownLon;
        lat = ( event.clientY - onPointerDownMouseY ) * 0.1 + onPointerDownLat;
        
        pointer.x = ( (event.clientX-(window.innerWidth-modal.clientWidth+50)/2) / labelRenderer.domElement.clientWidth ) * 2 - 1;
        pointer.y = - ( (event.clientY-(window.innerHeight-modal.clientHeight-50)/2) / labelRenderer.domElement.clientHeight ) * 2 + 1;
        raycaster.setFromCamera( pointer, camera );
        const angles: Angle = Angle.createRadians(Math.atan2(camera.quaternion.z, camera.quaternion.x));
        let deg = -2*angles.degrees;
        if(deg < 0){
            deg = 360 + deg;
        }
        compassElm.style.cssText = `transform: rotate(${deg}deg);`

        let angle = deg - 90 < 0 ? Math.abs(deg-90) : 360-(deg-90);
        // let angle = deg-90;
        // console.log('angle, deg: ', angle, deg)
        // compassAngle.innerText = `${Math.abs(Math.round(360-deg))}°`
        compassAngle.innerText = `${Math.round(angle)}°`
        compassContainer.style.position = "absolute";
   
        // See if the ray from the camera into the world hits one of our meshes
        const intersects = raycaster.intersectObject( scene.children[0] );
    
        // Toggle rotation bool for meshes that we clicked
        if ( intersects.length > 0 && enableTagDraw) {
            scene.getObjectByName('NewLabel').visible=true;
    
            helper.position.set( 0, 0, 0 );
            helper.lookAt( intersects[ 0 ].face.normal );
    
            helper.position.copy( intersects[ 0 ].point );
            newLabel.position.set( intersects[ 0 ].point.x, intersects[ 0 ].point.y, intersects[ 0 ].point.z );
            
        }
    
    }
    
    function onPointerUp(event) {
    
        if ( event.isPrimary === false ) return;
        const modal = document.getElementById('panoramicModal')!;
        pointer.x = ( (event.clientX-(window.innerWidth-modal.clientWidth+50)/2) / labelRenderer.domElement.clientWidth ) * 2 - 1;
        pointer.y = - ( (event.clientY-(window.innerHeight-modal.clientHeight-50)/2) / labelRenderer.domElement.clientHeight ) * 2 + 1;
    
        raycaster.setFromCamera( pointer, camera );
    
        // See if the ray from the camera into the world hits one of our meshes
        const intersects = raycaster.intersectObject( scene.children[0] );
        if ( intersects.length > 0 && enableTagDraw) {
            markers.push({x: intersects[ 0 ].point.x, y: intersects[ 0 ].point.y, z: intersects[ 0 ].point.z, existing: false});
            setOpenTagForm(true);
            enableTagDraw = false;
        }
        isUserInteracting = false;
    
        // document.removeEventListener( 'pointermove', onPointerMove );
        // document.removeEventListener( 'pointerup', onPointerUp );
    
    }
    
    function onDocumentMouseWheel( event ) {
    
        const fov = camera.fov + event.deltaY * 0.05;
    
        camera.fov = THREE.MathUtils.clamp( fov, 10, 75 );
    
        camera.updateProjectionMatrix();
    
    }
    
    function animate() {
    
        if ( isUserInteracting === false ) {
    
            // lon += 0.01;
    
        }
        if(markers.length){
            // markers.map(marker=>{
            //     const htmlMarker = new CSS2DObject( marker.existing ? tagMarker : tagMarkerNew);
            //     htmlMarker.position.set( marker.x, marker.y, marker.z );
            //     htmlMarker.center.set( 0, 1 );
            //     scene.children[0].add( htmlMarker );
            //     htmlMarker.layers.set( 0 );
            // })
        
        }
        requestAnimationFrame( animate );
        // lat = Math.max( - 85, Math.min( 85, lat ) );
        // phi = THREE.MathUtils.degToRad( 90 - lat );
        // theta = THREE.MathUtils.degToRad( lon );
    
        // const x = 500 * Math.sin( phi ) * Math.cos( theta );
        // const y = 500 * Math.cos( phi );
        // const z = 500 * Math.sin( phi ) * Math.sin( theta );
    
        // camera.lookAt( x, y, z );
    
        renderer.render( scene, camera );
        labelRenderer.render( scene, camera );
    }
    

    const nextIteration=()=>{
        setLoading(false)
        const  ifr=document.getElementById('inlineFrameExample')
        ifr?.setAttribute("style", "display: block;")
    }

    const tagFormHandler = (event)=>{
        event.persist();
        const target = event.target;
        if(multiActionButton == ButtonActionType.Editing)setMultiActionButton(ButtonActionType.Save);
        switch (target.name) {
            case "LOS":
                setTagForm(tagForm=>({...tagForm, [target.name]: target.checked}));
                break;
            default:
                setTagForm(tagForm=>({...tagForm, [target.name]: target.value}));
                break;
        }
    }

    const reloadDefectsInventoryDataInStore = async ()=>{
        try {
            const defectInventories = await PlanningAnnotationClient.getDefectInventories(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);
            if(defectInventories){
                store.dispatch(setDefectInventoriesData(defectInventories));
                loadData(defectInventories);
            }
          } catch (error) {
            console.error('Error while fetching Tower Images: ', error);
          }
      
    }

    const actionHandler = async (event)=>{
        event.persist();
        const currentState = store.getState();
        const defectInventories = currentState.detectedData.planningDefectInventoriesData;
        const target = event.target.innerText;
        switch (target) {
            case "CANCEL": {
                setOpenTagForm(false);
                enableTagDraw=false;
                markers = markers.filter(marker=>marker.existing==false);
                setTagForm(TowerFormInitState);
                scene.getObjectByName('NewLabel').visible=false;
                break;
            }
            case "SAVE": {
                let img = "[\"no_image\"]"
                let images = [...imageSelected]
               if(imageSelected.length  == 1 && downloadedImage[0].url!="")img= JSON.stringify(downloadedImage.map((img)=>img.data.id))
                if(imageSelected.length >1) img=JSON.stringify( images.slice(1,images.length))
                switch (tagFormType) {
                    case TagType.Tower: {
                        const formData: TowerFormInterface = tagForm as TowerFormInterface;
                        const location: any = {...(markers[markers.length-1])};
                        let currentInventory:any = defectInventories.inventories?.filter(invent=>invent.inventoryId == formData?.id)
                        delete location.existing;
                        const putData = {
                            inventoryId: formData.id,
                            // inspectionId:formData.inspectionId,
                            position: currentInventory[0].position,
                            sPitch: "0",
                            sYaw: "0",
                            sType: "info",
                            sCreateTooltipArgs: formData.siteCode,
                            sImagens:img,
                            sStatus: "existing_img",
                            sphericalSiteName: formData.siteName,
                            sphericalOwner: formData.owner,
                            sphericalDistance: formData.distance,
                            sphericalLOS: formData.LOS ? "1" : "0",
                            sphericalTowerHeight: formData.towerHeight,
                            typeOfInventory: "LOS",
                            activeOrNot: "Active"
                        }
                        const resp = await PlanningAnnotationClient.putInventory(store.getState().auth.accessTokenStatePrivateAPI.accessToken, putData);
                        if(resp){
                            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, "Data saved successfully", "", OutputMessageType.Toast));

                            reloadDefectsInventoryDataInStore();
                        }
                        break;
                    }                        
                    case TagType.Inventory: {
                        const formData: InventoryFormInterface = tagForm as InventoryFormInterface;
                        const location: any = {...(markers[markers.length-1])};
                        let currentInventory:any = defectInventories.inventories?.filter(invent=>invent.inventoryId == formData?.id)
                            delete location.existing;
                        const putData = {
                            inventoryId: formData.id,
                            // inspectionId:formData.inspectionId,
                            position: currentInventory[0].position,
                            sPitch: "0",
                            sYaw: "0",
                            sType: "info",
                            sImagens: img,
                            sStatus: "existing_img",
                            activeOrNot: "Active",
                            typeOfInventory: "PIN_SHELTER",
                            sCreateTooltipArgs: formData.assetName,
                            shelterAssetGroup: formData.assetGroup,
                            operator: formData.operator,
                            model: formData.model,
                            manufacturer: formData.manufacturer,
                            tech: formData.technology,
                            dim: formData.dimensions,
                            azimuth: formData.azimuth,
                            mechanical: formData.mechanicalTilt,
                            eletrical: formData.electricalTilt,
                            shelterFeederType: formData.feederType,
                        }
                        const resp = await PlanningAnnotationClient.putInventory(store.getState().auth.accessTokenStatePrivateAPI.accessToken, putData);
                        if(resp){
                            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, "Data saved successfully", "", OutputMessageType.Toast));

                            reloadDefectsInventoryDataInStore();
                        }
                        break;
                    }                        
                    case TagType.Defect: {
                        const formData: DefectFormInterface = tagForm as DefectFormInterface;
                        const location: any = {...(markers[markers.length-1])};
                        let currentDefect:any = defectInventories.defects?.filter(defect=>defect.defectId == formData?.id)
                            delete location.existing;
                        const putData = {
                            defectId: formData.id,
                            // inspectionId:formData.inspectionId,
                            owner: formData.owner,
                            criticality: formData.criticality,
                            position: currentDefect[0]?.position,
                            defect: formData.defect,
                            defectiveComponent: formData.defectiveComponent,
                            defectiveStructure: formData.defectiveStructure,
                            height: formData.height,
                            imgList: img,
                            inspectionDate:toDateInputValue (formData.inspectionDate,false),
                            inspector: formData.inspector,
                            instalationDate:toDateInputValue( formData.installationDate,false),
                            fixDate: "",
                            activeOrNot: "Active",
                            intervation: formData.criticality,
                            length: formData.length,
                            pinInformation: formData.criticality == DefectEnum.Low ? "greenPin" : formData.criticality == DefectEnum.Medium ? "yellowPin" : "redPin",
                            status: "existing_img",
                            aiOrManual: "Manual",
                            pinOrBox: "PIN_SHELTER",
                        }
                        const resp = await PlanningAnnotationClient.putDefect(store.getState().auth.accessTokenStatePrivateAPI.accessToken, putData);
                        if(resp){
                            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, "Data saved successfully", "", OutputMessageType.Toast));

                            reloadDefectsInventoryDataInStore();
                        }
                        break;
                    }                        
                
                    default:
                        break;
                }
          
                   setImageFile("")
                   setToDeleteImageFIle([""])

                   setOpenTagForm(false);
                  
                break;
            }
            case "SUBMIT": {
                enableTagDraw = false;
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Please wait while we are saving the data", "", OutputMessageType.Toast));
               let img = "[\"no_image\"]";
              
                if(imageSelected.length >1) img=JSON.stringify( imageSelected.slice(1,imageSelected.length))
                  

                switch (tagFormType) {
                    case TagType.Tower: {
                        const formData: TowerFormInterface = tagForm as TowerFormInterface;
                        const location: any = {...(markers[markers.length-1])};
                        delete location.existing;
                        const postData = {
                            position: location,
                            sPitch: "0",
                            sYaw: "0",
                            sType: "info",
                            typeOfInventory: "LOS",
                            activeOrNot: "Active",
                            sImagens: img,
                            sStatus: "existing_img",
                            sCreateTooltipArgs: formData.siteCode,
                            sphericalSiteName: formData.siteName,
                            sphericalOwner: formData.owner,
                            sphericalDistance: formData.distance,
                            sphericalLOS: formData.LOS ? "1" : "0",
                            sphericalTowerHeight: formData.towerHeight,
                        }

                        const resp = await PlanningAnnotationClient.postInventory(store.getState().auth.accessTokenStatePrivateAPI.accessToken, postData);
                        if(resp){
                            reloadDefectsInventoryDataInStore();
                        }
                        break;
                    }                        
                    case TagType.Inventory: {                  
                        const formData: InventoryFormInterface = tagForm as InventoryFormInterface;
                        const location: any = {...(markers[markers.length-1])};
                        delete location.existing;
                        const postData = {
                            position: location,
                            sPitch: "0",
                            sYaw: "0",
                            sType: "info",
                            sImagens: img,
                            sStatus: "existing_img",
                            activeOrNot: "Active",
                            typeOfInventory: "PIN_SHELTER",
                            sCreateTooltipArgs: formData.assetName,
                            shelterAssetGroup: formData.assetGroup,
                            operator: formData.operator,
                            model: formData.model,
                            manufacturer: formData.manufacturer,
                            tech: formData.technology,
                            dim: formData.dimensions,
                            azimuth: formData.azimuth,
                            mechanical: formData.mechanicalTilt,
                            eletrical: formData.electricalTilt,
                            shelterFeederType: formData.feederType,
                        }
                        const resp = await PlanningAnnotationClient.postInventory(store.getState().auth.accessTokenStatePrivateAPI.accessToken, postData);
                        if(resp){
                            reloadDefectsInventoryDataInStore()
                        }
                        break;
                    }                        
                    case TagType.Defect: {
                        const formData: DefectFormInterface = tagForm as DefectFormInterface;
                        const location: any = {...(markers[markers.length-1])};
                        delete location.existing;
                        const postData = {
                            owner: formData.owner,
                            criticality: formData.criticality,
                            position: location,
                            defect: formData.defect,
                            defectiveComponent: formData.defectiveComponent,
                            defectiveStructure: formData.defectiveStructure,
                            height: formData.height,
                            imgList: img,
                            inspectionDate: formData.inspectionDate,
                            inspector: formData.inspector,
                            instalationDate: formData.installationDate,
                            fixDate: "",
                            activeOrNot: "Active",
                            intervation: formData.criticality,
                            length: formData.length,
                            pinInformation: formData.criticality == DefectEnum.Low ? "greenPin" : formData.criticality == DefectEnum.Medium ? "yellowPin" : "redPin",
                            status: "existing_img",
                            aiOrManual: "Manual",
                            pinOrBox: "PIN_SHELTER",
                        }
                        const resp = await PlanningAnnotationClient.postDefect(store.getState().auth.accessTokenStatePrivateAPI.accessToken, postData);
                        if(resp){
                            reloadDefectsInventoryDataInStore()
                        }
                        break;
                    }                        
                
                    default:
                        break;
                }
              
                   
                   setImageFile("")
                   setToDeleteImageFIle([""])


                   setOpenTagForm(false);
                  
                break;
            }
            case "EXISTING PHOTO":
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Please wait while we are fetching data from Documents", "", OutputMessageType.Toast));

            let img =    await existingPhoto()
            setToDeleteImageFIle([""])
            setDownloadedImage(img)
            if(img && img[0].url === "" && img[0].data == null) IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "No Image found in Documents folder", "", OutputMessageType.Toast));

                break;
            case "UPLOAD PHOTO":
               
                break;
             case "DELETE":{
                switch(tagFormType){
                    case TagType.Tower: 
                    case TagType.Inventory:{
                        const formData: DefectFormInterface = tagForm as DefectFormInterface;
                        const selInvent:any =  formData.id;
                        const resp = await PlanningAnnotationClient.deleteInventory(store.getState().auth.accessTokenStatePrivateAPI.accessToken, selInvent);
                        if(resp == "success"){
                            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, "Successfully deleted.", "", OutputMessageType.Toast));
                            reloadDefectsInventoryDataInStore();
                        }
                        setOpenTagForm(false);
                        break;
                    }
                    case TagType.Defect:{
                        const formData: DefectFormInterface = tagForm as DefectFormInterface;
                        const selInvent:any =  formData.id;
                        const resp = await PlanningAnnotationClient.deleteDefect(store.getState().auth.accessTokenStatePrivateAPI.accessToken,selInvent);
                        if(resp == "success"){
                            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, "Successfully deleted.", "", OutputMessageType.Toast));
                            reloadDefectsInventoryDataInStore();
                        }
                        setOpenTagForm(false);
                        break;
                    }
                }
        
                }
            default:
                break;
        }
    }

    const tagHandler = (event)=>{
        event.persist();
        const target = event.target.text || event.currentTarget.name;
        switch (target) {
            case "Add New Tag":
                enableTagDraw=true;
                if(panoramicMode == PanoramicMode.Spherical) {
                    setTagForm(TowerFormInitState);
                    setTagFormType(TagType.Tower);
                } else {
                    setTagForm(InventoryFormInitState);
                    setTagFormType(TagType.Inventory);
                }
                break;
            case "Add New Defect":
                setTagForm(DefectFormInitState);
                setTagFormType(TagType.Defect);
                enableTagDraw=true;
                break;
        
            default:
                break;
        }
        setDownloadedImage([{url:"",id:0,data:{id:""}}])
        setImageSelected([""])
        setMultiActionButton(ButtonActionType.Submit);
        setFormMode(FormMode.Write);
    }

    const hideSpinner=()=>{
        if(props.toggle){
            setTimeout(() => {
                nextIteration();
                props.setToggle(false);
            }, 10000);
        }
        else{
            setTimeout(()=>{
                nextIteration();
            },2000)
          
        }
    }

 const maximaizeImage = (index) => {
    setIsOpenImage(!imageOpen)
     let arr =[...toDeleteImageFile]
    //   arr.push(downloadedImage[index].data.id)
      downloadedImage.map(files=>{if(files.id==index)arr.push(files.data.id)})
    let data = downloadedImage.filter(files=>files.id!=index)
    if(data.length == 0)data=[{url:"",id:0,data:{id:""}}]
    setToDeleteImageFIle(arr)
    // setDownloadedImage(data)
    // setMultiActionButton(ButtonActionType.Save)
 }
 const deleteImage = async(id) => {
    let tempDownloadedImg = downloadedImage.filter(files=>files.data.id != id)
    let tempImageSelected = imageSelected.filter(img=>img!= id)
     const data = await deleteImageFileInDocs(id)
     if(data) IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, "Successfully deleted.", "", OutputMessageType.Toast));
    tempDownloadedImg.length>0?setDownloadedImage(tempDownloadedImg):setDownloadedImage([{url:"",id:0,data:{id:""}}])
    setImageSelected(tempImageSelected)
   
 }

 const imageCaroselJsx = () => {
    return (
            <ImageCarousel
               images={downloadedImage}
               showCarousel={imageOpen}
               toggleCarousel={(e)=>{setIsOpenImage(e)}}
               setImageData={()=>{}}
               // listOptions={theImages}
               listOptions={[{ name: "Existing Photo" , value: "", parent: "Existing Photo" }]}
               title={""}
               height={300}
               width={200}
               imageData={downloadedImage}
           />
    )
 }
 const returnUplaodPhotojsx = () => {
    return(
        <div className="col" style={{display: "flex", justifyContent: "center"}}> 
        <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="contained-button-file"
            disabled =  {multiActionButton == ButtonActionType.Submit || multiActionButton == ButtonActionType.Editing|| multiActionButton == ButtonActionType.Save?false:true}
            onChange={async(event)=>{
            if (event.target.files && event?.target?.files.length > 0) {
                setImageFile(event.target.files[0]);
                let temp = await addDragDroppedFilesToProjectShareList("user-planning-images",event.target.files[0])
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, "File successfully selected", "", OutputMessageType.Toast));
                if(multiActionButton == ButtonActionType.Edit ||  multiActionButton == ButtonActionType.Editing) setMultiActionButton(ButtonActionType.Save)
                if(temp){
                    await getImage( [temp.file.id])
                    setImageSelected([...imageSelected,temp.file.id])
                } 
            }
        }}
       />
    {/* <label htmlFor="contained-button-file"> */}
        <Badge style={{cursor: "pointer", width: "inherit", textAlign: "center",height:"100%"}} backgroundColor="primary" onClick={()=>{document.getElementById('contained-button-file')?.click()}}>  Upload Photo</Badge>   
    {/* </label> */}
    </div>
    )
 }
const options = [{ label: "low", value: "Low" }, { label: "Medium", value: "Medium" }, { label: "High", value: "High" }];

    return (
        <section>          
            {showModal && 


            <Modal
              isOpen={true}
              title={props.title}
              onClose={() => {setShowModal(false)}}
              closeOnEsc={true}
              style={{width: "1600px", height: "750px", maxWidth: "70%", maxHeight: "85%"}}
          >
              <ModalContent id="panoramicModal" style={{height: "100%-50px", padding: "0px", overflowY: "hidden"}}>
                <>
                    <div id="roundNround" style={{position: 'absolute', display: 'flex',  justifyContent:'center', alignItems:'center', height: '100%', maxHeight: "100%", width: '100%', backgroundColor: "white", left: '0px', zIndex: 10}}>
                      <ProgressRadial size="large" style={{width: "180px", height: "180px"}} indeterminate value={50} >Loading...</ProgressRadial>
                    </div>
                    <div id="threeCanvas" style={{position: 'absolute', display: 'flex',  justifyContent:'center', alignItems:'center', height: 'inherit', borderRadius: "0 0 4px 4px", zIndex: 1}}></div>
                    
                    {/* <div className="component-group container-fluid" style={{width: "100%", display: "flex", justifyContent: "center", bottom: "10px", position: "absolute"}}>
                        <Row className="row" style={{backgroundColor: "#a9a9a97a", width: "70%"}}>
                            <LabeledInput className="col" size="small" displayStyle="default" message="Success" placeholder="Enter text here..." status="positive"  />
                            <LabeledInput className="col" size="small" displayStyle="default" message="Warning" placeholder="Enter text here..." status="warning"  />
                            <LabeledInput className="col" size="small" displayStyle="default" message="Error" placeholder="Enter text here..." status="negative"  />
                            <LabeledInput className="col" size="small" displayStyle="default" message="Error" placeholder="Enter text here..." status="negative"  />
                            <LabeledInput className="col" size="small" displayStyle="default" message="Error" placeholder="Enter text here..." status="negative"  />
                            <LabeledInput className="col" size="small" displayStyle="default" message="Error" placeholder="Enter text here..." status="negative"  />
                        </Row>
                        <Row className="row" style={{backgroundColor: "#a9a9a97a", width: "70%"}}>
                            <div className="col" ><Badge backgroundColor="positive">Submit</Badge></div>
                            <div className="col" ><Badge backgroundColor="negative">Cancel</Badge></div>
                            <div className="col" ><Badge backgroundColor="primary">Existing Photo</Badge></div>
                            <div className="col" ><Badge backgroundColor="warning">Upload Photo</Badge></div>
                        </Row>
                    </div> */}
        {/* <DropdownButton
            style={{position: "absolute", bottom: "20px", left: "10px", zIndex: 1}}
            as={ButtonGroup}
            key={"up"}
            id={`dropdown-button-drop-up`}
            drop={"up"}
            variant="secondary"
            title={`Tag`}
            onClick={tagHandler}
          >
            <Dropdown.Item eventKey="1">Show All</Dropdown.Item>
            <Dropdown.Item eventKey="2">Hide All</Dropdown.Item>
            <Dropdown.Item eventKey="3">Add New Tag</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
          </DropdownButton>       */}

            <button className="iui-button" data-iui-size="small" style={{zIndex: 1, position: "absolute", bottom: "20px", left: "10px", backgroundColor: "#CCCCCC"}} onClick={tagHandler} name="Add New Tag" type="button">
                <span className="iui-button-icon" aria-hidden="true">
                    <SvgIssueReport/>
                    {/* <SvgAnnotation/> */}
                    {/* <svg viewBox="0 0 16 16" width="1rem" height="1rem" fill="var(--iui-color-icon-muted, currentColor)"><path d="M8.063 4.899A3.122 3.122 0 114.941 8.02a3.126 3.126 0 013.122-3.122m0-1a4.122 4.122 0 104.122 4.122 4.127 4.127 0 00-4.122-4.122zm0-.896a.52.52 0 00.518-.518V.518A.52.52 0 008.063 0a.52.52 0 00-.518.518v1.966a.519.519 0 00.518.518zM4.51 4.421a.52.52 0 000-.732L3.12 2.3a.519.519 0 00-.732 0 .519.519 0 000 .732l1.39 1.39a.52.52 0 00.732 0zM3.002 7.937a.52.52 0 00-.518-.518H.518A.52.52 0 000 7.937a.52.52 0 00.518.518h1.966a.519.519 0 00.518-.518zm1.419 3.553a.52.52 0 00-.732 0L2.3 12.88a.519.519 0 000 .732.519.519 0 00.732 0l1.39-1.39a.52.52 0 000-.732zm3.516 1.508a.52.52 0 00-.518.518v1.966a.52.52 0 00.518.518.52.52 0 00.518-.518v-1.966a.519.519 0 00-.518-.518zm3.553-1.419a.52.52 0 000 .732l1.39 1.39a.519.519 0 00.732 0 .519.519 0 000-.732l-1.39-1.39a.52.52 0 00-.732 0zm1.508-3.516a.52.52 0 00.518.518h1.966A.52.52 0 0016 8.063a.52.52 0 00-.518-.518h-1.966a.519.519 0 00-.518.518zM11.579 4.51a.52.52 0 00.732 0l1.39-1.39a.519.519 0 000-.732.519.519 0 00-.732 0l-1.39 1.39a.52.52 0 000 .732z"></path></svg> */}
                </span>
            </button>                   
            {panoramicMode == PanoramicMode.InShelter && <button className="iui-button" data-iui-size="small" style={{zIndex: 1, position: "absolute", bottom: "50px", left: "10px", backgroundColor: "#CCCCCC"}} onClick={tagHandler} name="Add New Defect" type="button">
                <span className="iui-button-icon" aria-hidden="true">
                    <SvgStatusWarning/>
                    {/* <svg viewBox="0 0 16 16" width="1rem" height="1rem" fill="var(--iui-color-icon-muted, currentColor)"><path d="M8.063 4.899A3.122 3.122 0 114.941 8.02a3.126 3.126 0 013.122-3.122m0-1a4.122 4.122 0 104.122 4.122 4.127 4.127 0 00-4.122-4.122zm0-.896a.52.52 0 00.518-.518V.518A.52.52 0 008.063 0a.52.52 0 00-.518.518v1.966a.519.519 0 00.518.518zM4.51 4.421a.52.52 0 000-.732L3.12 2.3a.519.519 0 00-.732 0 .519.519 0 000 .732l1.39 1.39a.52.52 0 00.732 0zM3.002 7.937a.52.52 0 00-.518-.518H.518A.52.52 0 000 7.937a.52.52 0 00.518.518h1.966a.519.519 0 00.518-.518zm1.419 3.553a.52.52 0 00-.732 0L2.3 12.88a.519.519 0 000 .732.519.519 0 00.732 0l1.39-1.39a.52.52 0 000-.732zm3.516 1.508a.52.52 0 00-.518.518v1.966a.52.52 0 00.518.518.52.52 0 00.518-.518v-1.966a.519.519 0 00-.518-.518zm3.553-1.419a.52.52 0 000 .732l1.39 1.39a.519.519 0 00.732 0 .519.519 0 000-.732l-1.39-1.39a.52.52 0 00-.732 0zm1.508-3.516a.52.52 0 00.518.518h1.966A.52.52 0 0016 8.063a.52.52 0 00-.518-.518h-1.966a.519.519 0 00-.518.518zM11.579 4.51a.52.52 0 00.732 0l1.39-1.39a.519.519 0 000-.732.519.519 0 00-.732 0l-1.39 1.39a.52.52 0 000 .732z"></path></svg> */}
                </span>
            </button>}
            {openTagForm && tagFormType == TagType.Tower && <Form>
                <fieldset style={{zIndex: 1, backgroundColor: "#6565657a", marginLeft: "100px", width: "80%", display: "flex", justifyContent: "center", bottom: "20px", position: "absolute", fontSize: "small", color: "white"}}>
                    <div className="component-group container-fluid">
                        <Form.Group className="" onChange={tagFormHandler}>

                                    <Row style={{height: "30px"}}>
                                        <div className="col"><LabeledInput style={{height: "20px", alignItems: "center"}}className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="default" name="siteCode" title="Site Code" placeholder="Site Code" label="Site Code"  value={(tagForm as TowerFormInterface).siteCode} /></div>
                                        <div className="col"><LabeledInput style={{height: "20px", alignItems: "center"}}className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="default" name="siteName" title="Site Name" placeholder="Site Name" label="Site Name"  value={(tagForm as TowerFormInterface).siteName}/></div>
                                        <div className="col"><LabeledInput style={{height: "20px", alignItems: "center"}}className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="default" name="owner" title="Owner" placeholder="Owner"  label="Owner"   value={(tagForm as TowerFormInterface).owner}/></div>
                                    </Row>
                                    <Row style={{height: "30px"}}>
                                        <div className="col"><LabeledInput style={{height: "20px", alignItems: "center"}}className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="default" name="distance" title="Distance" placeholder="Distance"  label="Distance" type="number"  value={(tagForm as TowerFormInterface).distance}/></div>
                                        <div className="col"><LabeledInput style={{height: "20px", alignItems: "center"}}className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="default" name="towerHeight" title="Tower Height" placeholder="Tower Height" label="Tower Height" type="number"  value={(tagForm as TowerFormInterface).towerHeight} /></div>
                                        <div className="col"><Row><div className="col" style={{display: "flex",alignItems:'end',fontWeight:600}} >LOS <ToggleSwitch disabled={formMode == FormMode.Read} name="LOS" className="col" checked={(tagForm as TowerFormInterface).LOS} icon={<SvgCheckmark />} style={{display: "flex",justifyContent:'end'}} /></div></Row></div>
                                    </Row>
                                    <Row>
                                    <div style={{display:"flex",width:"55vw",position:'relative',overflowY:"scroll",marginTop:"5px"}}>

                                            {imageOpen &&  downloadedImage  && downloadedImage[0].url!="" &&  imageCaroselJsx() }
                                            {downloadedImage&& downloadedImage[0].url!="" && downloadedImage.map((img)=>{
                                               
                                                let selctedInage = imageSelected.find(ind=>ind == img.data.id)
                                                    return(
                                                        <div style={{display:"flex",marginRight:"1rem",marginTop:"5px" ,border:selctedInage?"3px solid red":""}}>
                                                        <div style={{display:"flex"}}>
        
                                                            <IconButton size="small" styleType="borderless" title={""}style={{zIndex:1,position:"absolute",marginLeft:"25px"}} onClick={()=>maximaizeImage(img.id)}><SvgWindowMaximize height={"10px"}/></IconButton>
        
                                                        <img src={img?.url} height={"50px"} width={"50px"} style={{position:"relative"}} onClick={()=>{
                                                          
                                                          if(multiActionButton == ButtonActionType.Editing || multiActionButton == ButtonActionType.Save || multiActionButton == ButtonActionType.Submit){

                                                            let temp = [...imageSelected]
                                                           
                                                            const index = temp.indexOf(img.data.id);
                                                            if(!selctedInage)temp.push(img.data.id)
                                                                else if(selctedInage  && selctedInage!="" && selctedInage==imageSelected[index]){
                                                                   temp.splice(index, 1);
                                                                }
                                                            setImageSelected(temp)
                                                            if(multiActionButton != ButtonActionType.Submit)
                                                            temp.length>1?setMultiActionButton(ButtonActionType.Save):setMultiActionButton(ButtonActionType.Editing)
                                                                
                                                        }
                                                        }
                                                            }></img>  
                                              <IconButton size="small" styleType='borderless' title={""}style={{zIndex:1,position:"absolute",marginLeft:"25px",marginTop:'2rem'}} onClick={()=>deleteImage(img.data.id)}><SvgDelete style={{height:"15px"}} /></IconButton>
                     
                                                            </div>
                                                    </div>
                                                    )
                                               
                                            })}
                                            </div>
                                    </Row>
                        </Form.Group>
                        <Form.Group className="mt-1 mb-1 pl-1 pr-1 p-2" style={{marginBottom: "-10px", backgroundColor: "#ffe5e55c", borderRadius: "5px"}} onClick={actionHandler}>
                                    <Row>
                                        <div className="col" style={{display: "flex", justifyContent: "center"}}><Badge style={{cursor: "pointer", width: "inherit", textAlign: "center"}} backgroundColor="negative">Cancel</Badge></div>
                                        <div className="col" style={{display: "flex", justifyContent: "center"}}><Badge style={{cursor: "pointer", width: "inherit", textAlign: "center"}} backgroundColor="primary">Existing Photo</Badge></div>
                                        {returnUplaodPhotojsx()}
                                       <div className="col" style={{display: "flex", justifyContent: "center"}} onClick={multiActionButtonHandler}><Badge style={{cursor: "pointer", width: "inherit", textAlign: "center"}} backgroundColor="positive">{multiActionButton}</Badge></div>
                                       <div className="col" style={{display: "flex", justifyContent: "center"}}><Badge style={{cursor: "pointer", width: "inherit", textAlign: "center"}} backgroundColor="negative">Delete</Badge></div>
                                       </Row>
                        </Form.Group>
                    </div>
                </fieldset>
                </Form>
              }
            {openTagForm && tagFormType == TagType.Inventory && <Form>
                <fieldset style={{zIndex: 1, backgroundColor: "#6565657a", marginLeft: "100px", width: "80%", display: "flex", justifyContent: "center", bottom: "20px", position: "absolute", fontSize: "small", color: "white"}}>
                    <div className="component-group container-fluid">
                        <Form.Group onChange={tagFormHandler} style={{textShadow: "0 0 4px black"}}>
                                    <Row style={{height: "30px"}}>
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Asset Name" title="Asset Name" placeholder="Asset Name" name="assetName" value={(tagForm as InventoryFormInterface).assetName} /></div>
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Asset Group" title="Asset Group" placeholder="Asset Group" name="assetGroup" value={(tagForm as InventoryFormInterface).assetGroup}/></div>
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Operator" title="Operator" placeholder="Operator" name="operator" value={(tagForm as InventoryFormInterface).operator}/></div>
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Model" title="Model" placeholder="Model" name="model" value={(tagForm as InventoryFormInterface).model}/></div>
                                    </Row>
                                    <Row style={{height: "30px"}} className="">
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Manufacturer" title="Manufacturer" placeholder="Manufacturer" name="manufacturer" value={(tagForm as InventoryFormInterface).manufacturer} /></div>
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Technology" title="Technology" placeholder="Technology" name="technology" value={(tagForm as InventoryFormInterface).technology}/></div>
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Dimensions" title="Dimensions" placeholder="Dimensions" name="dimensions"type="number" value={(tagForm as InventoryFormInterface).dimensions}/></div>
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Azimuth" title="Azimuth" placeholder="Azimuth" name="azimuth"type="number" value={(tagForm as InventoryFormInterface).azimuth}/></div>
                                    </Row>
                                    <Row style={{height: "30px"}}>
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Mechanical Tilt" title="Mechanical Tilt" placeholder="Mechanical Tilt" name="mechanicalTilt" type="number" value={(tagForm as InventoryFormInterface).mechanicalTilt} /></div>
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Electrical TIlt" title="Electrical TIlt" placeholder="Electrical Tilt" name="electricalTilt" type="number" value={(tagForm as InventoryFormInterface).electricalTilt} /></div>
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} className="col" disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Feeder Type" title="Feeder Type" placeholder="Feeder Type" name="feederType" value={(tagForm as InventoryFormInterface).feederType}/></div>
                                    </Row>
                                    <Row>
                                    <div style={{display:"flex",width:"55vw",position:'relative',overflowY:"scroll",marginTop:"5px"}}>

                                          {imageOpen &&  downloadedImage  && downloadedImage[0].url!="" &&  imageCaroselJsx() }
                                          {downloadedImage&& downloadedImage[0].url!="" && downloadedImage.map((img)=>{
                                               
                                               let selctedInage = imageSelected.find(ind=>ind == img.data.id)
                                                   return(
                                                       <div style={{display:"flex",marginRight:"1rem",marginTop:"5px" ,border:selctedInage?"3px solid red":""}}>
                                                       <div style={{display:"flex"}}>
       
                                                           <IconButton size="small" styleType="borderless" title={""}style={{zIndex:1,position:"absolute",marginLeft:"25px"}} onClick={()=>maximaizeImage(img.id)}><SvgWindowMaximize height={"10px"}/></IconButton>
       
                                                       <img src={img?.url} height={"50px"} width={"50px"} style={{position:"relative"}} onClick={()=>{
                                                          if(multiActionButton == ButtonActionType.Editing || multiActionButton == ButtonActionType.Save || multiActionButton == ButtonActionType.Submit){
                                                      
                                                        let temp = [...imageSelected]
                                                        const index = temp.indexOf(img.data.id);
                                                        if(!selctedInage)temp.push(img.data.id)
                                                            else if(selctedInage  && selctedInage!="" && selctedInage==imageSelected[index]){
                                                               temp.splice(index, 1);
                                                            }
                                                        setImageSelected(temp)
                                                        if(multiActionButton != ButtonActionType.Submit)
                                                            temp.length>1?setMultiActionButton(ButtonActionType.Save):setMultiActionButton(ButtonActionType.Editing)
                                                    }
                                                       }
                                                           }></img> 
                                              <IconButton size="small" styleType='borderless' title={""}style={{zIndex:1,position:"absolute",marginLeft:"25px",marginTop:'2rem'}} onClick={()=>deleteImage(img.data.id)}><SvgDelete style={{height:"15px"}} /></IconButton>
                      
                                                           </div>
                                                   </div>
                                                   )
                                              
                                           })}
</div>
                                    </Row>
                        </Form.Group>
                        <Form.Group className="mt-1 mb-1 pl-1 pr-1 p-2" style={{marginBottom: "-10px", backgroundColor: "#ffe5e55c", borderRadius: "5px"}} onClick={actionHandler}>
                                    <Row>
                                        <div className="col" style={{display: "flex", justifyContent: "center"}}><Badge style={{cursor: "pointer", width: "inherit", textAlign: "center"}} backgroundColor="negative">Cancel</Badge></div>
                                        <div className="col" style={{display: "flex", justifyContent: "center"}}><Badge style={{cursor: "pointer", width: "inherit", textAlign: "center"}} backgroundColor="primary">Existing Photo</Badge></div>
                                        {returnUplaodPhotojsx()}
                                        <div className="col" style={{display: "flex", justifyContent: "center"}} onClick={multiActionButtonHandler}><Badge style={{cursor: "pointer", width: "inherit", textAlign: "center"}} backgroundColor="positive">{multiActionButton}</Badge></div>
                                        <div className="col" style={{display: "flex", justifyContent: "center"}}><Badge style={{cursor: "pointer", width: "inherit", textAlign: "center"}} backgroundColor="negative">Delete</Badge></div>
                                    </Row>
                        </Form.Group>
                    </div>
                </fieldset>
                </Form>
            }
            {openTagForm && tagFormType == TagType.Defect && <Form>
                <fieldset style={{zIndex: 1, backgroundColor: "#6565657a", marginLeft: "100px", width: "80%", display: "flex", justifyContent: "center", bottom: "20px", position: "absolute", fontSize: "small", color: "white"}}>
                    <div className="component-group container-fluid">
                    <Form.Group onChange={tagFormHandler} style={{textShadow: "0 0 4px black"}}>

                                    <Row style={{height: "40px"}}>
                                        <div className="col-3"><LabeledInput style={{padding:"0 0 0 8px"}} disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Owner" title="Owner" placeholder="Owner" name="owner" value={(tagForm as DefectFormInterface).owner} /></div>
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Defective Structure" title="Defective Structure" placeholder="Defective Structure" name="defectiveStructure" value={(tagForm as DefectFormInterface).defectiveStructure}/></div>
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Defective Component" title="Defective Component" placeholder="Defective Component" name="defectiveComponent" value={(tagForm as DefectFormInterface).defectiveComponent}/></div>
                                    </Row>
                                    <Row style={{height: "40px", overflow:"hidden"}}>
                                        <div className="col-3"><LabeledSelect style={{padding:"0 0 0 8px"}} disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Criticality" title="Criticality" onChange={(e)=>setTagForm((tagForm: any)=>{return {...tagForm, criticality: e == "Low" ? DefectEnum.Low : e == "Medium" ? DefectEnum.Medium : DefectEnum.High}})} options={options} value={(tagForm as DefectFormInterface)?.criticality}/></div>
                                        <div className="col-2"><LabeledInput style={{padding:"0 0 0 8px"}} disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Defect" title="Defect" placeholder="Defect" name="defect" value={(tagForm as DefectFormInterface).defect} /></div>
                                        <div className="col-4"><LabeledInput style={{padding:"0 0 0 8px"}} disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Inventory Priority" title="Inventory Priority" placeholder="Inventory Priority" name="interventionPriority" value={(tagForm as DefectFormInterface).interventionPriority}/></div>
                                        <div className="col-3"><LabeledInput style={{padding:"0 0 0 8px"}} disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Height (m)" title="Height (m)" placeholder="Height (m)" name="height" type="number" value={(tagForm as DefectFormInterface).height}/></div>
                                    </Row>
                                    <Row style={{height: "30px"}}>
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Length (m)" title="Length (m)" placeholder="Length (m)" name="length" type="number" value={(tagForm as DefectFormInterface).length} /></div>
                                        <div className="col"><LabeledInput style={{padding:"0 0 0 8px"}} disabled={formMode == FormMode.Read} size="small" displayStyle="inline" label="Inspector" title="Inspector" placeholder="Inspector" name="inspector" value={(tagForm as DefectFormInterface).inspector}/></div>
                                        <div  style={{display:"flex",alignItems:'center'}} className="col">
                                        <label style={{fontWeight:600}} className="col-6">Installation</label>
                                            <input disabled={formMode == FormMode.Read} id="installationDate" title="Installation Date" placeholder="Installation Date" name="installationDate" className="col-6 input-date" type="date" value={(tagForm as DefectFormInterface)?.installationDate?.toString()??""} />
                                        </div>
                                        <div  style={{display:"flex",alignItems:'center'}} className="col">
                                            <label style={{fontWeight:600}} className="col-6">Inspection</label>
                                            <input disabled={formMode == FormMode.Read} id="inspectionDate" title="Installation Date" placeholder="Installation Date" name="inspectionDate" className="col-6 input-date" type="date" value={(tagForm as DefectFormInterface)?.inspectionDate?.toString()??""} />                                        </div>
                                        {/* <div  style={{width:'100%'}}>Installation</div><input disabled={formMode == FormMode.Read} id="installationDate" title="Installation Date" placeholder="Installation Date" name="installationDate" className="col-6" type="date" value={(tagForm as DefectFormInterface)?.installationDate?.toString()??""} /> */}
                                        {/* <div  style={{width:'100%'}}className="col-6">Inspection</div><input disabled={formMode == FormMode.Read} id="inspectionDate" title="Installation Date" placeholder="Installation Date" name="inspectionDate" className="col-6" type="date" value={(tagForm as DefectFormInterface)?.inspectionDate?.toString()??""} /> */}
                                        {/* <LabeledInput type="Date" className="col" size="small" displayStyle="inline" label="Installation Date" title="Installation Date" placeholder="Installation Date" name="installationDate" value={(tagForm as DefectFormInterface).installationDate}/>
                                        <LabeledInput className="col" size="small" displayStyle="inline" label="Inspection Date" title="Inspection Date" placeholder="Installation Date" name="inspectionDate" value={(tagForm as DefectFormInterface).inspectionDate}/> */}
                                    </Row>
                                    <Row >
                                    <div style={{display:"flex",width:"55vw",position:'relative',overflowY:"scroll",marginTop:"5px"}}>

                                            {imageOpen &&  downloadedImage  && downloadedImage[0].url!="" &&  imageCaroselJsx() }
                                            {downloadedImage&& downloadedImage[0].url!="" && downloadedImage.map((img)=>{
                                               
                                               let selctedInage = imageSelected.find(ind=>ind == img.data.id)
                                                   return(
                                                       <div style={{display:"flex",marginRight:"1rem",marginTop:"5px" ,border:selctedInage?"3px solid red":""}}>
                                                       <div style={{display:"flex"}}>
       
                                                           <IconButton size="small" styleType="borderless" title={""}style={{zIndex:1,position:"absolute",marginLeft:"25px"}} onClick={()=>maximaizeImage(img.id)}><SvgWindowMaximize height={"10px"}/></IconButton>
       
                                                       <img src={img?.url} height={"50px"} width={"50px"} style={{position:"relative"}} onClick={()=>{
                                                          if(multiActionButton == ButtonActionType.Editing || multiActionButton == ButtonActionType.Save || multiActionButton == ButtonActionType.Submit){
                                                        
                                                        let temp = [...imageSelected]
                                                        const index = temp.indexOf(img.data.id);
                                                        if(!selctedInage)temp.push(img.data.id)
                                                            else if(selctedInage  && selctedInage!="" && selctedInage==imageSelected[index]){
                                                               temp.splice(index, 1);
                                                            }
                                                        setImageSelected(temp)
                                                        if(multiActionButton != ButtonActionType.Submit)
                                                            temp.length>1?setMultiActionButton(ButtonActionType.Save):setMultiActionButton(ButtonActionType.Editing)
                                                    }
                                                       }
                                                           }></img>      
                                              <IconButton size="small" styleType='borderless' title={""}style={{zIndex:1,position:"absolute",marginLeft:"25px",marginTop:'2rem'}} onClick={()=>deleteImage(img.data.id)}><SvgDelete style={{height:"15px"}} /></IconButton>
                                                                           
                                                           </div>
                                                   </div>
                                                   )
                                              
                                           })}
                                        </div>
                                    </Row>
                        </Form.Group>
                        <Form.Group className="mt-1 mb-1 pl-1 pr-1 p-2" style={{marginBottom: "-10px", backgroundColor: "#ffe5e55c", borderRadius: "5px"}} onClick={actionHandler}>
                                    <Row>
                                        <div className="col" style={{display: "flex", justifyContent: "center"}}><Badge style={{cursor: "pointer", width: "inherit", textAlign: "center"}} backgroundColor="negative">Cancel</Badge></div>
                                        <div className="col" style={{display: "flex", justifyContent: "center"}}><Badge style={{cursor: "pointer", width: "inherit", textAlign: "center"}} backgroundColor="primary">Existing Photo</Badge></div>
                                        {returnUplaodPhotojsx()}
                                        <div className="col" style={{display: "flex", justifyContent: "center"}} onClick={multiActionButtonHandler}><Badge style={{cursor: "pointer", width: "inherit", textAlign: "center"}} backgroundColor="positive">{multiActionButton}</Badge></div>
                                        <div className="col" style={{display: "flex", justifyContent: "center"}}><Badge style={{cursor: "pointer", width: "inherit", textAlign: "center"}} backgroundColor="negative">Delete</Badge></div>
                                    </Row>
                        </Form.Group>
                    </div>
                </fieldset>
                </Form>
            }
                    
                    <canvas id="renderCanvas"></canvas>
                    {/* <iframe id="inlineFrameExample"
                        title="Panoramic view"
                        width="100%"
                        height="730px"
                        src={props.insightImageUrl}
                        // src={"https://westeurope-dev.insite.bentley.com/insite_app_js/html/360_panoramic.html?site=Vantage_Segovia_Fine_Tunned&client=testOTIQ&pId=58862c13-ca4a-4020-801a-4b9aee1c22b7"}
                        style={{display: "none"}}
                        onLoadedData={()=>{

                        }}
                        onLoad={()=>{
                            setTimeout(() => {                                
                                const  ifr=document.getElementById('inlineFrameExample')
                                ifr?.setAttribute("style", "display: block;")
                                const  rnr=document.getElementById('roundNround')
                                rnr?.setAttribute("style", "display: none;")
                            }, 20000);
                            // setLoading(false)
                            }
                        }

                    /> */}
                </>
                
              </ModalContent>


                {/* <div id="modal" className='imageViewModel'>
                    <ModalDialog title={props.title} Y={100} width={props.width} height={props.height} onClose={() => props.toggleImageModal()}>
                    <div className="imodel-viewport-dialog-container">
                        <div id='container'>
                        {loading ? (
                                        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100%'}}>
                                             <Spinner size={SpinnerSize.XLarge} />
                                        </div>
                                        ) :null
                        }                      
                            <iframe id="inlineFrameExample"
                                title="Inline Frame"
                                width="100%"
                                height="100%"
                                src={props.insightImageUrl}
                                style={{display: "none"}}
                                onLoad={hideSpinner}
                                >
                            </iframe>
                        </div>
                    </div>    
                    </ModalDialog>
                 </div> */}
            </Modal>

            }
        </section>
    );
};


export default ImageSlider360;