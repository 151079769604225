import { AccessToken } from "@itwin/core-bentley";
import { store } from "../../store/rootReducer";
import { RootState } from "../../store/States";
import { ConfigManager } from "../../config/ConfigManager";
import { sanitizeUrl } from "../../config/UrlManager";
//----------------------------------------------------------------------
/*
 * Get and Post data relate to Gratings and the relative mounts/pipes
 */ 
let privateAccessToken: AccessToken;
const listener = () => {
    setProjectIdUrl(store.getState());
}

function setProjectIdUrl(state: RootState) {
    privateAccessToken = state.auth.accessTokenStatePrivateAPI.accessToken!;
}

store.subscribe(listener);


export default class GratingsClient {
    //----------------------------------------------------------------------
    constructor() {}
    //----------------------------------------------------------------------
    /* Get the Mountings data , used for Pipe data that will be used in 
     * Auto pipe generation in the AddEditPolygonShapeTool.tsx
     */ 
    public static async getMountingsDataJason(token: string = privateAccessToken): Promise<any> {
        // tslint:disable-next-line:no-console
        // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
        const url = `${ConfigManager.openToweriQUrl}/v1.1/${ConfigManager.projectId}/MountingsData`;

        return fetch(sanitizeUrl(url), {
            method: "get",
            headers: {
                Authorization: token,
            },
        }).then(async (response: Response) => {
            if (!response.ok) {
                return null;
            }
            return response.json();
        }).then((data: any) => {
            return data;
        });
    }
    //----------------------------------------------------------------------
    /*
     *Not in use 
     */ 
    public static async getMountingsDataJson(token: string = privateAccessToken, apiVersion: string = "v1.1"): Promise<any> {
        // tslint:disable-next-line:no-console
        // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
        const url = `${ConfigManager.openToweriQUrl}/${apiVersion}/${ConfigManager.projectId}/MountingsData/Mount`;

        return fetch(sanitizeUrl(url), {
            method: "get",
            headers: {
                Authorization: token,
            },
        }).then(async (response: Response) => {
            if (!response.ok) {
                return null;
            }
            return response.json();
        }).then((data: any) => {
            return data;
        });
    }
    //----------------------------------------------------------------------
    /*
     * Used to Put/Save Grating Data including is supportig pipes 
     * invoked in AddEditPolygonShapeTool.tsx
     */ 
    public static async putGratingDataJason(token: string = privateAccessToken, mountdata: any, apiVersion: string = "v1.1"): Promise<any> {
        if (!mountdata || !mountdata.grating) {
            return;
        }
        // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
        const url = `${ConfigManager.openToweriQUrl}/${apiVersion}/${ConfigManager.projectId}/MountingsData/Mount/`;
        return fetch(sanitizeUrl(url), {
            method: "put",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(mountdata),
        }).then(async (res: Response) => {
            if (!res.ok) {
                return null;
            }
            return "Success.";
        }).then((data: any) => {
            return data;
        });
    }
    //----------------------------------------------------------------------
    /*
     */ 
    public static async deleteGrating(token: string = privateAccessToken, deleteData: any) {
        // const ConfigManager.projectId = ConfigManager.ConfigManager.projectId;
        const url = `${ConfigManager.openToweriQUrl}/v1.1/${ConfigManager.projectId}/MountingsData/Mount/${deleteData.mountId}/${deleteData.gratingId}`;

        return fetch(sanitizeUrl(url), {
            method: "delete",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        }).then(async (response: Response) => {
            if (!response.ok) {
                return "err";
            }
            return response.text();
        }).then((data: any) => {
            if (data === "err") {
                return null;
            }
            return "Successfully deleted.";
        });
    }
    //----------------------------------------------------------------------
}
