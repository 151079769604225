import { Id64String } from "@itwin/core-bentley";
import { Arc3d, Cone, CurveChainWithDistanceIndex, GeometryQuery, IndexedPolyface, IndexedPolyfaceVisitor, LineSegment3d, LineString3d, Loop, Path, Point3d, PolyfaceBuilder, Transform, Vector3d } from "@itwin/core-geometry";
import { ColorByName, ColorDef, LinePixels, ViewFlagOverrides } from "@itwin/core-common";
import { BeButtonEvent, DecorateContext, Decorator, EventHandled, GraphicBranch, GraphicBuilder, GraphicType, HitDetail, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, RenderGraphic, SelectionTool } from "@itwin/core-frontend";
import { UiFramework, WidgetState } from "@itwin/appui-react";
import { StagePanelLocation, SyncUiEventDispatcher } from "@itwin/appui-react";
import { PlaceTowerMarkerTool } from "../../components/tools/PlaceTowerMarkerTool";
import { DecoratorHelper } from "./DecoratorHelper";
import { ConfigManager } from "../../../config/ConfigManager";
import { store } from "../../../store/rootReducer";
import * as egm96 from "egm96-universal";
import { towVertSeg, towerStructureData } from "../../../store/detectedData/apiDataTypes";
import { sanitizeUrl } from "../../../config/UrlManager";


export interface TowerModelData {actWidthTop: number, actWidthBot: number, actHeight?: number}
export interface TowerGeometry {
    geometry?: GeometryQuery;
    cylinder: Cone;
    color: ColorDef;
    fill: boolean;
    fillColor: ColorDef;
    lineThickness: number;
    edges: boolean;
    linePixels: LinePixels;
    transientId?: Id64String;
    startPos: Point3d;
    endPos: Point3d;
    topWid: number;
    baseWid: number;
    name: string;
    modelData?: TowerModelData | undefined
}

export interface TowerVerticalSegments {
  altitudeBottom: number,
  altitudeTop: number,
  height: number,
  widthBottom: number,
  topWidth?: number,
  widthTop?: number
}

export interface TowerStructurePostData {
  baseCenter?: number[],
  baseAltitude?: number,
  baseWidth?: number,
  bearing?: number,
  concreteHeight?: number,
  epsgCode?: number,
  lightningRodHeight?: number,
  nLegs?: number,
  topAltitude?: number,
  topAppurtenanceHeight?: number,
  topSteelElevation?: number,
  topWidth?: number,
  towerTiltX?: number,
  towerTiltY?: number,
  type?: string,
  towerVerticalSegments?: TowerVerticalSegments[],
}

export interface selectedTowerSection {transId: Id64String, sectionName: string, theSection: TowerGeometry|null, isModified: boolean, creating: boolean, created: boolean};

export class TowerStructureDecorator implements Decorator {
    public useCachedDecorations: true | undefined = true;
    public static towerGeometryInfo: towerStructureData;
    public static displayedPropertyWindow: boolean = false;
    public selectedPart: string | undefined = "";
    public previousSelection: undefined | {name: string, startPos: any, endPos: any} = undefined;
    public hideMarkers: boolean = false;
    public nameIdMap: Map<string, Id64String> = new Map<string, Id64String>();
    public objectIdMap: Map<Id64String, string> = new Map<Id64String, string>();

    // tslint:disable:naming-convention
    public towerCylinders: TowerGeometry[] = [];
    public backupCylinders: TowerGeometry[] = [];
    public decoratorName = "TowerStructureDecorator";
    private colors: ColorDef[] = [ColorDef.fromString("rgb(128,128,128)"), ColorDef.red, ColorDef.blue, ColorDef.green, ColorDef.fromString("rgb(79,146,142)"), ColorDef.fromString("rgb(145,112,86)")];
    private static readonly defaultSelectionState: selectedTowerSection = {transId: "", sectionName: "", theSection: null, isModified: false, creating: false, created: false};
    public static selectedSection: selectedTowerSection = this.defaultSelectionState;
    public static UpdatedSections: TowerGeometry[] = [];
    public resetSelectedSection = () => TowerStructureDecorator.selectedSection = TowerStructureDecorator.defaultSelectionState;
    static TowerStructureDecorator: {};
    private LegCoords: any[] = [];
  
    public terminate() {
        this.towerCylinders = [];
        this.objectIdMap = new Map<Id64String, string>();
        this.nameIdMap = new Map<Id64String, string>();
        IModelApp.viewManager.invalidateDecorationsAllViews();
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
        TowerStructureDecorator.displayedPropertyWindow = false;
        this.displayPropertyWindow(true);
    }
    /**
     * Add Cylinders into iModel
     * @param context context
     */
    public decorate(context: DecorateContext): void {
        this.towerCylinders.forEach((entry) => {
            if (!entry.geometry) {
              const builder = PolyfaceBuilder.create();
              builder.addCone(entry.cylinder);
              const polyface = builder.claimPolyface(false);
              entry.geometry = polyface;
            }
          });
        this.createGraphics(context);
    }
    /**
     * convert to radian
     * @param degrees degree val
     * @returns radian
     */
    public radians(degrees: number) {
      const pi = Math.PI;
      return degrees * (pi / 180);
    }

  
    public convertPostToGetTowerVSData(towerVerticalSegmentsGet: TowerVerticalSegments[]): towVertSeg[] {
      return towerVerticalSegmentsGet.map(vsg=>{
        const retVal: towVertSeg = {
          altitude_bottom: vsg.altitudeBottom,
          altitude_top: vsg.altitudeTop,
          height: vsg.height,
          width_bottom: vsg.widthBottom,
        }
        if(vsg.topWidth)retVal.top_width = vsg.topWidth;
        if(vsg.widthTop)retVal.width_top = vsg.widthTop;
      return retVal;
    });
    }
  
    public convertGetToPostTowerVSData(towerVerticalSegmentsGet: towVertSeg[]): TowerVerticalSegments[] {
      return towerVerticalSegmentsGet.map(vsg=>{
        const retVal: TowerVerticalSegments = {
          altitudeBottom: vsg.altitude_bottom,
          altitudeTop: vsg.altitude_top,
          height: vsg.height,
          widthBottom: vsg.width_bottom,
        }
        if(vsg.top_width)retVal.topWidth = vsg.top_width;
        if(vsg.width_top)retVal.widthTop = vsg.width_top;
        return retVal;
      });
    }

  
    public convertGetToPostTowerData(towerGetData: towerStructureData, _reqdFields: string[] = ["All"]): TowerStructurePostData {

      let towerStructurePostData: TowerStructurePostData = {};
      
      if(_reqdFields[0] == "All"){
        towerStructurePostData = {
          baseAltitude: towerGetData.base_altitude,
          baseCenter: towerGetData.base_center,
          baseWidth: towerGetData.base_width,
          bearing: towerGetData.bearing,
          concreteHeight: towerGetData.concrete_height,
          epsgCode: towerGetData.epsg_code,
          lightningRodHeight: towerGetData.lightning_rod_height,
          nLegs: towerGetData.nLegs,
          topAltitude: towerGetData.top_altitude,
          topAppurtenanceHeight: towerGetData.top_appurtenance_height,
          topSteelElevation: towerGetData.top_steel_elevation,
          topWidth: towerGetData.top_width,
          towerTiltX: towerGetData.tower_tilt_x,
          towerTiltY: towerGetData.tower_tilt_y,
          towerVerticalSegments: this.convertGetToPostTowerVSData(towerGetData.tower_vertical_segments),
          type: towerGetData.type,
        }
      } else {
        _reqdFields.forEach(e=>{
          switch (e) {
            case "baseWidth":
              towerStructurePostData.baseWidth = towerGetData.base_width;
              towerStructurePostData.towerVerticalSegments = this.convertGetToPostTowerVSData(towerGetData.tower_vertical_segments);
              break;
          
            default:
              break;
          }
        })
      }
      return towerStructurePostData;
    }

  
    public updateGetTowerData(selPartName: string): towerStructureData {

      const editSelection = "up@Full".split("@");
      // const selPartName = editPartName.split("@")[1];
      const towPrevInfo=JSON.parse(JSON.stringify(TowerStructureDecorator.towerGeometryInfo));
      const towerType = towPrevInfo.type;
      let towPrevInfoCopy=JSON.parse(JSON.stringify(towPrevInfo));
      const towCyl = this.towerCylinders;
      const towData:any = {};
      const index = towCyl.findIndex(e=>e.name.includes(selPartName));
      const selTowPart = towCyl[index];
      const towLen=towCyl[index].endPos.distance(towCyl[index].startPos);
      const stPt = towCyl[index].startPos;
      const endPt = towCyl[index].endPos;

      // // if(towerType === "monopole"){
        if((editSelection[0].match(/right|left|front|back|up|down/i))){
            if(selPartName==="Tower Pole" || editSelection[1]==="Start" && ((towPrevInfo.base_center[0]) !== (towCyl[index].endPos.x) || (towPrevInfo.base_center[1]) !== (towCyl[index].endPos.y))){
                const X = stPt.x - endPt.x; // values in UTM (meters)
                const Y = stPt.y - endPt.y; // values in UTM (meters)
                const Z = stPt.z - endPt.z; // values in UTM (meters)
                const new_tower_tilt_x = 180*(Math.atan(X/Z))/Math.PI;
                const new_tower_tilt_y = 180*(Math.atan(Y/Z))/Math.PI;
                towData.towerTiltX = new_tower_tilt_x;
                towData.towerTiltY = new_tower_tilt_y;
                towData.baseCenter = [towCyl[index].endPos.x, towCyl[index].endPos.y]
                
                towPrevInfoCopy.tower_tilt_x = towData.towerTiltX;
                towPrevInfoCopy.tower_tilt_y = towData.towerTiltY;
                towPrevInfoCopy.base_center = towData.baseCenter;
            } else if(selPartName === "Tower top"){
              let towLen: number = 0;
              if(towerType == "monopole"){
                const poleIindex = towCyl.findIndex(e=>e.name.includes("Tower Pole"));
                towLen=towCyl[poleIindex].endPos.distance(towCyl[index].startPos);
              } else {
                let tempIndex = towCyl.findIndex(e=>e.name.includes("Concrete level"));
                let tempPart = towCyl[tempIndex];
                // const GLPosAvg = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
                // const GLPosAvg = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
                const CLPosAvg = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (selTowPart.startPos.y+selTowPart.endPos.y)*0.5, (selTowPart.startPos.z+selTowPart.endPos.z)*0.5)

                const basePt = new Point3d(towPrevInfo.base_center[0], towPrevInfo.base_center[1], towPrevInfo.base_altitude);
                let topPt = basePt.clone();
                topPt.z = towPrevInfo.top_altitude;
                // topPt = DecoratorHelper.extract3DPoint(topPt);
                topPt = selTowPart.startPos.interpolate(0.5, selTowPart.endPos);


                const bottomPt = DecoratorHelper.extract3DPoint(basePt.clone());
                const height = topPt.z - bottomPt.z;
                topPt.x = topPt.x + (height) * Math.tan(this.radians(towPrevInfo.tower_tilt_x));
                topPt.y = topPt.y + (height) * Math.tan(this.radians(towPrevInfo.tower_tilt_y));
                const extraR: number = 0.05; // to display monopole tower outside pole diameter
                towLen = topPt.distance(CLPosAvg);
              }

        
              towData.topSteelElevation = towLen;
              towData.topAltitude = towPrevInfo.base_altitude + towLen;
              towPrevInfoCopy.top_steel_elevation = towData.topSteelElevation;
              towPrevInfoCopy.top_altitude = towData.topAltitude;
              
              let tempIndex = towCyl.findIndex(e=>e.name.includes("Lightning Rod"));
              if(tempIndex !== -1){
                let tempPart = towCyl[tempIndex];
                const LRHPos = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5);
                towData.lightningRodHeight = LRHPos.distance(selTowPart.startPos);
                towPrevInfoCopy.lightning_rod_height = towData.lightningRodHeight;
              }

              tempIndex = towCyl.findIndex(e=>e.name.includes("Top end of equipment"));
              if(tempIndex !== -1){
                let tempPart = towCyl[tempIndex];
                const TAPos = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5);
                towData.topAppurtenanceHeight = TAPos.distance(selTowPart.startPos.interpolate(0.5, selTowPart.endPos));
                towPrevInfoCopy.top_appurtenance_height = towData.topAppurtenanceHeight;
              }
            } else if(selPartName === "Lightning Rod"){
                let tempIndex = towCyl.findIndex(e=>e.name.includes("Lightning Rod"));
                let tempPart = towCyl[tempIndex];
                const LRPos = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
                if(towerType == "monopole"){
                  tempIndex = towCyl.findIndex(e=>e.name.includes("Tower Pole"));
                  if(tempIndex > -1){
                      let towPole = towCyl[tempIndex];
                  
                      towData.lightningRodHeight = LRPos.z-towPole.startPos.z;
                      towPrevInfoCopy.lightning_rod_height = towData.lightningRodHeight;
                  }
                } else {
                  tempPart = towCyl.find(e=>e.name.match(/Tower top/))!;
                  let topPt = tempPart.startPos.interpolate(0.5, tempPart.endPos);
                  towPrevInfoCopy.lightning_rod_height = LRPos.z-topPt.z;
                }
            } else if(selPartName === "Top end of equipment"){
                let tempIndex = towCyl.findIndex(e=>e.name.includes("Top end of equipment"));
                let tempPart = towCyl[tempIndex];
                if(towerType == "monopole"){
                  tempIndex = towCyl.findIndex(e=>e.name.includes("Tower Pole"));
                  if(tempIndex > -1){
                      let towPole = towCyl[tempIndex];
                      // const TAPos = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
                      const TAPos = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
                  
                      towData.topAppurtenanceHeight = TAPos.z-towPole.startPos.z;
                      towPrevInfoCopy.top_appurtenance_height = towData.topAppurtenanceHeight;
                  }
                } else {
                  const TAPos = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
                  let ttIndex = towCyl.findIndex(e=>e.name.includes("Tower top"));
                  let ttPart = towCyl[ttIndex];

                  const basePt = new Point3d(towPrevInfo.base_center[0], towPrevInfo.base_center[1], towPrevInfo.base_altitude);
                  let topPt = basePt.clone();
                  topPt.z = towPrevInfo.top_altitude;
                  topPt = DecoratorHelper.extract3DPoint(topPt);

                  // towData.topAppurtenanceHeight = TAPos.z-basePt.z;
                  towData.topAppurtenanceHeight = TAPos.z-ttPart.startPos.interpolate(0.5, ttPart.endPos).z;
                  towPrevInfoCopy.top_appurtenance_height = towData.topAppurtenanceHeight;

                }
            } else if(selPartName === "Concrete level"){
                let tempIndex = towCyl.findIndex(e=>e.name.includes("Ground level"));
                let tempPart = towCyl[tempIndex];
                // const GLPosAvg = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
                const GLPosAvg = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
                const CLPosAvg = selTowPart.startPos.interpolate(0.5, selTowPart.endPos);//.x)*0.5, (selTowPart.startPos.y+selTowPart.endPos.y)*0.5, (selTowPart.startPos.z+selTowPart.endPos.z)*0.5)
                
                let towLen: number = 0;
                if(towerType == "monopole"){
                  const poleIndex = towCyl.findIndex(e=>e.name.includes("Tower Pole"));
                  towLen=towCyl[poleIndex].startPos.distance(CLPosAvg);
                } else {

                  const basePt = new Point3d(towPrevInfo.base_center[0], towPrevInfo.base_center[1], towPrevInfo.base_altitude);
                  const centerPt = basePt.clone();
                  const bottomPt = DecoratorHelper.extract3DPoint(basePt.clone());
                  let topPt = basePt.clone();
                  topPt.z = towPrevInfo.top_altitude;
                  topPt = DecoratorHelper.extract3DPoint(topPt);


                  const height = topPt.z - bottomPt.z;
                  topPt.x = topPt.x + (height) * Math.tan(this.radians(towPrevInfo.tower_tilt_x));
                  topPt.y = topPt.y + (height) * Math.tan(this.radians(towPrevInfo.tower_tilt_y));
                  const extraR: number = 0.05; // to display monopole tower outside pole diameter
                  towLen = topPt.distance(CLPosAvg);
                }

                towData.concreteHeight = CLPosAvg.distance(GLPosAvg);
                towData.baseAltitude=towPrevInfo.top_altitude-towLen;
                towData.topSteelElevation = towLen;

                towPrevInfoCopy.concrete_height = towData.concreteHeight;
                towPrevInfoCopy.base_altitude = towData.baseAltitude;
                towPrevInfoCopy.top_steel_elevation = towData.topSteelElevation;
            } else if(selPartName === "Ground level"){
                let tempIndex = towCyl.findIndex(e=>e.name.includes("Concrete level"));
                let tempPart = towCyl[tempIndex];

                const CLPosAvg = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
                const GLPosAvg = new Point3d((selTowPart.startPos.x+selTowPart.endPos.x)*0.5, (selTowPart.startPos.y+selTowPart.endPos.y)*0.5, (selTowPart.startPos.z+selTowPart.endPos.z)*0.5)
                
                towData.concreteHeight = CLPosAvg.distance(GLPosAvg);
                towPrevInfoCopy.concrete_height = towData.concreteHeight;
            }
        } else if((editSelection[0].match(/lenInc|lenDec/i))){
            switch(selPartName){
              case "Tower Pole":{
                  towData.topSteelElevation = towLen;
                  towData.topAltitude = towPrevInfo.base_altitude + towLen;
                  towPrevInfoCopy.top_steel_elevation = towData.topSteelElevation;
                  towPrevInfoCopy.top_altitude = towData.topAltitude;
                  const avgPt = new Point3d((selTowPart.startPos.x+selTowPart.endPos.x)*0.5, (selTowPart.startPos.y+selTowPart.endPos.y)*0.5, (selTowPart.startPos.z+selTowPart.endPos.z)*0.5);
                  
                  let tempIndex = towCyl.findIndex(e=>e.name.includes("Lightning Rod"));
                  let tempPart = towCyl[tempIndex];
                  const LRHPos = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5);
                  towData.lightningRodHeight = LRHPos.z-selTowPart.startPos.z;
                  towPrevInfoCopy.lightning_rod_height = towData.lightningRodHeight;
                  
                  tempIndex = towCyl.findIndex(e=>e.name.includes("Top end of equipment"));
                  tempPart = towCyl[tempIndex];
                  const TAPos = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5);
                  towData.topAppurtenanceHeight = TAPos.z-selTowPart.startPos.z;
                  towPrevInfoCopy.top_appurtenance_height = towData.topAppurtenanceHeight;
              }
              case "Lightning Rod":{

              }
            }
        } else if((editSelection[0].match(/incDia|decDia/i))){
          if(editSelection[1] === "Start"){
              towData.topWidth = (towCyl[index].topWid*2)-0.1;
              towPrevInfoCopy.top_width = towData.topWidth;
          }
          if(editSelection[1] === "End"){
              towData.baseWidth = (towCyl[index].baseWid*2)-0.1;
              towPrevInfoCopy.base_width = towData.baseWidth;
          }
          if(editSelection[1] === "Whole"){
              towData.topWidth = (towCyl[index].topWid*2)-0.1;
              towData.baseWidth = (towCyl[index].baseWid*2)-0.1;
              towPrevInfoCopy.top_width = towData.topWidth;
              towPrevInfoCopy.base_width = towData.baseWidth;
          }
        }
        return towPrevInfoCopy;
      }

  
    public saveTower(selPartName: any, modVals: any="up@Full"): void {
      const editSelection = modVals.split("@");
      // const selPartName = editPartName.split("@")[1];
      const towPrevInfo=TowerStructureDecorator.towerGeometryInfo;
      const towerType = towPrevInfo.type;
      let towPrevInfoCopy=JSON.parse(JSON.stringify(towPrevInfo));
      const towDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
      const towCyl = towDec.towerCylinders;
      const towData:any = {};
      const index = towCyl.findIndex(e=>e.name.includes(selPartName));
      const selTowPart = towCyl[index];
      const towLen=towCyl[index].endPos.distance(towCyl[index].startPos);
      const stPt = towCyl[index].startPos;
      const endPt = towCyl[index].endPos;
      
      if(towerType === "monopole"){
        if((editSelection[0].match(/right|left|front|back|up|down/i))){
            if(selPartName==="Tower Pole" || editSelection[1]==="Start" && ((towPrevInfo.base_center[0]) !== (towCyl[index].endPos.x) || (towPrevInfo.base_center[1]) !== (towCyl[index].endPos.y))){
                const X = stPt.x - endPt.x; // values in UTM (meters)
                const Y = stPt.y - endPt.y; // values in UTM (meters)
                const Z = stPt.z - endPt.z; // values in UTM (meters)
                const new_tower_tilt_x = 180*(Math.atan(X/Z))/Math.PI;
                const new_tower_tilt_y = 180*(Math.atan(Y/Z))/Math.PI;
                towData.towerTiltX = new_tower_tilt_x;
                towData.towerTiltY = new_tower_tilt_y;
                towData.baseCenter = [towCyl[index].endPos.x, towCyl[index].endPos.y]
                
                towPrevInfoCopy.tower_tilt_x = towData.towerTiltX;
                towPrevInfoCopy.tower_tilt_y = towData.towerTiltY;
                towPrevInfoCopy.base_center = towData.baseCenter;
            } else if(selPartName === "Tower top"){
              const poleIindex = towCyl.findIndex(e=>e.name.includes("Tower Pole"));
              const towLen=towCyl[poleIindex].endPos.distance(towCyl[index].startPos);
        
              towData.topSteelElevation = towLen;
              towData.topAltitude = towPrevInfo.base_altitude + towLen;
              towPrevInfoCopy.top_steel_elevation = towData.topSteelElevation;
              towPrevInfoCopy.top_altitude = towData.topAltitude;
              
              let tempIndex = towCyl.findIndex(e=>e.name.includes("Lightning Rod"));
              if(tempIndex !== -1){
                let tempPart = towCyl[tempIndex];
                const LRHPos = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5);
                towData.lightningRodHeight = LRHPos.distance(selTowPart.startPos);
                towPrevInfoCopy.lightning_rod_height = towData.lightningRodHeight;
              }

              tempIndex = towCyl.findIndex(e=>e.name.includes("Top end of equipment"));
              if(tempIndex !== -1){
                let tempPart = towCyl[tempIndex];
                const TAPos = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5);
                towData.topAppurtenanceHeight = TAPos.distance(selTowPart.startPos);
                towPrevInfoCopy.top_appurtenance_height = towData.topAppurtenanceHeight;
              }
            } else if(selPartName === "Lightning Rod"){
                let tempIndex = towCyl.findIndex(e=>e.name.includes("Lightning Rod"));
                let tempPart = towCyl[tempIndex];
                tempIndex = towCyl.findIndex(e=>e.name.includes("Tower Pole"));
                if(tempIndex > -1){
                    let towPole = towCyl[tempIndex];
                    const LRPos = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
                
                    towData.lightningRodHeight = LRPos.z-towPole.startPos.z;
                    towPrevInfoCopy.lightning_rod_height = towData.lightningRodHeight;
                }
            } else if(selPartName === "Top end of equipment"){
                let tempIndex = towCyl.findIndex(e=>e.name.includes("Top end of equipment"));
                let tempPart = towCyl[tempIndex];
                tempIndex = towCyl.findIndex(e=>e.name.includes("Tower Pole"));
                if(tempIndex > -1){
                    let towPole = towCyl[tempIndex];
                    const TAPos = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
                
                    towData.topAppurtenanceHeight = TAPos.z-towPole.startPos.z;
                    towPrevInfoCopy.top_appurtenance_height = towData.topAppurtenanceHeight;
                }
            } else if(selPartName === "Concrete level"){
                let tempIndex = towCyl.findIndex(e=>e.name.includes("Ground level"));
                let tempPart = towCyl[tempIndex];
                const poleIndex = towCyl.findIndex(e=>e.name.includes("Tower Pole"));
                const GLPosAvg = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
                const CLPosAvg = new Point3d((selTowPart.startPos.x+selTowPart.endPos.x)*0.5, (selTowPart.startPos.y+selTowPart.endPos.y)*0.5, (selTowPart.startPos.z+selTowPart.endPos.z)*0.5)
                const towLen=towCyl[poleIndex].startPos.distance(CLPosAvg);

                towData.concreteHeight = CLPosAvg.distance(GLPosAvg);
                towData.baseAltitude=towPrevInfo.top_altitude-towLen;
                towData.topSteelElevation = towLen;

                towPrevInfoCopy.concrete_height = towData.concreteHeight;
                towPrevInfoCopy.base_altitude = towData.baseAltitude;
                towPrevInfoCopy.top_steel_elevation = towData.topSteelElevation;
            } else if(selPartName === "Ground level"){
                let tempIndex = towCyl.findIndex(e=>e.name.includes("Concrete level"));
                let tempPart = towCyl[tempIndex];

                const CLPosAvg = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
                const GLPosAvg = new Point3d((selTowPart.startPos.x+selTowPart.endPos.x)*0.5, (selTowPart.startPos.y+selTowPart.endPos.y)*0.5, (selTowPart.startPos.z+selTowPart.endPos.z)*0.5)
                
                towData.concreteHeight = CLPosAvg.distance(GLPosAvg);
                towPrevInfoCopy.concrete_height = towData.concreteHeight;
            }
        } else if((editSelection[0].match(/lenInc|lenDec/i))){
            switch(selPartName){
              case "Tower Pole":{
                  towData.topSteelElevation = towLen;
                  towData.topAltitude = towPrevInfo.base_altitude + towLen;
                  towPrevInfoCopy.top_steel_elevation = towData.topSteelElevation;
                  towPrevInfoCopy.top_altitude = towData.topAltitude;
                  const avgPt = new Point3d((selTowPart.startPos.x+selTowPart.endPos.x)*0.5, (selTowPart.startPos.y+selTowPart.endPos.y)*0.5, (selTowPart.startPos.z+selTowPart.endPos.z)*0.5);
                  
                  let tempIndex = towCyl.findIndex(e=>e.name.includes("Lightning Rod"));
                  let tempPart = towCyl[tempIndex];
                  const LRHPos = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5);
                  towData.lightningRodHeight = LRHPos.z-selTowPart.startPos.z;
                  towPrevInfoCopy.lightning_rod_height = towData.lightningRodHeight;
                  
                  tempIndex = towCyl.findIndex(e=>e.name.includes("Top end of equipment"));
                  tempPart = towCyl[tempIndex];
                  const TAPos = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5);
                  towData.topAppurtenanceHeight = TAPos.z-selTowPart.startPos.z;
                  towPrevInfoCopy.top_appurtenance_height = towData.topAppurtenanceHeight;
              }
              case "Lightning Rod":{

              }
            }
        } else if((editSelection[0].match(/incDia|decDia/i))){
          if(editSelection[1] === "Start"){
              towData.topWidth = (towCyl[index].topWid*2)-0.1;
              towPrevInfoCopy.top_width = towData.topWidth;
          }
          if(editSelection[1] === "End"){
              towData.baseWidth = (towCyl[index].baseWid*2)-0.1;
              towPrevInfoCopy.base_width = towData.baseWidth;
          }
          if(editSelection[1] === "Whole"){
              towData.topWidth = (towCyl[index].topWid*2)-0.1;
              towData.baseWidth = (towCyl[index].baseWid*2)-0.1;
              towPrevInfoCopy.top_width = towData.topWidth;
              towPrevInfoCopy.base_width = towData.baseWidth;
          }
        }
      }

      // Currently we are supporting only monopole tower edits and save functionality. Lattice tower updates remains to be implemented. 
      // Once implemented, we can remove this if condition. : Praful
      if(towerType === "monopole"){
        const projectId = ConfigManager.projectId;
        const url = sanitizeUrl(`${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Site/Structure/`);
        fetch(url, {
        method: "put",
        headers: {
            Authorization: store.getState().auth.accessTokenStatePrivateAPI.accessToken!,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(towData),
        }).then(async (res: Response) => {
        if (!res.ok) {
            return null;
        }
        return "success";
        }).then((data: any) => {
            if(data==="success"){
                towDec.terminate();
                if(towDec.hideMarkers)towDec.hideMarkers=false;
                TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;
                towDec.loadShapes(towPrevInfoCopy);
                IModelApp.viewManager.invalidateDecorationsAllViews();
                IModelApp.viewManager.selectedView?.invalidateCachedDecorations(towDec);
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Tower edits saved successfully.", "", OutputMessageType.Toast));                
            } else {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, `Error occured: Tower edits not saved.`, "", OutputMessageType.Toast)); 
            }
        });
      } else {
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `We are not supporting saving the lattice tower updates in the database.`, "", OutputMessageType.Toast)); 
      }
    }


    public get getTowerGeometry(){
      return TowerStructureDecorator.towerGeometryInfo; 
    }

    public drawConcreteLevel(data: towerStructureData){
      const basePt = new Point3d(data.base_center[0], data.base_center[1], data.base_altitude);
      const centerPt = basePt.clone();
      // Ground is positioned below the base center with a distance of concrete height, Concrete level is positioned at base center: Praful
      // Base Point (Concrete)
      this.drawCylinder(centerPt, 5, ColorDef.create(ColorByName.red), "Concrete level@Marker");
      if(!this.hideMarkers)this.backupCylinders=this.towerCylinders;
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }

    public drawGroundLevel(data: towerStructureData){
      const basePt = new Point3d(data.base_center[0], data.base_center[1], data.base_altitude);
      const centerPt = basePt.clone();
      // Base Point
      centerPt.z = basePt.z - data.concrete_height;
      this.drawCylinder(centerPt, 10, ColorDef.create(ColorByName.gray), "Ground level@Marker");
      centerPt.z-=0.1;
      this.drawCylinder(centerPt, (data.lightning_rod_height + Math.abs(data.base_altitude-data.top_altitude)/2), ColorDef.create(ColorByName.aliceBlue), "Fall zone@Marker");
      if(!this.hideMarkers)this.backupCylinders=this.towerCylinders;
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }
    
    public drawLightningRodLevel(data: towerStructureData){
      const basePt = new Point3d(data.base_center[0], data.base_center[1], data.base_altitude);
      const centerPt = basePt.clone();
      // Base Point
      centerPt.z = basePt.z - data.concrete_height;

      let x = data.base_center[0];
      let y = data.base_center[1];
      let z = data.top_altitude+data.top_appurtenance_height; // z utm coordinates of the center of the tower at base altitude

      let h = z - data.base_altitude;                         // elevation relative to base altitude (height on the tower);
      x = x + (h)*Math.tan(Math.PI*(data.tower_tilt_x)/180);  // Top center x coordinate in UTM
      y = y + (h)*Math.tan(Math.PI*(data.tower_tilt_y)/180);  // Top center y coordinate in UTM        

      const ang = Math.atan2((data.base_center[1]-y), (data.base_center[0]-x));
      // top Point
      const topCentPt=centerPt.clone();
      topCentPt.x = x;
      topCentPt.y = y;
      topCentPt.z = data.top_altitude;

      // top Point (lightning_rod_height)
      if (data.lightning_rod_height != null) {
        topCentPt.z = data.top_altitude + data.lightning_rod_height;
        this.drawCylinder(topCentPt, 1, this.colors[4], 'Lightning Rod@Marker');
      }
      if(!this.hideMarkers)this.backupCylinders=this.towerCylinders;
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }
    
    public drawHighestAppurtenanceLevel(data: towerStructureData){
      const basePt = new Point3d(data.base_center[0], data.base_center[1], data.base_altitude);
      const centerPt = basePt.clone();
      // Base Point
      centerPt.z = basePt.z - data.concrete_height;

      let x = data.base_center[0];
      let y = data.base_center[1];
      let z = data.top_altitude+data.top_appurtenance_height; // z utm coordinates of the center of the tower at base altitude

      let h = z - data.base_altitude;                         // elevation relative to base altitude (height on the tower);
      x = x + (h)*Math.tan(Math.PI*(data.tower_tilt_x)/180);  // Top center x coordinate in UTM
      y = y + (h)*Math.tan(Math.PI*(data.tower_tilt_y)/180);  // Top center y coordinate in UTM        

      const ang = Math.atan2((data.base_center[1]-y), (data.base_center[0]-x));
      // top Point
      const topCentPt=centerPt.clone();
      topCentPt.x = x;
      topCentPt.y = y;
      topCentPt.z = data.top_altitude;

      // top Point (lightning_rod_height)
      if (data.top_appurtenance_height != null) {
        topCentPt.z = data.top_altitude + data.top_appurtenance_height;
        this.drawCylinder(topCentPt, data.top_appurtenance_height > 0 ? 1.5 : 2, this.colors[3], "Top end of equipment@Marker");
      }
      if(!this.hideMarkers)this.backupCylinders=this.towerCylinders;
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }
    
    public drawTowerTopLevel(data: towerStructureData){
      const basePt = new Point3d(data.base_center[0], data.base_center[1], data.base_altitude);
      const centerPt = basePt.clone();
      let x = data.base_center[0];
      let y = data.base_center[1];
      let z = data.top_altitude+data.top_appurtenance_height; // z utm coordinates of the center of the tower at base altitude

      let h = z - data.base_altitude;                         // elevation relative to base altitude (height on the tower);
      x = x + (h)*Math.tan(Math.PI*(data.tower_tilt_x)/180);  // Top center x coordinate in UTM
      y = y + (h)*Math.tan(Math.PI*(data.tower_tilt_y)/180);  // Top center y coordinate in UTM        

      const ang = Math.atan2((data.base_center[1]-y), (data.base_center[0]-x));
      // top Point
      const topCentPt=centerPt.clone();
      topCentPt.x = x;
      topCentPt.y = y;
      topCentPt.z = data.top_altitude;

      this.drawCylinder(topCentPt, data.top_appurtenance_height > 0 ? 2 : 1.5, this.colors[2], "Tower top@Marker");
      if(!this.hideMarkers)this.backupCylinders=this.towerCylinders;
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }

    /**
     * Recive Data by calling Structure API
     * @param data Tower Structure Object
     */
    public loadShapes(data: any) {
        // used to display information in roperty window
        TowerStructureDecorator.towerGeometryInfo = data;

        this.drawConcreteLevel(data);
        this.drawGroundLevel(data);
        this.drawTowerTopLevel(data);

        // top Point (lightning_rod_height)
        if (data.lightning_rod_height != null) {
          this.drawLightningRodLevel(data);
        }
        if (data.top_appurtenance_height != null) {
          this.drawHighestAppurtenanceLevel(data);
        }
        // draw monopole tower
        if (data.type === "monopole") {
          this.drawMonoPole(data);
        } else {
          this.drawLatticeLegs(data);
        }
        // if(!this.hideMarkers)this.backupCylinders=this.towerCylinders;
        // IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }

    public drawLatticeLegs = (data: towerStructureData) => {
      this.LegCoords = this.drawLatticeSection(data);
      if(!this.hideMarkers)this.backupCylinders=this.towerCylinders;
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }

    public drawMonoPole = (data: towerStructureData) => {
      this.drawCylindricalPole(data);
      if(!this.hideMarkers)this.backupCylinders=this.towerCylinders;
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }

    /**
     * lattice tower
     * @param data lattice tower data
     */
    private drawLatticeSection(data: any) {
      const result = this.calculateLegsCoordinates(data);
      // tslint:disable-next-line:forin
      let i=0;
      for (const r in result) {
        const points = result[r];
        for(let s=0; s < points.length; s++){
          const radius = 0.05*points.length;
          // const radius = 0.01*points.length*(this.getTowerGeometry.base_width);
          // const radius = 0.01*(this.getTowerGeometry.base_width)*0.01*(this.getTowerGeometry.top_altitude-this.getTowerGeometry.base_altitude)*points.length;
          const bottomPt = this.extract3dPoint(new Point3d(points[s][0][0], points[s][0][1], points[s][0][2]));
          const topPt = this.extract3dPoint(new Point3d(points[s][1][0], points[s][1][1], points[s][1][2]));
          this.createCylinder(bottomPt, topPt, 0.1, 0.1, this.colors[5], `latticeSection_${i}_${s}`);
        }
        i++;
      }
      return result;
    }

    /**
     * convrt to spatial
     * @param pt convert utm points to spatial point
     * @returns point3d object
     */
    public extract3dPoint(pt: Point3d) {
      const iModel = UiFramework.getIModelConnection()!;
      const cart = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
      pt = DecoratorHelper.ExtractSpatialXYZ(cart, pt.x, pt.y, pt.z, iModel);
      return pt;
    }

    public clearTiltPoleSegmentForLattice(){
      this.towerCylinders = this.towerCylinders.filter(tc=>!tc.name.match(/segment-pole|segment-joint/));
    }

    public drawTiltPoleSegmentForLattice(){
      const legCoords = this.LegCoords;
      const baseCoords: any[] = [], topCoords: any[] = [];
      const nLegs = TowerStructureDecorator.towerGeometryInfo.nLegs;
      Object.entries(legCoords).map((lc: any)=>{
        baseCoords.push(lc[1][0]);
        topCoords.push(lc[1][lc[1].length-1]);
      });
      let x=0, y=0, z=0;
      baseCoords.forEach(e=>{x+=e[0][0];y+=e[0][1];z+=e[0][2];});
      // console.log("x, y: ",x, y, x/4, y/4);
      const centroidPtBase = new Point3d(x/nLegs, y/nLegs, z/nLegs);
      x=0; y=0; z=0;
      topCoords.forEach(e=>{x+=e[1][0];y+=e[1][1];z+=e[1][2];});
      // console.log("x, y: ",x, y, x/4, y/4);
      const centroidPtTop = new Point3d(x/nLegs, y/nLegs, z/nLegs);

      const bottomPt = this.extract3dPoint(centroidPtBase);
      const topPt = this.extract3dPoint(centroidPtTop);
      const towLen = bottomPt.distance(topPt);
      const reqdLen = towLen*0.005;

      // this.createCylinder(bottomPt, topPt, TowerStructureDecorator.towerGeometryInfo.base_width/2, TowerStructureDecorator.towerGeometryInfo.top_width/2, this.colors[5], "Lattice pole");

      const theFactor = 0.378;
      const extremists: {bottom: Point3d, top: Point3d, widthBottom: number, widthTop: number}[] = [];
      const points: {point: Point3d, widthTop: number, widthBottom: number}[] = []
      for(let i=0; i < TowerStructureDecorator.towerGeometryInfo.tower_vertical_segments.length; i++){
        const baseCoords: Point3d[] = [], topCoords: Point3d[] = []
        for(const l in legCoords){
          baseCoords.push(legCoords[l][i][0]);
          topCoords.push(legCoords[l][i][1]);
        }
        let x=0, y=0, z=0;
        baseCoords.forEach(e=>{x+=e[0];y+=e[1];z+=e[2];});
        // console.log("x, y: ",x, y, x/4, y/4);
        const centroidPtBase = new Point3d(x/nLegs, y/nLegs, z/nLegs);
        x=0; y=0; z=0;
        topCoords.forEach(e=>{x+=e[0];y+=e[1];z+=e[2];});
        // console.log("x, y: ",x, y, x/4, y/4);
        const centroidPtTop = new Point3d(x/nLegs, y/nLegs, z/nLegs);

        const bottomPt = this.extract3dPoint(centroidPtBase.clone());
        const topPt = this.extract3dPoint(centroidPtTop.clone());

        const index = TowerStructureDecorator.towerGeometryInfo.tower_vertical_segments.length-1-i;
        const widthBottom = TowerStructureDecorator.towerGeometryInfo.tower_vertical_segments[index].width_bottom;
        const widthTop = TowerStructureDecorator.towerGeometryInfo.tower_vertical_segments[index].width_top! || TowerStructureDecorator.towerGeometryInfo.tower_vertical_segments[index].top_width!;
        // this.createCylinder(bottomPt, topPt, Math.ceil(widthBottom/2), Math.ceil(widthTop/2), this.colors[5], "Lattice pole", null, false);
        
        const tempLen = bottomPt.distance(topPt);
        const factor = reqdLen/tempLen;
        let adnTopFactor = 1;
        let adnBotFactor = 1;
        if(i == 0)adnBotFactor=2;
        if(i == TowerStructureDecorator.towerGeometryInfo.tower_vertical_segments.length-1)adnTopFactor=2;
        const adjustedBotPt = bottomPt.interpolate(factor*adnBotFactor, topPt); 
        const adjustedTopPt = topPt.interpolate(factor*adnTopFactor, bottomPt);
        
        if(i==0){
          points.push({point: bottomPt, widthTop, widthBottom}, {point: adjustedBotPt, widthTop, widthBottom}, {point: adjustedTopPt, widthTop, widthBottom});
          if(TowerStructureDecorator.towerGeometryInfo.tower_vertical_segments.length == 1)points.push({point: topPt, widthTop, widthBottom})
        } else if(i==TowerStructureDecorator.towerGeometryInfo.tower_vertical_segments.length-1)points.push({point: adjustedBotPt, widthTop, widthBottom}, {point: adjustedTopPt, widthTop, widthBottom}, {point: topPt, widthTop, widthBottom});
        else points.push({point: adjustedBotPt, widthTop, widthBottom}, {point: adjustedTopPt, widthTop, widthBottom});

        if(i==0){
          adnBotFactor=2;
          extremists.push({bottom: bottomPt, top: adjustedBotPt, widthBottom, widthTop});
        }
        extremists.push({bottom: adjustedBotPt, top: adjustedTopPt, widthBottom, widthTop});
        
        if(i == TowerStructureDecorator.towerGeometryInfo.tower_vertical_segments.length-1)extremists.push({bottom: bottomPt, top: topPt, widthBottom, widthTop});
        
        this.createCylinder(adjustedBotPt, adjustedTopPt, (widthBottom/2)*(theFactor*nLegs), (widthTop/2)*(theFactor*nLegs), ColorDef.fromAbgr(ColorByName.salmon), `segment-pole-${i+1}`, null, false, {actWidthTop: widthTop, actWidthBot: widthBottom }, 140);
        // this.createCylinder(bottomPt, topPt, (widthBottom/2)*(theFactor*nLegs), (widthTop/2)*(theFactor*nLegs), this.colors[5], "Lattice pole", null, false);
      }
      for(let i=0; i/2 < points.length/2; i+=2){
        let widthTop=points[i].widthTop, widthBottom = points[i].widthBottom;
        
        if(i==0){
          widthTop=widthBottom;
        } else if(i==Math.floor(points.length-2)){
          widthBottom=widthTop;
        } else {
          widthTop = points[i].widthTop;
          widthBottom = points[i].widthTop;
        }
        this.createCylinder(points[i].point, points[i+1].point, (widthBottom/2)*(theFactor*nLegs), (widthTop/2)*(theFactor*nLegs), ColorDef.fromAbgr(ColorByName.dodgerBlue), `segment-joint-${i/2+1}`, null, true, {actWidthTop: widthTop, actWidthBot: widthBottom }, 10);
      }
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }



    /**
     * calculate Legs
     * @param data data retrieved from API call
     * @returns leg point
     */
    private calculateLegsCoordinates(data: any) {
      const legs: any = {};
      const noOflegs = data.nLegs;
      for (let i = 0; i < noOflegs; i++) {
        let coords: any = [];
        const legAngle = (this.radians(data.bearing) + (2 * Math.PI / noOflegs) * i) % (2 * Math.PI);
        // tslint:disable-next-line:forin
        for (const sct in data.tower_vertical_segments) {
          // ## bottom coordinate
          const section = data.tower_vertical_segments[sct];
          const width = section.width_bottom;                                                                           // # tower width - distace from leg to leg
          const radius = ((width / 2) / Math.sin(Math.PI / noOflegs))   ;                                               // # tower radius - distance from center to leg
          let xDeviation = (section.altitude_bottom - data.base_altitude) * Math.tan(this.radians(data.tower_tilt_x));  // # deviation on x axis due tower/model tilt
          let yDeviation = (section.altitude_bottom - data.base_altitude) * Math.tan(this.radians(data.tower_tilt_y));  // # deviation on y axis due tower/model tilt
          const point1 = [data.base_center[0] + radius * Math.sin(legAngle) + xDeviation,
                        data.base_center[1] + radius * Math.cos(legAngle) + yDeviation,
                      section.altitude_bottom];
                      point1.push({widthTop: section.width_top || section.top_width, widthBottom: section.width_bottom})
          // ## top coordinates
          const w = section.width_top || section.top_width;                                                             // # tower width - distace from leg to leg
          const r = ((w / 2) / Math.sin(Math.PI / data.nLegs));                                                         // # tower radius - distance from center to leg
          xDeviation = (section.altitude_top - data.base_altitude) * Math.tan(this.radians(data.tower_tilt_x));         // # deviation on x axis due tower/model tilt
          yDeviation = (section.altitude_top - data.base_altitude) * Math.tan(this.radians(data.tower_tilt_y));         // # deviation on y axis due tower/model tilt
          const point2 = [data.base_center[0] + r * Math.sin(legAngle) + xDeviation,
                          data.base_center[1] + r * Math.cos(legAngle) + yDeviation,
                      section.altitude_top];
                      point2.push({widthTop: section.width_top || section.top_width, widthBottom: section.width_bottom})

          coords.push([point1, point2]);
        }
        coords.sort((a: any, b: any) => a[2] - b[2]);
        coords = coords.reverse();
        // coords.push({widthTop: section.width_top})
        legs[`leg_${i}`] = coords;
      }
      return legs;
    }
    /**
     * draw poles
     * @param basePt base point
     * @param data object returns from TowerStrcture API
     */
    private drawCylindricalPole (data: any) {
      //THE IF BLOCK OF THE CODE NEEDS TO BE TESTED WITH VARIOUS PROJECTS FOR ITS PROPER APPEARANCE
      // if (data.tower_vertical_segments && data.tower_vertical_segments.length){  //This block of code draws the monopole sections.
      //   const tvs = data.tower_vertical_segments;
      //   for(let i=tvs.length-1; i >= 0; i--){
      //     let bottomPt:any={};
      //     if(i===tvs.length-1){
      //       bottomPt = this.extract3dPoint(basePt.clone());
      //     } else {
      //       bottomPt = this.extract3dPoint(basePt.clone());
      //       const height = tvs[i+1].height;
      //       bottomPt.x = bottomPt.x + (height) * Math.tan(this.radians(data.tower_tilt_x));
      //       bottomPt.y = bottomPt.y + (height) * Math.tan(this.radians(data.tower_tilt_y));
      //       bottomPt.z+=height;
      //     }
          
      //     let topPt = bottomPt.clone();
      //     topPt.z = tvs[i].altitude_top;
      //     topPt = this.extract3dPoint(topPt);
  
      //     const height = tvs[i].height;
      //     topPt.x = topPt.x + (height) * Math.tan(this.radians(data.tower_tilt_x));
      //     topPt.y = topPt.y + (height) * Math.tan(this.radians(data.tower_tilt_y));
      //     const extraR: number = 0.5; // to display monopole tower outside pole diameter
      //     this.createCylinder(bottomPt, topPt, (tvs[i].width_bottom * 0.5) + extraR, (tvs[i].top_width * 0.5) + extraR, this.colors[5], nameId+"-"+i);
      //   }
      // } else {
        const basePt = new Point3d(data.base_center[0], data.base_center[1], data.base_altitude);
        const bottomPt = this.extract3dPoint(basePt.clone());
        let topPt = basePt.clone();
        topPt.z = data.top_altitude;
        topPt = this.extract3dPoint(topPt);

        const height = topPt.z - bottomPt.z;
        topPt.x = topPt.x + (height) * Math.tan(this.radians(data.tower_tilt_x));
        topPt.y = topPt.y + (height) * Math.tan(this.radians(data.tower_tilt_y));
        const extraR: number = 0.05; // to display monopole tower outside pole diameter
        const baseWid = (data.base_width * 0.5) + extraR;
        const topWid = (data.top_width * 0.5) + extraR;
        this.createCylinder(bottomPt, topPt, baseWid, topWid, this.colors[5], "Tower Pole", null, true, {actWidthBot: data.base_width, actWidthTop: data.top_width, actHeight: height});
      // }
    }
    /**
     * draw cylinder
     * @param centerPt center point
     * @param width width of cylinder
     * @param colorIndex color position
     */
    public drawCylinder(centerPt: Point3d, radius: number, color: ColorDef, nameId: string) {
        const iModel = UiFramework.getIModelConnection()!;
        const cart = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
        centerPt = DecoratorHelper.ExtractSpatialXYZ(cart, centerPt.x, centerPt.y, centerPt.z, iModel);
        const bottomPt = centerPt.plusScaled(Vector3d.unitZ(), -0.02);
        const topPt = centerPt.plusScaled(Vector3d.unitZ(), 0.02);
        this.createCylinder(bottomPt, topPt, radius * 2, radius * 2, color, nameId);
    }

    public resetObjectIds() {
      this.objectIdMap = new Map<string, Id64String>();
      this.towerCylinders.forEach(tc=>{if(tc.transientId)this.objectIdMap.set(tc.transientId, `towerGeom#${tc.name}`)});
      return this.objectIdMap;
    }  

    /**
     * create cylinder and add entry in towercylinders
     * @param bottomPt bottompt
     * @param topPt top point4
     * @param width width
     * @param colorIndex colorindex
     */
    private createCylinder(bottomPt: Point3d, topPt: Point3d, baseWidth: number, topWidth: number, color: ColorDef, nameId: string, recreatedCylInfo: any = null, capped: boolean = true, _modelData: TowerModelData|undefined = undefined, _opacity: number = 255-Math.round(255*store.getState().detectedData.objectOpacityState.tower.value)) {
      const baseCylinder = Cone.createAxisPoints(bottomPt, topPt, baseWidth, topWidth, capped);
      let theOpacity = _opacity;
      if(baseCylinder){
        if(this.hideMarkers && !nameId.includes(this.selectedPart!)){
          theOpacity = 255;
        }
      }
      const tc: TowerGeometry = {
        color: color,
        cylinder: baseCylinder!,
        edges: false,
        fill: true,
        fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(color.tbgr, theOpacity)),
        linePixels: LinePixels.Solid,
        lineThickness: 1,
        name: nameId,
        startPos: topPt,
        endPos: bottomPt,
        baseWid: baseWidth,
        topWid: topWidth,
        modelData: _modelData,
        transientId: recreatedCylInfo != null ? recreatedCylInfo.transientId! : !nameId.match(/segment-pole|Fall zone/) ? IModelApp.viewManager.selectedView!.iModel.transientIds.getNext() : null, // generate and store a unique ID each time
      };
      if(nameId.match(/Fall zone/))tc.transientId = undefined;
      if(baseCylinder){
        if(!this.hideMarkers){
          this.towerCylinders.push(tc);
          // this.nameIdMap.set(`towerGeom#${tc.name}`, tc.transientId);
          if(!nameId.match(/Fall zone/))this.objectIdMap.set(tc.transientId!, `towerGeom#${tc.name}`);
        } else if(this.hideMarkers && nameId.includes(this.selectedPart!)){
          this.towerCylinders.push(tc);
          // this.nameIdMap.set(`towerGeom#${tc.name}`, tc.transientId);
          if(!nameId.match(/Fall zone/))this.objectIdMap.set(tc.transientId!, `towerGeom#${tc.name}`);
        } else {
          tc.transientId=undefined;
          this.towerCylinders.push(tc);
        }
      }
    }

  /**
   * Updtae Cylinder Radius
   * @param _name name of Cylinder
   * @param _change increase cylinder radius with val
   * @returns true if successful
   */
   public updateCylinderRadius(_name: string, _change: number, selection: string): boolean {
    const jsonIndex = this.towerCylinders.findIndex((e) => e.name === _name);
    if (jsonIndex === -1) return false;

    const decorator = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
    const theCyl = decorator.towerCylinders[jsonIndex];
    decorator.towerCylinders.splice(jsonIndex, 1);
    const allPipes=decorator.towerCylinders;
    if(theCyl){
        if(selection==="Start")theCyl.topWid+=_change;
        else if(selection==="End")theCyl.baseWid+=_change;
        else if(selection==="Whole"){theCyl.topWid+=_change;theCyl.baseWid+=_change;}

        const topRadius = (theCyl.topWid+_change <= 0.05 && _change < 0) ? 0.05 : theCyl.topWid+_change;
        const baseRadius = (theCyl.baseWid+_change <= 0.05 && _change < 0) ? 0.05 : theCyl.baseWid+_change;
        decorator.terminate();
        decorator.towerCylinders=allPipes;
        decorator.createCylinder(theCyl.endPos, theCyl.startPos, baseRadius, topRadius, theCyl.color, theCyl.name);
    }
    TowerStructureDecorator.displayedPropertyWindow = true;
    // this.displayPropertyWindow();
    IModelApp.viewManager.invalidateDecorationsAllViews();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
    // (IModelApp as any).listCallback(ListEnum.TowerGeometry);
    // UiFramework.getIModelConnection()!.selectionSet.emptyAll();
    // const id = this.nameIdMap.get(_name)
    // if(id)UiFramework.getIModelConnection()!.selectionSet.add(id as string);
    return false;
  }
  /**
   * Update Cylinder Thickness
   * @param _name cylinder name
   * @param _change change value from Widget
   * @returns true or false depending on success or failure
   */
  public updateCylinderHeight(_name: string, _change: number): boolean {
    const jsonIndex = this.towerCylinders.findIndex((e) => e.name === _name);
    if (jsonIndex === -1) return false;
    const decorator = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
    const theCyl = decorator.towerCylinders[jsonIndex];
    decorator.towerCylinders.splice(jsonIndex, 1);
    const allPipes=decorator.towerCylinders;
    if(theCyl){
        const fstPt = theCyl.startPos.interpolate(-_change/10, theCyl.endPos);

        decorator.terminate();
        decorator.towerCylinders=allPipes;
        decorator.createCylinder(theCyl.endPos, fstPt, theCyl.baseWid, theCyl.topWid, theCyl.color, theCyl.name);
    }
    TowerStructureDecorator.displayedPropertyWindow = true;
    // this.displayPropertyWindow();
    IModelApp.viewManager.invalidateDecorationsAllViews();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
    // (IModelApp as any).listCallback(ListEnum.TowerGeometry);
    //Disabling the selection sustaining on update, as it triggers onSelectionChange event
    // UiFramework.getIModelConnection()!.selectionSet.emptyAll();
    // const id = this.nameIdMap.get(_name)
    // if(id)UiFramework.getIModelConnection()!.selectionSet.add(id as string);
    return false;
  }


  /**
   * update microwave position
   * @param _name name of Microwave
   * @param _changePosVector change vector
   * @returns success if true
   */
   public updateCylinderPosition(_name: string, _changePosVector: Vector3d, selection: string): boolean {
    const jsonIndex = this.towerCylinders.findIndex((e) => e.name === _name);
    if (jsonIndex === -1) return false;


    const decorator = this;
    const theCyl = decorator.towerCylinders[jsonIndex];
    decorator.towerCylinders.splice(jsonIndex, 1);
    const allCylinders=decorator.towerCylinders;
    if(theCyl){
        if(selection === "Whole"){
            theCyl.startPos.x+=_changePosVector.x;
            theCyl.startPos.y+=_changePosVector.y;
            theCyl.startPos.z+=_changePosVector.z;
            theCyl.endPos.x+=_changePosVector.x;
            theCyl.endPos.y+=_changePosVector.y;
            theCyl.endPos.z+=_changePosVector.z;
        } else if(selection === "Start"){
            theCyl.startPos.x+=_changePosVector.x;
            theCyl.startPos.y+=_changePosVector.y;
            theCyl.startPos.z+=_changePosVector.z;
        } else if(selection === "End"){
            theCyl.endPos.x+=_changePosVector.x;
            theCyl.endPos.y+=_changePosVector.y;
            theCyl.endPos.z+=_changePosVector.z;
        }
        decorator.terminate();
        decorator.towerCylinders=allCylinders;
        decorator.createCylinder(theCyl.endPos, theCyl.startPos, theCyl.baseWid, theCyl.topWid, theCyl.color, theCyl.name, theCyl);
    }
    TowerStructureDecorator.displayedPropertyWindow = true;
    // this.displayPropertyWindow();

    IModelApp.viewManager.invalidateDecorationsAllViews();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
    // (IModelApp as any).listCallback(ListEnum.TowerGeometry);
    // Disabling the selection sustaining on update, as it triggers onSelectionChange event
    // UiFramework.getIModelConnection()!.selectionSet.emptyAll();
    // const id = this.nameIdMap.get(_name)
    // if(id)UiFramework.getIModelConnection()!.selectionSet.add(id as string);
    return false;
  }


    public createGraphics(context: DecorateContext): RenderGraphic | undefined {
        // Get next available Id to represent decoration for its life span.
        this.towerCylinders.forEach((styledGeometry: TowerGeometry) => {
          if (!styledGeometry.transientId && !styledGeometry.name.match(/segment-pole|fall zone/)) {
            styledGeometry.transientId = context.viewport.iModel.transientIds.getNext();
          }

          let builder;
          if(this.hideMarkers && styledGeometry.name == this.selectedPart){
            builder = context.createGraphicBuilder(GraphicType.Scene, Transform.identity, styledGeometry.transientId);
          } else if(!this.hideMarkers && !styledGeometry.name.match(/segment-pole|Fall zone/)){
             builder = context.createGraphicBuilder(GraphicType.Scene, Transform.identity, styledGeometry.transientId);
           } else {
            builder = context.createGraphicBuilder(GraphicType.Scene, Transform.identity);
          }
          // builder.wantNormals = true;
          builder.setBlankingFill(styledGeometry.fillColor);
          // builder.setSymbology(styledGeometry.color, styledGeometry.fillColor, styledGeometry.lineThickness, styledGeometry.linePixels);
          this.createGraphicsForGeometry(styledGeometry.geometry!, styledGeometry.edges, builder);

          const overrides: ViewFlagOverrides = {};
          // overrides.ShowVisibleEdges = false;
          // overrides.setApplyLighting(true);
          const branch = new GraphicBranch(false);
          branch.setViewFlagOverrides(overrides);
          branch.add(builder.finish());
          const graphic = context.createBranch(branch, Transform.identity);
          context.addDecoration(GraphicType.Scene, graphic);
        });

        return undefined;
      }

      private createGraphicsForGeometry(geometry: GeometryQuery, wantEdges: boolean, builder: GraphicBuilder) {
        if (geometry instanceof LineString3d) {
          builder.addLineString(geometry.points);
        } else if (geometry instanceof Loop) {
          builder.addLoop(geometry);
          if (wantEdges) {
            // Since decorators don't natively support visual edges,
            // We draw them manually as lines along each loop edge/arc
            // builder.setSymbology(ColorDef.black, ColorDef.black, 2);
            const curves = geometry.children;
            curves.forEach((value) => {
              if (value instanceof LineString3d) {
                let edges = value.points;
                const endPoint = value.pointAt(0);
                if (endPoint) {
                  edges = edges.concat([endPoint]);
                }
                builder.addLineString(edges);
              } else if (value instanceof Arc3d) {
                builder.addArc(value, false, false);
              }
            });
          }
        } else if (geometry instanceof Path) {
          builder.addPath(geometry);
        } else if (geometry instanceof IndexedPolyface) {
          builder.addPolyface(geometry, true);
          if (wantEdges) {
            // Since decorators don't natively support visual edges,
            // We draw them manually as lines along each facet edge
            builder.setSymbology(ColorDef.black, ColorDef.black, 2);
            const visitor = IndexedPolyfaceVisitor.create(geometry, 1);
            let flag = true;
            while (flag) {
              const numIndices = visitor.pointCount;
              for (let i = 0; i < numIndices - 1; i++) {
                const point1 = visitor.getPoint(i);
                const point2 = visitor.getPoint(i + 1);
                if (point1 && point2) {
                  builder.addLineString([point1, point2]);
                }
              }
              flag = visitor.moveToNextFacet();
            }
          }
        } else if (geometry instanceof LineSegment3d) {
          const pointA = geometry.point0Ref;
          const pointB = geometry.point1Ref;
          const lineString = [pointA, pointB];
          builder.addLineString(lineString);
        } else if (geometry instanceof Arc3d) {
          builder.addArc(geometry, false, false);
        } else if (geometry instanceof CurveChainWithDistanceIndex) {
          this.createGraphicsForGeometry(geometry.path, wantEdges, builder);
        }
      }

      public async onDecorationButtonEvent(hit: HitDetail, _ev: BeButtonEvent): Promise<EventHandled> {
        const sourceId = hit.sourceId;
        this.displayPropertyWindow();
        const found = this.towerCylinders.filter(e=>e.transientId==sourceId);
        this.selectedPart=found.length ? found[0].name: "";
        TowerStructureDecorator.selectedSection.sectionName = this.selectedPart;
        TowerStructureDecorator.selectedSection.theSection = found[0];

        if (_ev.isDoubleClick && this.selectedPart.includes("Marker")){
          this.hideMarkers=true;
          // this.backupCylinders=this.towerCylinders;
          const towData = this.getTowerGeometry;
          this.terminate()
          this.loadShapes(towData);
          IModelApp.viewManager.invalidateDecorationsAllViews();
          IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
  
          IModelApp.tools.run(PlaceTowerMarkerTool.toolId, this.selectedPart);    
        } else {
          this.hideMarkers=false;
        }
        UiFramework.getIModelConnection()!.selectionSet.emptyAll();
        UiFramework.getIModelConnection()!.selectionSet.add(sourceId);
        return EventHandled.No;
      }
      /**
       * display property window
       */
      private displayPropertyWindow(hideWindow: boolean = false) {
        // SampleToolWidget.selectedList = ListEnum.TowerGeometry;
        const isBlankConnection: boolean | undefined = UiFramework.getIModelConnection()?.isBlank;
        if (!hideWindow) {
          if (isBlankConnection) {
            SyncUiEventDispatcher.dispatchSyncUiEvent("tower-selected");
            // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
          } else if ((IModelApp as any).listCallback) {
            // (IModelApp as any).listCallback(ListEnum.TowerGeometry);
          // } else if (SampleToolWidget.currentList && (IModelApp as any).listCallback) {
          //   (IModelApp as any).listCallback(SampleToolWidget.currentList);
          }
          // SampleToolWidget.selectedList = ListEnum.TowerGeometry;
        } else {
          // if (isBlankConnection) {
            // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
          // } else {
            // (IModelApp as any).listCallback(ListEnum.TowerInfo);
          // }
        }

        IModelApp.tools.run(SelectionTool.toolId);
      }

      public testDecorationHit(_id: string): boolean {
        this.displayPropertyWindow();
        const found = this.towerCylinders.filter(e=>e.transientId==_id);
        this.selectedPart=found.length ? found[0].name: "";
        TowerStructureDecorator.selectedSection.sectionName = this.selectedPart;
        TowerStructureDecorator.selectedSection.theSection = found[0];
        return Array.from(this.nameIdMap.values()).includes(_id);
      }
}
