import { VersionCompare } from "@bentley/version-compare";
import { AccessToken } from "@itwin/core-bentley";
import { IModelApp, ScreenViewport } from "@itwin/core-frontend";
import { store } from "../../../store/rootReducer";

export const VersionCompareInitialize = ()=>{
    const options  = {
      

        avoidPresentation: true,
        wantTypeOfChange: true,
        wantPropertyFiltering: true,
        wantReportGeneration: true,
        wantTooltipAugment: true,
        wantOverviewComparison: true,
        wantSearch: true,
        wantSavedFilters: false,
        wantPagedChangesetStatusLoading:true,
        wantChangesetPagination: true,
        wantPublicChangedElementsApi: true,
        userId: "", // Necessary for storing saved filters // Necessary for storing saved filters
        simpleVisualizationOptions:{ getPrimaryViewport: () => {
          
          return IModelApp.viewManager.selectedView as ScreenViewport;
        }},
        // getPublicAccessToken : async() => {
        //   return store.getState().auth.accessTokenStatePrivateAPI.accessToken as AccessToken;
        // },
        getPublicAccessToken: async() => {
          return  store.getState().auth.accessTokenState.accessToken as AccessToken;
        },
      };
      
      
      // Initialize the version compare package
      VersionCompare.initialize(options);
}