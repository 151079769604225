import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { ConfigManager } from "../../config/ConfigManager";
import { AccessToken } from "@itwin/core-bentley";
import { store } from "../../store/rootReducer";
import { RootState } from "../../store/States";


let privateAccessToken: AccessToken;
const listener = () => {
    setProjectIdUrl(store.getState());
}

function setProjectIdUrl(state: RootState) {
    privateAccessToken = state.auth.accessTokenStatePrivateAPI.accessToken!;
}

store.subscribe(listener);

export default class PlanningAnnotationClient {
    constructor() { }

    public static async postInventory(token: string = privateAccessToken, tagData: any): Promise<any> {
        if (!tagData) {
            return;
        }
        const projectId = ConfigManager.projectId;
        const url = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Inventory`;
        return fetch(url, {
            method: "post",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(tagData),
        }).then(async (res: Response) => {
            if (!res.ok) {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Saving Failed , Please Retry", "", OutputMessageType.Toast))
                return null;
            }
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, "Tag Saved", "", OutputMessageType.Toast))
            return "success";
        })
    }

    public static async postDefect(token: string = privateAccessToken, tagData: any): Promise<any> {
        if (!tagData) {
            return;
        }
        const projectId = ConfigManager.projectId;
        const url = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/InsightDefect`;
        return fetch(url, {
            method: "post",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(tagData),
        }).then(async (res: Response) => {
            if (!res.ok) {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Saving Failed , Please Retry", "", OutputMessageType.Toast))
                return null;
            }
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, "Tag Saved", "", OutputMessageType.Toast))
            return "success";
        })
    }

    public static async putInventory(token: string= privateAccessToken, tagData: any): Promise<any> {
        if (!tagData || !tagData.inventoryId) {
            return;
        }
        const projectId = ConfigManager.projectId;
        const url = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Inventory/${tagData.inventoryId}`;

        return fetch(url, {
            method: "put",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(tagData),
        }).then(async (res: Response) => {
            if (!res.ok) {
                return null;
            }
            return "success";
        }).then((data: any) => {
            return data;
        });
    }
    public static async putDefect(token: string = privateAccessToken, tagData: any): Promise<any> {
        if (!tagData || !tagData.defectId) {
            return;
        }
        const projectId = ConfigManager.projectId;
        const url = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/InsightDefect`;

        return fetch(url, {
            method: "put",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(tagData),
        }).then(async (res: Response) => {
            if (!res.ok) {
                return null;
            }
            return "success";
        }).then((data: any) => {
            return data;
        });
    }
    public static async deleteInventory(token: string = privateAccessToken, id: string): Promise<any> {
        if (!id) {
            return;
        }
        const projectId = ConfigManager.projectId;
        const url = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Inventory/${id}`;

        return fetch(url, {
            method: "delete",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        }).then(async (res: Response) => {
            if (!res.ok) {
                return null;
            }
            return "success";
        }).then((data: any) => {
            return data;
        });
    }
    public static async deleteDefect(token: string = privateAccessToken,id:string): Promise<any> {
        if (!id) {
            return;
        }
        const projectId = ConfigManager.projectId;
        const url = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Defect/${id}`;

        return fetch(url, {
            method: "delete",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        }).then(async (res: Response) => {
            if (!res.ok) {
                return null;
            }
            return "success";
        }).then((data: any) => {
            return data;
        });
    }
  
    public static async getDefectInventories(token: string = privateAccessToken): Promise<any> {
        const projectId = ConfigManager.projectId;
        const url: string = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/DefectsInventory`;
        return fetch(url, {
            method: "get",
            headers: {
                Authorization: token,
            },
        }).then(async (response: Response) => {
            if (!response.ok) {
                return null;
            }
            return response.json();
        }).then((data: any) => {
            return data;
        });
    }

}
