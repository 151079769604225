import axios from "axios"
import { store } from "../../../store/rootReducer"
import { ConfigManager } from "../../../config/ConfigManager"
import { Logger } from "../../../logging/logging"
import { uploadBlob } from "../../api/upload-blob-api"
import { RootState } from "../../../store/States"

export const getEnvironmentPrefix = () => {
    let prefix = ""
    if(ConfigManager.regionCode == 103)prefix="dev-"
    else if(ConfigManager.regionCode == 102)prefix="qa-"
    return prefix
}
export const getAccessToken = (state: RootState) => {
               
    return state.auth.accessTokenState.accessToken;
  }
  export const getParentFolderData = async (projectId) => {
    try{
        let token :any=  getAccessToken(store.getState())?.toString() ;
        
        let baseUrl = new URL(`https://${getEnvironmentPrefix()}api.bentley.com/storage/?projectId=${projectId}`);

        const subFoldersFo: any = await axios.get(baseUrl.href,{
            headers:{
                Authorization:token,
                Accept:" application/vnd.bentley.itwin-platform.v1+json  "     
                     } 

        });
    if(subFoldersFo.status == 200) return (subFoldersFo.data)
    }catch(e){
    Logger.error(`Error while calling https://${getEnvironmentPrefix()}api.bentley.com/storage/?projectId=${projectId}`,e)
    }
}
  export const getFolderData = async (parentFolderId) => {
    try{
        let token :any=  getAccessToken(store.getState())?.toString() ;
        
        let baseUrl = new URL(`https://${getEnvironmentPrefix()}api.bentley.com/storage/folders/${parentFolderId}/list?$skip=0`);

        const subFoldersFo: any = await axios.get(baseUrl.href,{
            headers:{
                Authorization:token,
                Accept:" application/vnd.bentley.itwin-platform.v1+json  "     
                     } 

        });
    if(subFoldersFo.status == 200) return (subFoldersFo.data)
    }catch(e){
    Logger.error(`Error while calling https://${getEnvironmentPrefix()}api.bentley.com/storage/folders/${parentFolderId}/folders[?$top][&$skip]`,e)
    }
}

const checkParentFolderStatus = async(Id,name)=>{
    const subFoldersFo: any = await getParentFolderData(Id);
    let imWsgid: string = "";
    if (subFoldersFo && subFoldersFo.hasOwnProperty("items") ) {
        for (let it = 0; it < subFoldersFo.items.length; it++) {
            if (subFoldersFo.items[it].displayName == name) {
                imWsgid = subFoldersFo.items[it].id;
            }
        }
        return imWsgid
    }
    return 
}

const checkImageInPlanningImageFolder = async(folderId) => {
    try{
        let token :any=  getAccessToken(store.getState())?.toString() ;
        
        let baseUrl = new URL(`https://${getEnvironmentPrefix()}api.bentley.com/storage/folders/${folderId}/files?$skip=0`);

        const subFoldersFo: any = await axios.get(baseUrl.href,{
            headers:{
                Authorization:token,
                Accept:" application/vnd.bentley.itwin-platform.v1+json  "     
                     } 

        });
    if(subFoldersFo.status == 200) return (subFoldersFo.data)
    }catch(e){
    Logger.error(`Error while calling https://${getEnvironmentPrefix()}api.bentley.com/storage/folders/${folderId}/files?$skip=0`,e)
    }
}

export const downloadingImage = async(wsgId : string) =>{
    try{

        let token :any = store.getState().auth.accessTokenState.accessToken;//Project assess token
        var myHeaders = new Headers();
        myHeaders.append("Authorization",token);
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: undefined
        };
        let img;
       let data = await fetch(`https://${getEnvironmentPrefix()}api.bentley.com/storage/files/${wsgId}/download`, requestOptions).then(res=>{
         if(res.status ==200) img = res.url
    })
       
        return img;
    }catch(e){
        Logger.error("Error while downloading image",e)
        return null;
    }
}
export const deleteImageFileInDocs = async(fileId : string) => {
    try{
        let token :any=  getAccessToken(store.getState())?.toString() ;
        
        let baseUrl = new URL(`https://${getEnvironmentPrefix()}api.bentley.com/storage/files/${fileId}`);

        const subFoldersFo: any = await axios.delete(baseUrl.href,{
            headers:{
                Authorization:token,
                Accept:" application/vnd.bentley.itwin-platform.v1+json  "     
                     } 

        });
    if(subFoldersFo.status == 200) return (subFoldersFo)
    }catch(e){
    Logger.error(`Error while calling https://${getEnvironmentPrefix()}api.bentley.com/storage/files/${fileId}`,e)
    }

}

export const existingPhoto = async() => {
    let checkImageFolder = await checkParentFolderStatus(ConfigManager.projectId!,"Images")
    let listUserPlanningImageFolder = await getFolderData(checkImageFolder);
    let folderId = ""
    if (listUserPlanningImageFolder && listUserPlanningImageFolder.hasOwnProperty("items") ) {
        for (let it = 0; it < listUserPlanningImageFolder.items.length; it++) {
            if (listUserPlanningImageFolder.items[it].displayName == "user-planning-image") {
                folderId = listUserPlanningImageFolder.items[it].id;
            }
        }
  
    }
    if(folderId !== ""){
        let AllImages = await checkImageInPlanningImageFolder(folderId)
        let img:any = []
        if(AllImages && AllImages.hasOwnProperty("files")){
        for (let i=0;i<AllImages.files.length;i++){
            let temp = await downloadingImage(AllImages.files[i].id)
          if(temp)  img.push({url:temp,id:i,data:AllImages.files[i]})
        }
       return img&&img.length>0?img: [{url:"",id:0,data:null}]
    }else return [{url:"",id:0,data:null}]
    }
    return [{url:"",id:0,data:null}]
}

export const addDragDroppedFilesToProjectShareList = async (folderName: string, dragDroppedFiles: any) => {

    if (dragDroppedFiles ) {
      
       let checkImageFolder = await checkParentFolderStatus(ConfigManager.projectId!,"Images")
       let listUserPlanningImageFolder = await getFolderData(checkImageFolder)

        let pf:any = {};
        pf.name = folderName;
        let token :any=  getAccessToken(store.getState())?.toString() ;
        let header = {
            Authorization: token,
            "Content-Type": "application/json",
            Accept: "application/vnd.bentley.itwin-platform.v1+json"
        }
        let folderId = "";
        if (listUserPlanningImageFolder && listUserPlanningImageFolder.hasOwnProperty("items") ) {
            for (let it = 0; it < listUserPlanningImageFolder.items.length; it++) {
                if (listUserPlanningImageFolder.items[it].displayName == "user-planning-image") {
                    folderId = listUserPlanningImageFolder.items[it].id;
                }
            }
      
        }
        if(folderId === ""){

             let data  = await axios.post(`https://${getEnvironmentPrefix()}api.bentley.com/storage/folders/${checkImageFolder}/folders`, JSON.stringify({
                "displayName": "user-planning-image",
    
            }),{
                headers:header
            });
            if(data.status == 201)
            folderId = data.data.folder.id;
        }

            
                let pff: any = {};
                pff.name = dragDroppedFiles.name;
                pff.contentType = dragDroppedFiles.type;
                pff.size = dragDroppedFiles.size;
                let createFile : any =  await axios.post(`https://${getEnvironmentPrefix()}api.bentley.com/storage/folders/${folderId}/files`, JSON.stringify({
                    "displayName":  pff.name,
                    "description":  ""
                }),{
                    headers:header
                });
                if(createFile && createFile.status == 202){
                    const res = await uploadBlob(createFile.data._links.uploadUrl.href, dragDroppedFiles);

                    if(res != undefined){
                        const body: any = {}
                       let res =  await axios.post(createFile.data._links.completeUrl.href,body,{
                            headers:{
                                    Authorization:token,     
                                    "Content-Type": "application/json",
                                    Accept: "application/vnd.bentley.itwin-platform.v1+json"                                        
                            }
                        });
                    if(res && res.status == 200){
                               return res.data;
                  
                    }

                    } 
                    
                }
       
        }
    
    return;
}