import { UiFramework } from "@itwin/appui-react";
import React,{useState,useEffect} from "react";
import AddMountElevationPopup from "./tools/AddMountElevationPopup";
import { RootState } from "../../store/States";
import { connect } from "react-redux";
import { PinPropertyPopup } from "./Lists/PinPropertyPopup";
import { ObjectCategoryTypes } from "../../store/detectedData/apiDataTypes";
import DeleteMount from "./delete-mount/delete-mount";
import ConfirmationModal, { ConfirmationMode } from "./ConfirmationModal";
import { AnnotationDecorator } from "../tools/decorators/AnnotationsDecorator";
import { ShapeDecorator } from "../tools/decorators/ShapeDecorator";


const ShowDeletePopup = (props) =>{
    const [item,setItem] = useState(<></>)
useEffect(()=>{
    let temp: any;
if(props.selectedObject && (props.selectedObject.objectProperties)){
    switch(props.selectedObject.objectProperties.objectCategoryType){
        case ObjectCategoryTypes.Mount_DM:{
         temp = <DeleteMount imodel = {UiFramework.getIModelConnection()}/>
         break;
        }
        case ObjectCategoryTypes.MountMember_DM:{
         temp = <DeleteMount imodel = {UiFramework.getIModelConnection()}/>
         break;
        }
        case ObjectCategoryTypes.Equipment_RM:{
            temp = <ConfirmationModal selectedObject={props.selectedObject} title={ShapeDecorator.selectedEquipment.displayName!} message={""}/>
         break;
        }
        
        case ObjectCategoryTypes.Mount_RM:{
            const theMsg = <>
            <p>This action will trigger a background process which will takes several minutes to complete.<br/></p>
            <p>You will lose mount information until the process is completed.<br/><br/></p>
            <p>Do you want to continue?</p>
            </>
           
           
         temp =  <ConfirmationModal selectedObject={props.selectedObject} title={`Identify Models`} message={theMsg} mode={ConfirmationMode.Concent}/>
         break;
        }
        default:temp=<></>
      }
      switch(props.selectedObject.heading){
        case "Grating Information":{
            temp = <ConfirmationModal selectedObject={props.selectedObject} title={`Grating ${props.selectedObject.objectProperties.selectedObjectNST.name.split('@')[0].split('_').slice(1).join(' ')}`} message={""}/>
            break;
           }
        case "Ladder Information":{
            temp = <ConfirmationModal selectedObject={props.selectedObject} title={`Selected ladder`} message={""}/>
            break;
           }
        case "Feedline Information":{
            temp = <ConfirmationModal selectedObject={props.selectedObject} title={`Feedline ${props.selectedObject.objectProperties.selectedObjectNST.name}`} message={""}/>
            break;
           }
        case  "Defect Information":{
             const {criticality, defectiveStructure} = AnnotationDecorator.selectedMarkerJson;
            temp =  <ConfirmationModal selectedObject={props.selectedObject} title={`${criticality} Defect in ${defectiveStructure}`} message={""}/>
            break;
           }
      
          
      }
      setItem(temp)
}
 

},[])
    return(<>
     {item}
    </>)
}
const mapStateToProps = (state: RootState) => {
  const retState = {
    isEditActive: state.dtvState.applicationState.isEditModeActive,
    selectedObject: state.detectedData.selectedObjectInformation,
    showPinTags: state.dtvState.applicationState.showPinTags,

  }
  return retState;
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ShowDeletePopup)
