import { UiFramework } from '@itwin/appui-react'
import React, { useEffect, useState } from 'react'
import { RootState } from '../../store/States'
import { mountingsData, objectProperties } from '../../store/detectedData/apiDataTypes'
import { connect } from 'react-redux'
import { PropertyEditorComponent, pecComponentProps, pecDataValueTypes, pecGridStructure, pecInputTagTypes, pecOptionData, pecStrucType, pecTableButton } from '../components/PropertyEditorComponent'
import { store } from '../../store/rootReducer'
import axios from 'axios'
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from '@itwin/core-frontend'
import { setMountingsData } from '../../store/detectedData/apiDataActionTypes'
import { Id64String } from '@itwin/core-bentley'
import { MountDecorator } from './decorators/MountDecorator'
import { ReduceDecimalNumberToTwoPlaces } from '../components/Lists/EquipmentList'
import { ConfigManager } from '../../config/ConfigManager'
import EquipmentClient from '../api/equipment'
import { resetObjectIds } from '../components/HorizontalToolbarItems'
import { SvgAdd, SvgInfoCircular, SvgInfoCircularHollow } from '@itwin/itwinui-icons-react'
import ConfirmationModal, { ConfirmationMode } from '../components/ConfirmationModal'
import { sanitizeUrl } from '../../config/UrlManager'


const MountEditPropertyHandler = (props) => {
    const [mountGridData, setMountGridData] = useState<pecGridStructure>(props.equipmentMenuStruc);
    const [manModelData, setManModelData] = useState(props.manModelData);
    const [mountsData, setMountsData] = useState<any>(props.mountsData);
    const [updatedManModel, setUpdManModel] = useState<any>({manufacturer: mountGridData.rows[1].columns[1].value, model: mountGridData.rows[2].columns[1].value});
    const [matchError, setMatchError] = useState<any>(0);
    const [saveButton, setSaveButton] = useState<{disabled: boolean, text: string}>({disabled: true, text: "Save"});
    const [modified, setModified] = useState<boolean|undefined>(false)
    const [item,setItem] = useState(<></>)
    useEffect(() => {
        setMountGridData(getFieldStructure(props.selObjInfo, props, store.getState()).equipmentMenuStruc);
    }, [props.selObjInfo.selectedObjectNST.name])

    const saveData = async () => {
        let sector: string =  mountGridData.rows[0].columns[1].value as string;
        let manufacturer: any =  mountGridData.rows[1].columns[1].value;
        let model: any = mountGridData.rows[2].columns[1].value;
        let score:any = matchError;//mountGridData.rows[2].columns[1].value;
        let mountName = props.selObjInfo.selectedObjectNST.name.split('@')[3];//store.getState().detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name.split('@')[3]
        
        const projectId = ConfigManager.projectId!;
        const openToweriQUrl = ConfigManager.openToweriQUrl!;
        const privateAccessToken = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
        let url = "";
        if((mountGridData.rows[1].columns[1].modified || mountGridData.rows[2].columns[1].modified)){
          if(mountGridData.rows[1].columns[1].value == "Select Manufacturer" && mountGridData.rows[2].columns[1].value == "Select Model"){
            manufacturer=null;
            model=null;
          }          
          url = `${openToweriQUrl}/v1.1/${projectId}/MountingsData/Model`;
          let copyMountData = JSON.parse(JSON.stringify(mountsData));
          copyMountData.mounts[mountName].manufacturer = manufacturer;
          copyMountData.mounts[mountName].model = model;
          let data = [{
              mountId: mountName,
              model,
              manufacturer,
  
          }]
          try{
              let res = await axios.put(sanitizeUrl(url), JSON.stringify(data), {
                  headers: {
                      Authorization:privateAccessToken,
                      "Content-Type": "application/json",
                  },
              })
              if(res && res.status == 200){
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, `Mount model information saved successfully.`, "", OutputMessageType.Toast));
                store.dispatch(setMountingsData(JSON.parse(JSON.stringify(copyMountData))));
                setModified(false);
                const mountDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
                const selMount = mountDec.selectedMount;
                if(selMount?.name.includes('temp'))return null;   //Returning null if the pipe is temperory and not yet added to the database with the properties required.   
                const selParMount = MountDecorator.mountData.filter((e) => e[0] === selMount.modelData.parentMount)[0];
                selParMount[1].manufacturer = manufacturer;
                selParMount[1].model = model;
                const imc = UiFramework.getIModelConnection();
                imc?.selectionSet.emptyAll();
                props.selObjInfo.objectIds.forEach(e=>{
                    imc?.selectionSet.add(e as Id64String);
                })
              }
          } catch(e) {
              IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, `Something Went Wrong...`, "", OutputMessageType.Toast));
          }
        }
        if(mountGridData.rows[0].columns[1].modified && sector != "Select Sector"){
          const mountDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
          const selMountData = mountDec.selectedMount.modelData;
          url = `${openToweriQUrl}/v1.2/${projectId}/MountingsData/Mount/${mountName}?recalculateMounts=true`;
          const data = {
            "facesInfo": [
              {
                "faceId": selMountData.mountFace,
                "sector": sector,
                "userDrivenSector": true
              }
            ]
          }
          let res = await axios.put(sanitizeUrl(url), JSON.stringify(data), {
              headers: {
                  Authorization:privateAccessToken,
                  "Content-Type": "application/json",
              },
          })
          if(res && res.status == 200){
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, `Mount Sector information saved successfully.`, "", OutputMessageType.Toast));
            const newMountData = await EquipmentClient.getMountingsDataJson(privateAccessToken);
            store.dispatch(setMountingsData(JSON.parse(JSON.stringify(newMountData))));
            setModified(false);
            const mountDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
            mountDec.clearAndReload(newMountData);
            resetObjectIds(mountDec);
            const selMount = mountDec.selectedMount;
            if(selMount?.name.includes('temp'))return null;   //Returning null if the pipe is temperory and not yet added to the database with the properties required.   
            const selParMount = MountDecorator.mountData.filter((e) => e[0] === selMount.modelData.parentMount)[0];
            if(manufacturer != "Select Manufacturer")selParMount[1].manufacturer = manufacturer;
            if(model != "Select Model")selParMount[1].model = model;
            const imc = UiFramework.getIModelConnection();
            imc?.selectionSet.emptyAll();
            // props.selObjInfo.objectIds.forEach(e=>{
            //     imc?.selectionSet.add(e as Id64String);
            // })
            mountDec.selectedMount = mountDec.pipes.find(p=>(p.modelData.name == selMount.modelData.name && p.modelData.parentMount == selMount.modelData.parentMount && p.modelData.orientation == selMount.modelData.orientation && p.modelData.parentMountType == selMount.modelData.parentMountType));
            mountDec.pipes.forEach((pipe) => {
              const modelData = pipe.modelData;
              if (modelData && (modelData?.mountFace === selMount.modelData.mountFace || modelData.parentMountType.includes("Platform")) && modelData.parentMount === selMount.modelData.parentMount)imc!.selectionSet.add(pipe.transientId);
            });
            return true;
          }
        }
        return true;
    }

    const swapValueInArray = (data,originalValues,index) => {
        let temp ;
        let copyData = [...data];
        temp = copyData[0]
        copyData[0] = originalValues;
        copyData[index] = temp;
        return copyData;
    }
        
    const onModalBtnClick = async (event: any) => {
      const id = event.target.id;
      switch (id) {
        case "Save#1": {
          setSaveButton({disabled: true, text: "Saving..."});
          await saveData()
          props.onClose();
          break;
        }
        case "Cancel#2": {
          const temp: pecGridStructure = {...mountGridData};
          temp.rows[0].columns[1].value = updatedManModel.man;
          temp.rows[1].columns[1].value = updatedManModel.model;
          setManModelData(temp)
          setModified(false);
          props.onClose();
          break;
        }
      }
    }
          
    const onFieldUpdate = (event) => {
      let currentEvent= event;
    }
          
    const onFieldChange = async (evt) => {
        evt.persist();
        setModified(false);
        const temp: pecGridStructure = JSON.parse(JSON.stringify(mountGridData));
        const id = evt.target.id;
        const idSplit = id.split("-");
        const {name: theName, value: theValue} = evt.target;
        const [inputType, rowIndex, columnIndex] = id.split("@")[0].split('-');
        // setPrevManModel({man: temp.rows[0].columns[1].value as string, model: temp.rows[1].columns[1].value as string});
      
        switch (inputType) {
          case pecInputTagTypes.SELECT:
            temp.rows[rowIndex].columns[columnIndex].value = theValue;
            switch (theName) {
              case "manufacturer": {
                const theModels: any[] = manModelData.filter(e=>e.man.toUpperCase()==theValue.toUpperCase())[0].models;
                if(theModels[0].name != "Select Model")theModels.unshift({name: "Select Model", matchError: 0});
                if(theValue == "Select Manufacturer"){
                  temp.rows[2].columns[1].data = [{label: "Select Model", value: "Select Model"}];
                  temp.rows[2].columns[1].value = "Select Model";
                  temp.rows[2].columns[1].disabled = true;
                  temp.rows[3].columns[1].value = `-`;
                  temp.rows[1].columns[1].modified = true;
                  temp.rows[2].columns[1].modified = true;
                } else {                  
                  temp.rows[2].columns[1].disabled = false;
                  temp.rows[2].columns[1].value = theModels[1].name;
                  if(mountGridData.rows[1].columns[1].value != theValue)temp.rows[1].columns[1].modified = true;
                  if(mountGridData.rows[2].columns[1].value != theModels[1].name)temp.rows[2].columns[1].modified = true;
                  temp.rows[2].columns[1].data = theModels.map(e=>({label: e.name, value: e.name}));
                  temp.rows[3].columns[1].value = `${ReduceDecimalNumberToTwoPlaces((1-parseFloat(theModels[1].matchError))*100)}%`;
                  setMatchError(theModels[1].matchError);
                }
              }
              setSaveButton(saveButton=>({...saveButton, disabled: false}));
              // if(!temp.rows[0].columns[1].modified)setSaveButton(saveButton=>({...saveButton, disabled: true}));
              break;
              case "model": {
                const mmd = manModelData.filter(e=>e.man.toUpperCase() == (temp.rows[1].columns[1].value as string).toUpperCase());
                const mdls = mmd[0].models.filter(e=>e.name.toUpperCase() == (temp.rows[2].columns[1].value as string).toUpperCase());
                if(mdls.length && theValue != "Select Model"){
                  temp.rows[3].columns[1].value = `${ReduceDecimalNumberToTwoPlaces((1-parseFloat(mdls[0].matchError))*100)}%`;
                  if(mountGridData.rows[2].columns[1].value != theValue)temp.rows[2].columns[1].modified = true;
                } else {
                  temp.rows[3].columns[1].value = `-`;
                }
                setMatchError(temp.rows[3].columns[1].value);
                if(theValue != "Select Manufacturer")setSaveButton(saveButton=>({...saveButton, disabled: false}));
                else if(!temp.rows[0].columns[1].modified) setSaveButton(saveButton=>({...saveButton, disabled: true}));
              }
              break;
              case "sector": {
                temp.rows[0].columns[1].value = theValue;
                temp.rows[0].columns[1].modified = true;
                if(temp.rows[0].columns[1].value && theValue != "Select Sector")setSaveButton(saveButton=>({...saveButton, disabled: false}));
                else if(theValue == "Select Sector" && !temp.rows[1].columns[1].modified && !temp.rows[2].columns[1].modified)setSaveButton(saveButton=>({...saveButton, disabled: true}));
              }
              break;
              default:
                break;
            }
            break;        
          default:
            break;
        }
        
  
        setMountGridData(temp);
        // if(temp.rows[1].columns[1].modified || temp.rows[2].columns[1].modified){
        //   if((temp.rows[1].columns[1].value as string).toLowerCase() != updatedManModel.manufacturer.toLowerCase() || (temp.rows[2].columns[1].value as string).toLowerCase() != updatedManModel.model.toLowerCase()){
        //       // if(temp.rows[1].columns[1].value != "Select Manufacturer" && temp.rows[2].columns[1].value != "Select Model")setModified(true);
        //   }
        // }
    }
            
          
    const onFieldClick = async (event: any, _inputIds) => {
      // let currentEvent= event;
      // let inputId = inputIds
      const id = event.currentTarget.id.split('-')[0];
      switch (id) {
        case "refresh":{
          const execute = async () => {
            setSaveButton(saveButton=>({...saveButton, disabled: true}));
            const projectId = ConfigManager.projectId!;
            const openToweriQUrl = ConfigManager.openToweriQUrl!;
            const privateAccessToken = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
            let url = "";
            url = `${openToweriQUrl}/v1.1/${projectId}/MountingsData/Model`;
            const siblingMounts = props.siblingMounts;
            const fiArr: any[] = [];
            const resArr: any[] = [];
            await siblingMounts.forEach(async mount => {
              url = `${openToweriQUrl}/v1.2/${projectId}/MountingsData/Mount/${mount}?recalculateMounts=true`;
              const mountFace: any = mountsData.mounts[mount];
              Object.entries(mountFace.FACEs_info).forEach((face: any) => {
                if(face[1].UserDrivenSector){
                  fiArr.push(
                      {
                        faceId: face[0],
                        sector: face[1].Sector,
                        userDrivenSector: false
                      }
                  );
                  columns.push(<tr>
                    <th>{`Mount ${parseInt(mount.split('mount')[1])}`}</th>
                    <th>{face[0]}</th>    
                    <th>{face[1].Sector}</th>
                  </tr>)
                
                }
              });
              const data = {facesInfo: fiArr};
              let res = await axios.put(sanitizeUrl(url), JSON.stringify(data), {
                  headers: {
                      Authorization:privateAccessToken,
                      "Content-Type": "application/json",
                  },
              })
              if(res && res.status == 200)resArr.push(res);
              if(resArr.length == siblingMounts.length){
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, `Mount Sector information saved successfully.`, "", OutputMessageType.Toast));
                const newMountData = await EquipmentClient.getMountingsDataJson(privateAccessToken);
                store.dispatch(setMountingsData(JSON.parse(JSON.stringify(newMountData))));
                setModified(false);
                const mountDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
                mountDec.clearAndReload(newMountData);
                resetObjectIds(mountDec);
                const selMount = mountDec.selectedMount;
                const imc = UiFramework.getIModelConnection();
                imc?.selectionSet.emptyAll();
                mountDec.selectedMount = mountDec.pipes.find(p=>(p.modelData.name == selMount.modelData.name && p.modelData.parentMount == selMount.modelData.parentMount && p.modelData.orientation == selMount.modelData.orientation && p.modelData.parentMountType == selMount.modelData.parentMountType));
                mountDec.pipes.forEach((pipe) => {
                  const modelData = pipe.modelData;
                  if (modelData && (modelData?.mountFace === selMount.modelData.mountFace || modelData.parentMountType.includes("Platform")) && modelData.parentMount === selMount.modelData.parentMount)imc!.selectionSet.add(pipe.transientId);
                });
                // return true;
              }
            });
            setItem(<></>)
          }
  
          let columns:any[]=[];
          props.siblingMounts.forEach(async mount => {
            const mountFace: any = mountsData.mounts[mount];
            Object.entries(mountFace.FACEs_info).forEach((face: any) => {
              if(face[1].UserDrivenSector){
                columns.push(<tr>
                  {/* <th scope="row">{columns.length+1}</th> */}
                  <td>{`Mount ${parseInt(mount.split('mount')[1])}`}</td>
                  <td>{`${face[0].substring(0, face[0].length-1)} ${face[0].substring([face[0].length-1])}`}</td>    
                  <td>{face[1].Sector}</td>
                </tr>)              
              }
            });
          });

            let theMessage = <>This will remove all the user defined sectors assigned to the mounts of this RAD Center. Following is the existing user defined sectors summary of this RAD Center.
            <table className='table table-striped mt-3'>
            <thead className='thead-light'>
              <tr>
                {/* <th scope="col">#</th> */}
                <th scope="col">Mount</th>
                <th scope="col">Face</th>    
                <th scope="col">Sector</th>{}
              </tr>
            </thead>
            <tbody>
              {columns.map(c=>c)}
            </tbody>
          </table>
          <div className='mt-4'>Do you want to continue?</div>
          </>;
  setItem(<ConfirmationModal selectedObject={store.getState().detectedData.selectedObjectInformation} callback={execute} title={`Reset User Defined Sectors`} message={theMessage} mode={ConfirmationMode.Execute}/>)
          // UiFramework.content.dialogs.open(<ConfirmationModal selectedObject={store.getState().detectedData.selectedObjectInformation} callback={execute} title={`Reset User Defined Sectors`} message={theMessage} mode={ConfirmationMode.Execute}/>, "Delete Confirmation")

          //   const data = {
          //     "facesInfo": [
          //       {
          //         "faceId": selMountData.mountFace,
          //         "sector": sector,
          //         "userDrivenSector": true
          //       }
          //     ]
          //   }
            
          // });
          // let res = await axios.put(url, JSON.stringify(data), {
          //     headers: {
          //         Authorization:privateAccessToken,
          //         "Content-Type": "application/json",
          //     },
          // })

          break;
        }
      
        default:
          break;
      }
    }
    

    const checkDisabled = () => {
      let check = false;
      if(mountGridData.rows[0].columns[1].value == "--Select--")check = true
      if(mountGridData.rows[1].columns[1].value == "--Select--")check = true

      return check;
    }
    
    
    let modalBtns: pecTableButton[] = [
      {name: saveButton.text, value: saveButton.text, class: "dialogBox-save-btn", disable: saveButton.disabled, onInteract: onModalBtnClick},
      {name: "Cancel", value: "Cancel", class: "dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick},
    ];
    
    
    let equipmentEditorStructure: pecComponentProps = {
      structureType: pecStrucType.STRUCTURE,
      title: {
        label: "Edit Properties",
      },
      grid: mountGridData,
      gridButtons: modalBtns,
      eventHandlers: {
        onChange: onFieldChange,
        onUpdate: onFieldUpdate,
        onClick: onFieldClick,
      }
    }
    return (
      <>
      <PropertyEditorComponent {...equipmentEditorStructure} />
      {item}
      </>
    )
}

const mapStateToProps = (state: RootState, props: any) => {

    const {equipmentMenuStruc, manModels: manModelData, siblingMounts} = getFieldStructure(state.detectedData.selectedObjectInformation.objectProperties, props, state);
    const retVal = {
      equipmentMenuStruc,
      mountsData: state.detectedData.mountingsData,
      selObjInfo: state.detectedData.selectedObjectInformation.objectProperties,
      manModelData,
      siblingMounts
    };
    return retVal;
  }
  
  const mapDispatchToProps = {}
  
  export default connect(mapStateToProps, mapDispatchToProps)(MountEditPropertyHandler);

  const getFieldStructure = (objProps: objectProperties, props: any, currentState: RootState): any => {
    let mountingsData: mountingsData = store.getState().detectedData.mountingsData;// await getMountingsDataJson();
    const radCenters: any = mountingsData.radCenters!;
    const selMountName = objProps.selectedObjectNST.name.split('@')[3];
    if(selMountName == undefined){props.onClose();return{}}
    
    const {manufacturer="", model=""} = mountingsData.mounts[selMountName];
    const detectedManModelMatches = mountingsData.mounts[selMountName].scored;
    const allOccupiedUserDrivenSectors: string[] = [];
    let siblingMounts = [];
    let userDefinedSector: boolean = false;
    if(mountingsData.radCenters != null){
      siblingMounts = radCenters.find(rc=>rc?.mounts.find(m=>m==selMountName)).mounts;
      
      siblingMounts.forEach(sm=>{
        const mount: any = mountingsData?.mounts[sm];
        const facesInfo = mount.FACEs_info;
        for(const face in facesInfo){
          const faceInfo: any = facesInfo[face];
          if(faceInfo.UserDrivenSector){
            allOccupiedUserDrivenSectors.push(faceInfo.Sector)
            userDefinedSector = true;
          }
        }
      });
    }
    const enableSectorReset = currentState.dtvState.featureControls.resetMountSectors && userDefinedSector;

    // if(detectedManModelMatches == null){props.onClose();return{}}
    const mountDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
    const selMount = mountDec.selectedMount;
    const sectorNames = ["Alpha", "Beta", "Gamma", "Delta"];
    // const allSectors: pecOptionData[] = [{label: "Select Sector", value: "Select Sector"}, {label: "Alpha", value: "Alpha"}, {label: "Beta", value: "Beta"}, {label: "Gamma", value: "Gamma"}, {label: "Delta", value: "Delta"}];
    const allSectors: pecOptionData[] = sectorNames.map(sm=>({label: sm, value: sm, disabled: allOccupiedUserDrivenSectors.findIndex(aouds=>aouds==sm)>-1}));

    const selSector: string = mountingsData.mounts[selMountName].type.match(/circular/ig) ? selMount.modelData.mountFace ? mountingsData.mounts[selMountName].FACEs_info[selMount.modelData.mountFace].Sector : undefined :  mountingsData.mounts[selMountName].FACEs_info[selMount.modelData.mountFace].Sector;

    let manModels: any[] = [{man: "Select Manufacturer", models: [{name: "Select Model", matchError: 0}]}];
    if(detectedManModelMatches?.length){
      detectedManModelMatches.forEach(e=>{
        if(!manModels.filter(f=>f.man==e.manufacturer).length)manModels.push({man: e.manufacturer, models: [{name:e.model, matchError: e.score}]});
        else manModels[manModels.findIndex(h=>h.man == e.manufacturer)].models.push({name: e.model, matchError: e.score});
      });
    }
    manModels.map(mm=>({man: mm.man, models: mm.models.sort(function cmp(a, b) {
        return a.matchError.toString().localeCompare(b.matchError.toString())})
    }))
    const manData: pecOptionData[] = manModels.map(e=>({label: e.man, value: e.man}));
    let modelData: any[] = [];
    if(manModels.length > 1 && manufacturer != null)modelData = manModels[manModels.findIndex(e=>e.man.toLowerCase() == manufacturer.toLowerCase())].models.map(e=>({label: e.name, value: e.name, matchError: e.matchError}));
    modelData.unshift({label: "Select Model", value: "Select Model"})
    const matchError = modelData.length > 1 ? modelData.find(e=>e.label == model).matchError : manModels[0].models[0].matchError;
    const equipmentMenuStruc: pecGridStructure = {
      rows: [
          {
            columns: [
              {
                size: 5,
                type: pecInputTagTypes.LABEL,
                customClass: "",
                value: "Sector",
                disabled: selSector == undefined,
                name: "sector",
                fieldType:pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: ""}
              },
              {
                size: enableSectorReset ? 3 : 5,
                type: pecInputTagTypes.SELECT,
                customClass: "",
                value: selSector ?? "",
                disabled: selSector == undefined,
                name: "sector",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: `dialogBox-MountEditPropertyHandler-dropdown${enableSectorReset ? "-reset-enabled" : ""}`, childClass: `custom-select-mount-sector${enableSectorReset ? "-reset-enabled" : ""}`},
                data: allSectors
              },       
              {
                size: 2,
                type: pecInputTagTypes.INFOICON,
                customClass: "",
                value: "",
                disabled: false,
                name: "sector",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: `dialogBox-MountEditPropertyHandler-infoButton${enableSectorReset ? "-reset-enabled" : ""}`, childClass: `mt-1 dialogBox-MountEditPropertyHandler-nohover dialogBox-MountEditPropertyHandler-borderless${enableSectorReset ? "-reset-enabled" : ""}`},
                data: selMount.modelData.parentMountType.match(/circular/ig) == null ? "Disabled list items are the sectors assigned by the user to other mount on same RAD center." : selMount.modelData.parentMountType.match(/circular/ig) != null && selSector == undefined ? "Only vertical pipes on circular mounts can have sector defined." : null
              },       
            ]        
          },
          {
            columns: [
              {
                size: 5,
                type: pecInputTagTypes.LABEL,
                customClass: "",
                value: "Manufacturer",
                disabled: false,
                name: "manufacturer",
                fieldType:pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: ""}
              },
              {
                size: 7,
                type: pecInputTagTypes.SELECT,
                customClass: "",
                value: manufacturer,
                disabled: !(manModels.length > 1),
                name: "manufacturer",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "", childClass: "dialogBox-MountEditPropertyHandler-dropdown"},
                data: manData
              },       
            ]        
          },
          {
            columns: [
              {
                size: 5,
                type: pecInputTagTypes.LABEL,
                customClass: "",
                value: "Model",
                disabled: false,
                name: "model",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: ""}
              },
              {
                size: 7,        
                type: pecInputTagTypes.SELECT,
                customClass: "",
                value: model,
                disabled: !(manModels.length > 1),
                name: "model",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "", childClass: "dialogBox-MountEditPropertyHandler-dropdown"},
                data: modelData
              },                   
            ]            
          },
        ]
      }
      if(enableSectorReset){
        equipmentMenuStruc.rows[0].columns.push(
        {
          size: 2,
          type: pecInputTagTypes.REFRESHICON,
          customClass: "",
          value: "",
          disabled: !userDefinedSector,
          name: "sector",
          fieldType: pecDataValueTypes.INTEGER,
          modified: false,
          styles: {parentClass: `dialogBox-MountEditPropertyHandler-refreshButton${enableSectorReset ? "-reset-enabled" : ""}`, childClass: `mt-1 dialogBox-MountEditPropertyHandler-borderless${enableSectorReset ? "-reset-enabled" : ""}`},
          data: "Reset user defined sectors."
        },       
      )
    }
    equipmentMenuStruc.rows.push(
      manModels.length > 1 ?{
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Detection Accuracy",
            disabled: false,
            name: "score",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: ""}
          },
          {
            size: 4,            
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: (manModels.length > 1) ? `${ReduceDecimalNumberToTwoPlaces((1-parseFloat(matchError))*100)}%` : '-',
            disabled: false,
            name: "score-display",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "", childClass: ""},
          },             
        ]        
      } :
      {
        columns: [
          {
            size: 12,
            type: pecInputTagTypes.NOTE,
            customClass: "",
            value: "Please use Identify Models option to detect Manufacturer and Model.",
            disabled: false,
            name: "score",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mt-2 mb-0 font-italic text-muted font-weight-light label-mount-footer", childClass: ""}
          },             
        ]        
      },

    )

    return {equipmentMenuStruc, manModels, siblingMounts};
  }

  