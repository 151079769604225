
import { SelectAntennaTool } from "./tools/SelectAntennaTool";
import "./FrontstageWidgets.scss";
import "./Lists/List.scss";

function select(state: RootState, dataKey: string) {
  return state.detectedData[dataKey];
}


import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Button } from "@itwin/itwinui-react";

import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import "./image-gallary/imageSlider.scss";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Logger } from "../api/logging";
import { RootState } from "../../store/States";
import { IndividualShapeDecorator } from "../tools/decorators/IndividualShapeDecorator";
import ModalDialog from "./TreeVisualizer/dialog/dialog";
import { DefectsDecorator } from "../tools/decorators/DefectsDecorator";
import { store } from "../../store/rootReducer";
import { ObjectCategoryTypes } from "../../store/detectedData/apiDataTypes";
import { ConfigManager } from "../../config/ConfigManager";
import { sanitizeUrl } from "../../config/UrlManager";

interface propTypes {
    inSiteImageUrl: string;
    title: string;
    btnTooltip?: string;
    disabled?: boolean;
    slides?: any[];
    isDefect? : boolean;
    defectType? : string;
    standsFor: string;
    message: string;
    selectedEquipment?: string;
}
const GetObjectImages = (props: propTypes) => {
    const { title, slides=[], isDefect, defectType } = props;
    const [typeOfDefect, setTypeOfDefect] = React.useState(defectType)
    const [current, setCurrent] = React.useState(0);
    const [theSlides, setTheSlides] = React.useState(slides);
    const [showModal, setShowModal] = React.useState(false);
    const [showImage360, setShowImage360] = React.useState(false);
    const [modalTile, setModalTitle] = React.useState(title)
    useEffect(()=>{
        (async()=>{
            try {
                // let equipName=IndividualShapeDecorator.selectedEquipName!;
                if(props.selectedEquipment! && props.selectedEquipment.includes('face'))
                // equipName=SampleToolWidget.currEquipDecorator!.faceToEquipSelect(equipName)!.name;
                IndividualShapeDecorator.selectedEquipName=props.selectedEquipment;
                if(isDefect){ // for showing Defects Images
                   let data =  await DefectsDecorator.selectedMarkerJson;
                    if (!data) {
                        return;
                    }
                    if (data && data.fileName) {
                        const {criticality, type, defect} = data
                        let titl = titleCase(`${criticality} ${type} - ${defect}`)
                        const imgName = data.fileName;
                        let defectType = data.type.toLowerCase() == "screw"?"screws":data.type.toLowerCase()
                        setModalTitle(titl)
                        setTypeOfDefect(defectType)
                        setTheSlides([imgName]);
                        setCurrent(0)
                    }
                }
                else if(store.getState().detectedData.selectedObjectInformation.objectProperties.objectCategoryType ==ObjectCategoryTypes.Equipment_RM){
                    // let data = await SelectAntennaTool.fetchEquipmentData(props.selectedEquipment!)

                   let {equipmentData}:any = store.getState().detectedData;
                   let data = equipmentData.filter(equipments=>equipments.name == props.selectedEquipment)[0]
                    if (!data) {
                        return;
                    }
                    if (data && data.imageList !== "[]" && data.imageList !== "" && data.imageList !== null && data.imageList !== "['no_image']" && data.imageList !== "[\"no_image\"]") {
                        const imgName = data.imageList;
                        const imgNameReplace = imgName.replace(/[\[\]']+/g, "");
                        const imageName = imgNameReplace.replaceAll('"', "").split(",");
                        setTheSlides(imageName);
                        setModalTitle(title)
                } else{
                    setTheSlides(slides)
                    setModalTitle(title)
                }
            }

            } catch (e) {
               Logger.error("Error while calling fetchEquipmentData api",e);
            }
        })()
    }, [props.selectedEquipment]);
    const length = theSlides.length;
    const imageComp = React.useRef(null);
    // let url = ConfigManager.INsiteImageUrl;
    let url = props.inSiteImageUrl;
    if (url && isDefect) {
        url = url.replace("api/img", "api/aiml/img");
    }
    const titleCase = (str) => {
        return str.split(' ').map(item => 
               item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(' ');
      }

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    const openModal = () => {
        setShowModal((prev) => !prev);
    };


    return (
        <>

            <div className="info-panel">
            <div className="propertyGridButton">
                <Button disabled={props.disabled} title={props.btnTooltip ?? ""} className={"property-button"} onClick={openModal}>
                    {title} 
                </Button>
            </div>

            {
                theSlides.map((slide, index) => {
                    return (
                        <>
                            <div>
                            </div>
                            {(showModal && showImage360) ?
                                <div className="large-image">
                                </div> : showModal &&

                                <div className={index === current ? "slide active" : "slide"} key={index}>
                                    {
                                        index === current && (
                                            <ModalDialog Y={150} onClose={() => setShowModal((prev) => !prev)} title={modalTile}>
                                                <TransformWrapper >
                                                    <TransformComponent>
                                                        <div className="imodel-viewport-dialog-container">
                                                            <div ref={imageComp} id="container">
                                                                <img key={index} src={sanitizeUrl(`${url}&img=${slide.trimStart()}${isDefect ? `&type=${typeOfDefect}` : "" }`)} className="imgL" />
                                                            </div>
                                                        </div>
                                                    </TransformComponent>
                                                </TransformWrapper>
                                                {
                                                    length > 1 ? current === length - 1 ?
                                                    (<FaArrowAltCircleLeft className="left-arrow " onClick={prevSlide} />
                                                    ) : current === 0 ? (
                                                        <FaArrowAltCircleRight className="right-arrow" onClick={nextSlide} />
                                                    ) : (
                                                    <>
                                                        <FaArrowAltCircleLeft className="left-arrow " onClick={prevSlide} />
                                                        <FaArrowAltCircleRight className="right-arrow" onClick={nextSlide} />
                                                    </>
                                                    )
                                                    : null
                                                }
                                            </ModalDialog>
                                        )
                                    }
                                </div>
                            }
                        </>
                    );
                })
            }
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedEquipment: state.detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name,
    inSiteImageUrl: ConfigManager.INsiteImageUrl
})

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(GetObjectImages);


function getTowerType ({type, nLegs}){
    if(type == "lattice")return `${nLegs} Legged Lattice`
    return type.charAt(0).toUpperCase() + type.slice(1)
}
