import * as React from "react";
import { useState, useEffect } from 'react';
// import "bootstrap/dist/css/bootstrap.css";
import "./SidePanel.scss";
import Icon from '../../components/icon/icon';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import { IModelApp } from "@itwin/core-frontend";
// import { SampleToolWidget } from "../frontstages/SampleToolWidget";
import TrackTelemetryApiClient from "../../api/trackTelemetry";
import { Logger } from "../../api/logging";
import { SyncUiEventDispatcher, UiFramework, WidgetState } from "@itwin/appui-react";
import {PDFManager} from "../../components/pdfmanager";
import { DTVActions } from "../../../store/Actions";
import { PropertyTableType } from "../../../store/States";
import { setSelectedObjectStructure } from "../../../store/detectedData/apiDataActionTypes";
import { ObjectCategoryTypes, clickType, initialPropertyListObjectState, init_DM_WindPropListState, init_DM_SesmicPropListState } from "../../../store/detectedData/apiDataTypes";
import { SyncUiEventIds, selectionObjective, EquipmentType } from "../../../store/redux-types";
import { store } from "../../../store/rootReducer";
import { Tooltip } from "../../components/tooltip/tooltip";

async function _onClick(_event: any, item: any) {
  // const activeFrontstageId = UiFramework.activeFrontstageId;
  // if (activeFrontstageId !== 'SampleFrontstage') {
  //   const frontstageDef = UiFramework.frontstages.findFrontstageDef("SampleFrontstage");
  //   await UiFramework.frontstages.setActiveFrontstageDef(frontstageDef);
  // }
  if (item.name === "panels") {
    // IModelApp.pdfCallback(false);
    // IModelApp.tableCallback(TableEnum.Panel);
    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_PANELS_LOAD");
    Logger.info("PanelTable Loading");
    store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_GEO_PANEL));
    UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("PropertyTableFormattingWidget")?.setWidgetState(WidgetState.Open);
  } else if (item.name === "pole") {
    // IModelApp.pdfCallback(false);
    // IModelApp.tableCallback(TableEnum.Pole);
    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_POLES_LOAD");
    store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_GEO_POLE))
    UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("PropertyTableFormattingWidget")?.setWidgetState(WidgetState.Open);
    Logger.info("PoleTable Loading");
  } else if (item.name === "mods") {
    // IModelApp.pdfCallback(false);
    // IModelApp.tableCallback(TableEnum.Modification);
    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_MODIFICATIONS_LOAD");
    Logger.info("ModificationTable Loading");
    store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_GEO_MODS))
    UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("PropertyTableFormattingWidget")?.setWidgetState(WidgetState.Open);
  } else if (item.name === "mount") {
    // IModelApp.pdfCallback(false);
    // IModelApp.tableCallback(TableEnum.Mount);
    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_MOUNTS_LOAD");
    store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_LOAD_MOUNTS))
    Logger.info("MountTable Loading");
    UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("PropertyTableFormattingWidget")?.setWidgetState(WidgetState.Open);
  } else if (item.name === "discrete") {
    // IModelApp.pdfCallback(false);
    // IModelApp.tableCallback(TableEnum.Discrete);
    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_DISCRETE_LOAD");
    store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_LOAD_DISCRETE))
    UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("PropertyTableFormattingWidget")?.setWidgetState(WidgetState.Open);
    Logger.info("DiscreteTable Loading");
  } else if (item.name === "attachment") {
    // IModelApp.pdfCallback(false);
    // IModelApp.tableCallback(TableEnum.Attachment);
    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_ATTACHMENTS_LOAD");
    store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_LOAD_ATTCM))
    Logger.info("AttachmentTable Loading");
    UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("PropertyTableFormattingWidget")?.setWidgetState(WidgetState.Open);
  } else if (item.name === "linear") {
    // IModelApp.pdfCallback(false);
    // IModelApp.tableCallback(TableEnum.Appurtenance);
    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_FEEDER_LOAD");
    store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_LOAD_FEEDER))

    UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("PropertyTableFormattingWidget")?.setWidgetState(WidgetState.Open);
    Logger.info("linear Loading");
  } else if (item.name === "seismic") {
    // SampleToolWidget.hideIssuesPanel();
    // IModelApp.pdfCallback(false);
    // IModelApp.listCallback(ListEnum.Seismic);
    SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.DM_Seismic_Load_Selected);          
    UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("PropertyTableFormattingWidget")?.setWidgetState(WidgetState.Open);
    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_SEISMIC_INFO");
    Logger.info("seismic Loading");
  } else if (item.name === "isolated") {
    // IModelApp.pdfCallback(false);
    // IModelApp.tableCallback(TableEnum.Isolated);
    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_ISOLATED_LOAD");
    store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_ISOLATED))
    UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("PropertyTableFormattingWidget")?.setWidgetState(WidgetState.Open);

    Logger.info("isolated Loading");
  } else if (item.name === "drilledPier") {
    // IModelApp.pdfCallback(false);
    // IModelApp.tableCallback(TableEnum.DrilledPier);
    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_DRILLEDPIER_LOAD");
    store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_DRILLED_PIER))
    UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("PropertyTableFormattingWidget")?.setWidgetState(WidgetState.Open);

    Logger.info("drilledPier Loading");
  } else if (item.name === "reaction") {
    // IModelApp.pdfCallback(false);
    // IModelApp.tableCallback(TableEnum.Reaction);
    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_REACTIONS_LOAD");
    store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_REACTION))
    UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("PropertyTableFormattingWidget")?.setWidgetState(WidgetState.Open);
    Logger.info("reaction Loading");
  } else if (item.name === "wind") {
    SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.DM_Wind_Load_Selected);          
    // IModelApp.pdfCallback(false);
    // IModelApp.listCallback(ListEnum.Wind);
    // SampleToolWidget.hideIssuesPanel();
    Logger.info("wind Loading");
    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_WIND_INFO");
  } else if (item.name === "report") {
    Logger.info("report Loading");
  //   UiFramework.content.dialogs.open(
  //     <PDFManager />,
  //     "saReport"
  // )    // IModelApp.pdfCallback(true);
  } else if (item.name === "home") {
    // IModelApp.pdfCallback(false);
    Logger.info("home Loading");
  } else {
    window.alert(JSON.stringify(item, null, 2));
  }
}

const handleWind = (type) => {
    let objectProperties = {
      selectionObjective: selectionObjective.Info,
      objectIds: "",
      objectDetails: undefined,
     objectCategoryType:type=="Wind"? ObjectCategoryTypes.Wind_DM:ObjectCategoryTypes.Seismic_DM,
      selectedObjectNST: {name: "", subType: EquipmentType.None},
      clicked: clickType.SINGLE
    }
  
    let  elementsOnBottom = []
    let propListObj = initialPropertyListObjectState;
    
    let elementsOnTop = [];
    const isEditActive = store.getState().dtvState.applicationState.isEditModeActive;
  
    propListObj = {...propListObj, heading: `${type} Information`, propertyList:type=="Wind"? init_DM_WindPropListState:init_DM_SesmicPropListState, elementsOnTop };
    objectProperties={...objectProperties, selectionObjective: isEditActive ? selectionObjective.Edit : selectionObjective.Info, objectCategoryType: type=="Wind"? ObjectCategoryTypes.Wind_DM:ObjectCategoryTypes.Seismic_DM,  selectedObjectNST: {name: "", subType: EquipmentType.None}};
    const temp = {...propListObj, objectProperties}
    store.dispatch(setSelectedObjectStructure(temp));
  }

//const getConnectProject = async () => {
//  const id = ConfigManager.projectId;
//  if (NineZoneSampleApp.oidcClient.hasSignedIn && id !== "") {
//    Logger.info("Get Connect Project Information");
//    const requestContext = await AuthorizedFrontendRequestContext.create();
//    const connectClient = new ContextRegistryClientEx();
//    let project;
//    try {
//      project = await connectClient.getProjectInfo(id, requestContext, {});
//      return project;
//    } catch (e) {
//      Logger.error("Project with id does not exist", e);
//      throw new Error(`Project with id does not exist`);
//    }
//  }
//  return;
//};

async function getData(imodel) {
  try {
    if (!imodel.name) {
      return [];
    }
    let record;
    for await (record of imodel.query("select TowerType from OpenTower.TowerInformation")) {
      return record;
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    Logger.error("Error while fetching TowerInformation",e)
  }

  return undefined;
}
let items: any[] = [];
async function getItems() {
  // const towerType = (await getData(imodel))?.towerType;
  const getTowerInfo = await getData(UiFramework.getIModelConnection());
  const towerType = getTowerInfo?.length ? getTowerInfo[0] : "";
  // const towerType = (await getData(imodel))[0];
  const itemstest = [
    "divider",
    {
      items: [
        (towerType === "Monopole"?
        {
          _onClick,
          label: "Pole",
          name: "pole",
          pictfile: "/image/pole.svg",
        }: {
            _onClick,
            label: "Panels",
            name: "panels",
            pictfile: "/image/panels.svg",
          }),
        {
          _onClick,
          label: "Modifications",
          name: "mods",
          pictfile: "/image/mods.ico",
        },
      ],
      isSingle: false,
      label: "Geometry",
      name: "geometry",
      pictfile: "/image/Geometry.svg",
    },
    "divider",
    {
      items: [
        {
          _onClick,
          label: "Mounts",
          name: "mount",
          pictfile: "/image/mount.png",
        },
        {
          _onClick,
          label: "Discrete",
          name: "discrete",
          pictfile: "/image/discrete.png",
        },
        {
          _onClick,
          label: "Attachments",
          name: "attachment",
          pictfile: "/image/wireladder.ico",
        },
        {
          _onClick,
          label: "Feeder",
          name: "linear",
          pictfile: "/image/wire.svg",
        },
      ],
      isSingle: false,
      label: "Loading",
      name: "loading",
      pictfile: "/image/loading.svg",
    },
    "divider",
    { _onClick, isSingle: true, label: "Wind", name: "wind", pictfile: "/image/wind.svg" },

    "divider",
    {
      _onClick,
      isSingle: true,
      label: "Seismic",
      name: "seismic",
      pictfile: "/image/seismic.png",
    },

    "divider",
    {
      items: [
        {
          _onClick,
          label: "Isolated",
          name: "isolated",
          pictfile: "/image/FoundationMat.ico",
        },
        {
          _onClick,
          label: "Drilled Pier",
          name: "drilledPier",
          pictfile: "/image/PierPile.ico",
        },
        //{
        //  _onClick,
        //  label: "Anchor Guy",
        //  name: "anchorGuy",
        //  pictfile: "/image/anchor.png",
        //},
      ],
      isSingle: false,
      label: "Foundation",
      name: "foundation",
      pictfile: "/image/foundation.svg",
    },
    "divider",
    {
      _onClick,
      isSingle: true,
      label: "Reactions",
      name: "reaction",
      pictfile: "/image/reaction.png",
    },
    "divider",
    {
      _onClick,
      isSingle: true,
      label: "SA Report",
      name: "report",
      pictfile: "/image/report.png",
    },
  ];
  items = itemstest;
  return itemstest;
}
function SidebarItem({
  depthStep = 10,
  depth = 0,
  expanded,
  item,
  clickEvent,
  activeField,
  currentItem, currentItemEvent,
  sidePanelExpanded,
  ...rest
}) {
  const [collapsed, setCollapsed] = React.useState(true);
  const { _onClick: onClickProp, items, Icon, label, pictfile } = item;
  const [defaultFlag, setDefaultFlag] = React.useState(true);

  //const [activeField, setActiveField] = React.useState('Panels');

  function toggleCollapse() {
    if (item.name === currentItem && defaultFlag) {
      setDefaultFlag((prevValue) => {
        return !prevValue;
      });
    } else {
      currentItemEvent(item.name);
      //setCollapsed((prevValue) => {
      //  return !prevValue;
      //});
    }
  }

  function renderItemContainer(element) {
    return (
      sidePanelExpanded
        ? (
          element
        ) : (
          <Tooltip text={label} placement="right">
            {element}
          </Tooltip>
        )
    );
  }

  function _onClick(e) {
    if (Array.isArray(items)) {
      toggleCollapse();
    }
    if (onClickProp) {
      onClickProp(e, item);
      //setActiveField(item.label);
      clickEvent(item.label);
    }
  }

  let collapsedValue =
    item.name === currentItem && defaultFlag ? false : collapsed;

  let expandIcon: React.ReactElement = <></>;//= <div style={{width:'20px'}}></div>;

  if (Array.isArray(items) && items.length) {
    expandIcon = !collapsedValue ? (
      <ExpandLessIcon
        className={sidePanelExpanded ?
          "sidebar-item-expand-arrow sidebar-item-expand-arrow-expanded expanded-arrow-icon" : "sidebar-item-expand-arrow sidebar-item-expand-arrow-expanded"
        }
      />
    ) : (
        <ExpandMoreIcon className={sidePanelExpanded ? "sidebar-item-expand-arrow expanded-arrow-icon" : "sidebar-item-expand-arrow"} />
      );
  }
  return (
    <>{activeField && activeField == "SA Report" && item&&item.label == "SA Report" &&  <PDFManager />}
      { renderItemContainer(
        <ListItem
          style={{ paddingTop: "5px", paddingBottom: "5px" }}
          className={`sidebar-item  ${activeField === label ? "active" : ""} ${expanded === '' ? 'single-item' : ''}`}
          onClick={_onClick}
          // disabled = {store.getState().dtvState.featureControls.ShowSubMenu}
          button
          dense
          {...rest}
        >
          <div
            className="sidebar-item-content">
            {Icon && <Icon className="sidebar-item-icon" fontSize="small" />}
            {pictfile && (
              <div style={{ display: "block", width: "100%", textAlign: "center" }}>
                <img
                  className="sidebar-item-icon"
                  // fontSize="small"
                  src={pictfile}
                  alt=" "
                  width="20"
                  height="20"
                ></img>
              </div>
            )}
            {sidePanelExpanded && (
              <div className="sidebar-item-text">{label}</div>
            )}
          </div>
          {item.isSingle === true && (<div className="MuiSvgIcon-root sidebar-item-expand-arrow"></div>)}
          {expandIcon}
        </ListItem>
      )}
      <Collapse in={!collapsedValue} timeout="auto" unmountOnExit>
        {Array.isArray(items) ? (
          <List disablePadding dense>
            {items.map((subItem, index) => (
              <React.Fragment key={`${subItem.name}${index}`}>
                {subItem === "divider" ? (
                  <Divider />
                ) : (
                    <SidebarItem
                      depth={depth + 1}
                      depthStep={depthStep}
                      item={subItem}
                      clickEvent={clickEvent}
                      activeField={activeField}
                      sidePanelExpanded={sidePanelExpanded} expanded={false} currentItem={undefined} currentItemEvent={undefined}                    />
                  )}
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  );
}

function Sidebar(props: any) {
  const { depthStep=10, depth=0, expanded=false, itemList=[] } = props.items ?? {};
  const activeItemId = props.activeItemId;
  const [activeField, setActiveField] = React.useState(activeItemId);
  const [sidePanelExpanded, setSidePanelExpanded] = React.useState(true);
  const [currentItem, setCurrentItem] = React.useState('geometry');
  const [itemKey, setItemKey] = React.useState(1);

  useEffect(() => {
    setActiveField(activeItemId);
  }, [activeItemId])


useEffect(() => {
    if (IModelApp?.viewManager?.selectedView && activeItemId) {
        UiFramework.frontstages.updateModalFrontstage();
    }
});

  const clickEvent = (activeItem) => {
    setActiveField(activeItem);
  };
  /*const clickEvent = (activeItem) => {
      setActiveField(activeItem);
  }*/
  const currentItemEvent = (name) => {
    setCurrentItem(name);
    setItemKey(itemKey + 1);
  }

  const toggleSidebarMenu = () => {
    setSidePanelExpanded(!sidePanelExpanded);
    UiFramework.frontstages.updateModalFrontstage();
  };


  function renderExpandItem(sidePanelExpanded) {
    return (
      <li className='bnt-hc-side-navigation-expand-btn' onClick={() => toggleSidebarMenu()}>
        <a>
          <Icon name={sidePanelExpanded ? 'chevron-left' : 'chevron-right'} defaultColor />
        </a>
      </li>
    )
  }

  return (
    <div className="sidebar">
      {renderExpandItem(sidePanelExpanded)}
      <List disablePadding dense>
        {items.map((sidebarItem, index) => (
          <React.Fragment key={`${sidebarItem.name}${index}${itemKey}`}>
            {sidebarItem === "divider" ? (
              <Divider />
            ) : (
                <SidebarItem
                  depthStep={depthStep}
                  depth={depth}
                  expanded={expanded}
                  item={sidebarItem}
                  clickEvent={clickEvent}
                  activeField={activeField}
                  sidePanelExpanded={sidePanelExpanded}
                  currentItem={currentItem}
                  currentItemEvent={currentItemEvent}
                />
              )}
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}

function TowerSidebar() {
  const [allItems, setAllItems] = useState<any[]>();
  const [activeItem, setActiveItem] = useState('Panels');

  useEffect(() => {
    (async function () {
      const itemsData: any = await getItems();
      if (itemsData[1].items[0].name === 'pole') {
        setActiveItem('Pole');
        store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_GEO_POLE))
      }
      setAllItems(itemsData!);
    })();
  }, [])
  //const itemsTest = getItems(props.imodel);
  return (
    <div>
      <Sidebar items={allItems} activeItemId={activeItem} />

    </div>
  );
}

export default TowerSidebar;
