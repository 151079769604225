import 
    {FormsManager, FormsPluginManager} 
   from "@bentley/itwin-forms";
import { IModelApp } from "@itwin/core-frontend";
import { store } from "../../../store/rootReducer";
import { AppSettings } from "../../../config/AppSettings";
import { AccessToken } from "@itwin/core-bentley";
import { SavedViewsClientFactoryApi } from "@bentley/itwin-saved-views";
import { LocalizationManager } from "../../../api/config/LocalizationManager";

export const IssueInitialize = () => {
  const getUserInfo = () => {
    return  {
      id:  store.getState().auth.user?.profile.sub ??"",
      email:  store.getState().auth.user?.profile.email ??"",
      profile: {
          firstName:  store.getState().auth.user?.profile.given_name ??"",
          lastName:  store.getState().auth.user?.profile.family_name ??"",
          name:  store.getState().auth.user?.profile.name ??"",
          preferredUserName:  store.getState().auth.user?.profile.preferred_username ??"",
      },
      organization: {
          id:  store.getState().auth.user?.profile.org ??"",
          name:  store.getState().auth.user?.profile.org_name ??"",
      }
     }
  }

   store.getState().auth.user?.profile

  const options = {
  userId:  store.getState().auth.user?.profile.sub ??"",
  applicationName: AppSettings.appName,
  gprid:   AppSettings.gprid,
      // getstore(),
      store:   store, // not used by ui-snippet
      i18n: LocalizationManager.i18n,
      getAccessToken: ()=> getAccessToken()
  }
 
  const getAccessToken = async() => {
      return  store.getState().auth.accessTokenStatePrivateAPI.accessToken as AccessToken;
  }
  return FormsManager.initialize(options.userId,options.i18n, new SavedViewsClientFactoryApi,{
    fixUrl: true,
    allowMarkerInSpace: true,
  },
  async () => getUserInfo(),getAccessToken); 
}