import { UiFramework } from "@itwin/appui-react";
import React,{useState,useEffect} from "react";
import AddMountElevationPopup from "./tools/AddMountElevationPopup";
import { RootState } from "../../store/States";
import { connect } from "react-redux";
import { PinPropertyPopup } from "./Lists/PinPropertyPopup";
import { store } from "../../store/rootReducer";
import ModalWithImageSlider from "./ModalWithImageSlider";


const ShowModalPopup = (props) =>{
    const [item,setItem] = useState(<></>)
useEffect(()=>{
    let temp: any = <></>;
    if(props.showCreateModal.id!=""){
        switch(props.showCreateModal.id){
            case "AddNewRaD":{
        temp = <AddMountElevationPopup data={UiFramework.getIModelConnection()} onClose={props.onClose}/>
     break;
     
    }
            case "AddEditRaD":{
        temp = <AddMountElevationPopup type={"edit"} data={UiFramework.getIModelConnection()} onClose={props.onClose}/>
     break;
     
    }
    case "pinProperty":{
       temp = < PinPropertyPopup openOClose={true}/>
       break;
    }
    case "showDefectImages":{
       temp =  <ModalWithImageSlider title={"Defect Image"} isDefect={true}/>
       break;
    }

  }

   
setItem(temp)
    }
},[])


    return(<>
     {item}
    </>)
}
const mapStateToProps = (state: RootState) => {
  const retState = {
    isEditActive: state.dtvState.applicationState.isEditModeActive,
    selectedObject: state.detectedData.selectedObjectInformation.objectProperties,
    showPinTags: state.dtvState.applicationState.showPinTags,
    showCreateModal: state.dtvState.applicationState.showCreateModal,

  }
  return retState;
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ShowModalPopup)
